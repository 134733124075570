import React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LOADER } from '../../redux/constants/Common';

const Iframe = ({ source, height, width }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()


    // useEffect(() => {
    //     window.addEventListener("message", function (e) {
    //       //console.log('main target===========================>>>>>>>', e)
    //         if (e?.data === 'landscape') {
    //           //console.log('device in landscape mode')
    //             var target = document.getElementById("iframe-rummy");
    //             target.className += "fullScreen";
    //         }
    //         if (e?.data === 'portrait') {
    //           //console.log('device in portrait mode')
    //             var target = document.getElementById("iframe-rummy");
    //             target.classList.remove('fullScreen')
    //         }
    //         if (e?.data?.event === 'LeaveRummy') {

    //         }

    //     });
    // }, []);


    if (!source) {
        navigate(-1)
    }

    // const src = source;

    return (
        // basic bootstrap classes. you can change with yours.
        <iframe src={source} style={{ height, width }} id="iframe-rummy"  />
    );
};

export default Iframe;