import React, { useEffect, useState } from 'react'
import moment from 'moment'
import Session from './session'
import { Button, Modal, Spinner } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { MATCH_DEAD_LINE_POPUP } from '../redux/constants/Common'
import { useDispatch } from 'react-redux'



const MatchTimer = ({ item, hour, class_name, hr }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const dispatch = useDispatch()


  const [timeDiff, setTimeDiff] = useState(0)
  const [[days, hours, minutes, seconds], setTime] = useState([parseInt(0), parseInt(0), parseInt(0), parseInt(0)])
  const match_start_time = item?.timestamp_start !== undefined ? item?.timestamp_start : item?.mathData?.timestamp_start
  const [remainTime, setRemainTime] = useState(moment.unix(match_start_time).diff(moment.unix(Session.getSession('server_time')), 'seconds'))


  var timerId;

  const reset = () =>
    setTime([
      parseInt(0),
      parseInt(0),
      parseInt(0),
      parseInt(0)
    ])

  const timeOver = () => {
    const notUrl = ["/Fantasy", "/upcomingmatch", "/money-pool", "/pool-detail"]
    if (notUrl?.includes(pathname)) {
      window.location.reload()
    }else{
      dispatch({ type: MATCH_DEAD_LINE_POPUP, payload: true })
    }
  }


  const tick = () => {

    if (remainTime > 0) {
      const day = Math.floor(remainTime / 86400);
      const remainingSecs = remainTime % 86400;
      const hrs = Math.floor(remainingSecs / 3600);
      const remainingSecsAfterHours = remainingSecs % 3600;
      const mins = Math.floor(remainingSecsAfterHours / 60);
      const secs = remainingSecsAfterHours % 60;
      setRemainTime(moment.unix(match_start_time).diff(moment.unix(Session.getSession('server_time')), 'seconds'))
      setTime([day, hrs, mins, secs]);
      setTimeDiff(remainTime)
    } else {
      timeOver()
      clearInterval(timerId)
    }
  };


  const updateTimer = () => {
    // console.log('remain time==>>>>>>>>>>>>>>>>>>>>', moment.unix(match_start_time).diff(moment.unix(Session.getSession('server_time')), 'seconds'));
    const isTimer = moment.unix(match_start_time).diff(moment.unix(Session.getSession('server_time')), 'seconds')
    if (isTimer > 0) {
      setRemainTime(moment.unix(match_start_time).diff(moment.unix(Session.getSession('server_time')), 'seconds'))
      // reset()
    } else {
      reset()
      timeOver()
    }

  };


  const handleVisibilityChange = () => {
    if (document.hidden) {
      // console.log('document hidden==>>>>>>');
    } else {
      updateTimer();
    }
  };



  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [])


  useEffect(() => {
    timerId = setInterval(() => tick(), 1000);
    return () => clearInterval(timerId);
  },);



  if (timeDiff > 0) {
    // if (false) {
    var isClass = class_name === 'normal' ? 'normal'
      : class_name === 'active' ? 'match_time active'
        : class_name === 'left_time' ? 'left_time'
        : (class_name === 'red' || class_name === 'white') ? `text-${class_name}`
          : class_name ? 'match_time' : 'not-started'

    return (
      <>
        {days >= 1
          ? (
            !hour
              ? <div className={isClass}> {days}d  {hr === 'left' && 'left'}</div>
              : <div className={isClass}> {days}d : {hours}h {hr === 'left' && 'left'}</div>
            // <div className={isClass`${hour ? 'not-started' : 'match_time'} `} > {days}d : {hours}h </div>
          )
          : hours === 0
            ? <div className={isClass}> {minutes}m : {seconds}s {hr === 'left' && 'left'}</div>
            : hr === 'left' ? <div className={isClass}> {hours}h : {minutes}m  {" "} left</div>
              // : hr === 'left' ? <div className={isClass}> {hours}h : {minutes}m : {seconds}s {" "} left</div>
              : hr ? <div className={isClass}> {hours}hr : {minutes}min </div>
                : <div className={isClass}> {hours}h : {minutes}m  </div>}

      </>
    )
  } else {
    // console.log('this is timer else part MatchTimer.js component');
    var isClass = class_name === 'normal' ? 'normal'
      : class_name === 'active' ? 'match_time active'
        : class_name === 'left_time' ? 'left_time'
        : (class_name === 'red' || class_name === 'white') ? `text-black`
          : class_name ? 'match_time' : 'not-started'
          
    return (
      <>
        {/* <div className={isClass}> {0}m : {0}s </div> */}
        <div className={isClass}> -- -- -- </div>


      </>
    )
  }


}


export default MatchTimer
