import moment from 'moment'
import Session from './session'
import axios from 'axios'
import { toast } from 'react-toastify'

const helper = {
  get_cookie: function (name) {
    return document.cookie.split(';').some((c) => {
      return c.trim().startsWith(name + '=')
    })
  },
  delete_cookie: function (name, path, domain) {
    if (helper.get_cookie(name)) {
      document.cookie =
        name +
        '=' +
        (path ? ';path=' + path : '') +
        (domain ? ';domain=' + domain : '') +
        ';expires=Thu, 01 Jan 1970 00:00:01 GMT'
    }
  },

  // all api methods
  api: async (path, method, data) => {
    const token = Session.getSession('token')
    // const homeActiveKey = Session.getSession('homeActiveKey')
    // if (homeActiveKey === 'soccer' && path.includes('/fantasy/') && !path.includes('/apply-contest-invite-code')) {
    //   path = path.replace('/fantasy/', '/soccer/')
    // }

    try {
      const config = {
        method,
        url: path,
        data,
        headers: {
          'x-access-token': token,
          // 'game_type': homeActiveKey
        }
      }

      const league_type = Session.getSession('league_type')
      if (league_type) {
        config.headers.league_type = league_type
      }

      const homeActiveKey = Session.getSession('homeActiveKey')
      if (homeActiveKey) {
        config.headers["game_type"] = homeActiveKey
      } else {
        config.headers["game_type"] = 'cricket'
      }
      const result = await axios(config)

      if (result?.data?.ResponseCode?.includes('200')) {
        return result?.data
      } else if (result?.data?.Message === 'Failed to authenticate token' && result?.data?.success === false) {
        Session.clearAllSession()
        window.location.reload()
      } else if (result?.data?.ResponseCode === '502' && result?.data?.Message === 'Session disconnected, please login.') {
        Session.clearAllSession()
        window.location.reload()
      }
      else {
        return result?.data
      }
    } catch (error) {
      if (error?.response?.data?.msg === 'Failed to authenticate token' || error?.response?.data.auth === false) {
        toast.error(error?.response?.data?.msg)
        Session.clearAllSession()
      }
      return error
    }
  },
 
  serialize: function (obj, prefix) {
    const str = []
    let p
    for (p in obj) {
      if (obj.hasOwnProperty(p)) {
        const k = prefix ? prefix + '[' + p + ']' : p
        const v = obj[p]
        str.push((v !== null && typeof v === 'object')
          ? this.serialize(v, k)
          : encodeURIComponent(k) + '=' + encodeURIComponent(v))
      }
    }
    return str.join('&')
  },
  start_time: function () {
    // return helper.start_time;
    return Session.getSession('server_time');
  }(),



  localizeNumber: function (sign, value) {
    var num = Math.round(Number(value) * 100) / 100;
    var str = num.toLocaleString()

    if (str == '0' && sign === 'coin') {
      return <>
        <img src="./images/gold_icon.png" alt="" className='me-1' height={'20px'} width={20} />
        {0.0}
      </>
    }

    if (str == '0' || value == undefined) {
      return ` ${'0.0'}`
    }

    if (sign === "") {
      return str
    }

    if (sign === "coin") {
      return (
        <>
          <img src="./images/gold_icon.png" alt="" className='me-1' height={'20px'} width={20} />
          {str}
        </>
      )
    }

    if (sign === "$") {
      return (
        <>
          {str}
        </>
      )
    }


    return <>
      {sign}
      {str}
    </>

  },

  textConverter: function (text, size) {
    size = size ? size : 15
    return text?.length > size ? text?.substring(0, size) + '...' : text
  },
  textCapitalize: function (str) {
    const lower = str?.toLowerCase()
    return str?.charAt(0)?.toUpperCase() + lower?.slice(1)
  },
  textCapital: function (str) {
    return str?.toUpperCase()
  },
  dateFormatter: function (date, formate) {
    if (!date || !formate) {
      return moment(date).format("dd, mm yy")
    }

    return moment(date).format(formate)
  },
  getStateList: function () {
    return [
      "Arunachal Pradesh",
      "Bihar",
      "Chhattisgarh",
      "Chandigarh",
      "Dadra and Nagar Haveli",
      "Daman and Diu",
      "Delhi",
      "Goa",
      "Gujarat",
      "Haryana",
      "Himachal Pradesh",
      "Jammu and Kashmir",
      "Jharkhand",
      "Karnataka",
      "Kerala",
      "Madhya Pradesh",
      "Maharashtra",
      "Manipur",
      "Mizoram",
      "Punjab",
      "Pondicherry",
      "Rajasthan",
      "Tamil Nadu",
      "Tripura",
      "Uttar Pradesh",
      "Uttarakhand",
      "West Bengal"
    ]
  },


}

export default helper
