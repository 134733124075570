import { ALL_PLAYER_LIST_LEAGUE, CHECK_WALLET_BALANCE_LEAGUE, CONTEST_CONFIRMATION_LEAGUE, EDIT_TEAM_LEAGUE, GET_LEAGUE_BOOSTER_LIST, GET_LEAGUE_MATCH_DETAILS, GET_LEAGUE_STATES, GET_SERIES_LIST, GET_USER_BOOSTER_LIST, LEADER_BOARD_SCROLL_DATA, LEAGUE_ALL_CONTEST, LEAGUE_LEADER_BOARD_DATA, LEAGUE_MY_CONTEST, LEAGUE_MY_CONTEST_TAB, LEAGUE_MY_CONTEST_TEAMS, LEAGUE_MY_CONTEST_TEAMS_CLEAR, LEAGUE_MY_TEAMS_ACCORDION, LEAGUE_PLAYERS_POINT_AND_TRANSFER, LEAGUE_SERIES_PLAYER_LIST, LEAGUE_STATE_DATA, LEAGUE_TEAM_PREVIEW, LEAGUE_TOP_PLAYERS, LEAGUE_TRANSFER_PREVIEW, LOW_BALANCE_LEAGUE, PREVIOUS_APPLIED_BOOSTER, SELECTED_TEAM_LEAGUE, TEAM_TYPE_LEAGUE } from '../constants/league';
import helper from '../../utils/helper';
import apiPath from '../../utils/apiPath';
import { LOADER, LOW_BALANCE } from '../constants/Common';
import { toast } from 'react-toastify';
import { get_player_details, get_player_state_for_single_match } from './FantasyActions';
import { isEmpty } from 'lodash';
import Session from '../../utils/session';
import axios from 'axios';
import moment from 'moment';

var qs = require('qs');

export const fetchServerTime = (matchTime) => async (dispatch) => {
    try {
        const response = await axios.get(apiPath.server_time); // Replace with your server endpoint
        return moment.unix(matchTime).diff(moment(response.data.results?.currentTimeStamp), 'seconds')
    } catch (error) {
        console.log('Error fetching server time:', error);
    }


}




export const get_series_list = (payload) => async (dispatch) => {

    try {
        dispatch({ type: LOADER, payload: true })

        payload = qs.stringify(payload);

        const res = await helper.api(apiPath.get_series_list, 'get', payload)
        if (res?.success) {
            dispatch({
                type: GET_SERIES_LIST,
                payload: res
            })
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.success(res?.msg);
            return res
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.error(res?.msg);
            return res
        }

    } catch (error) {
        //console.log('error in upcoming matches api::', error)
    }

}

export const get_league_match_details = (payload) => async (dispatch) => {

    try {
        dispatch({ type: LOADER, payload: true })
        dispatch({ type: GET_LEAGUE_MATCH_DETAILS, payload: [] })

        payload = qs.stringify(payload);

        const res = await helper.api(apiPath.get_league_match_details, 'post', payload)
        if (res?.success) {
            dispatch({
                type: GET_LEAGUE_MATCH_DETAILS,
                payload: res
            })
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.success(res?.msg);
            return res
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.error(res?.msg);
            return res
        }

    } catch (error) {
        //console.log('error in upcoming matches api::', error)
    }

}


export const get_league_states = (payload) => async (dispatch) => {

    try {
        dispatch({ type: LOADER, payload: true })
        dispatch({ type: GET_LEAGUE_STATES, payload: [] })

        payload = qs.stringify(payload);

        const res = await helper.api(apiPath.get_league_states, 'post', payload)
        if (res?.success) {
            dispatch({
                type: GET_LEAGUE_STATES,
                payload: res
            })
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.success(res?.msg);
            return res
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.error(res?.msg);
            return res
        }

    } catch (error) {
        //console.log('error in upcoming matches api::', error)
    }

}

export const get_league_booster_list = (data) => async (dispatch) => {

    try {
        dispatch({ type: LOADER, payload: true })

        const res = await helper.api(apiPath.get_league_booster_list_by_match, 'post', data)
        if (res?.success) {
            dispatch({
                type: GET_LEAGUE_BOOSTER_LIST,
                payload: res
            })
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.success(res?.msg);
            return res
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.error(res?.msg);
            return res
        }

    } catch (error) {
        //console.log('error in upcoming matches api::', error)
    }

}
export const get_user_booster_list = (data) => async (dispatch) => {

    try {
        dispatch({ type: LOADER, payload: true })

        const res = await helper.api(apiPath.get_booster_list_by_user, 'get', {})
        if (res?.success) {
            dispatch({
                type: GET_USER_BOOSTER_LIST,
                payload: res
            })
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.success(res?.msg);
            return res
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.error(res?.msg);
            return res
        }

    } catch (error) {
        //console.log('error in upcoming matches api::', error)
    }

}

export const get_league_all_contest = (payload) => async (dispatch) => {

    try {
        dispatch({ type: LOADER, payload: true })
        dispatch({ type: LEAGUE_ALL_CONTEST, payload: [] })

        payload = qs.stringify(payload);

        const res = await helper.api(apiPath.get_league_all_contest, 'post', payload)
        if (res?.success) {
            dispatch({
                type: LEAGUE_ALL_CONTEST,
                payload: res
            })
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.success(res?.msg);
            return res
        } else {
            dispatch({ type: LEAGUE_ALL_CONTEST, payload: [] })
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.error(res?.msg);
            return res
        }

    } catch (error) {
        //console.log('error in upcoming matches api::', error)
    }

}
export const get_league_my_contest = (payload) => async (dispatch) => {

    try {
        dispatch({ type: LOADER, payload: true })
        dispatch({ type: LEAGUE_MY_CONTEST, payload: [] })

        payload = qs.stringify(payload);

        const res = await helper.api(apiPath.get_league_my_contest, 'post', payload)
        if (res?.success) {
            dispatch({
                type: LEAGUE_MY_CONTEST,
                payload: res
            })
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.success(res?.msg);
            return res
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.error(res?.msg);
            return res
        }

    } catch (error) {
        //console.log('error in upcoming matches api::', error)
    }

}
export const get_league_my_teams = (payload) => async (dispatch) => {

    try {
        dispatch({ type: LOADER, payload: true })
        dispatch({ type: LEAGUE_MY_CONTEST_TEAMS_CLEAR, payload: [] })

        payload = qs.stringify(payload);

        const res = await helper.api(apiPath.get_league_my_teams, 'post', payload)
        if (res?.success) {
            dispatch({
                type: LEAGUE_MY_CONTEST_TEAMS,
                payload: res
            })
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.success(res?.msg);
            return res
        } else {
            dispatch({ type: LEAGUE_MY_CONTEST_TEAMS_CLEAR, payload: [] })
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.error(res?.msg);
            return res
        }

    } catch (error) {
        //console.log('error in upcoming matches api::', error)
    }

}
export const points_team_preview = (payload) => async (dispatch) => {
    dispatch({ type: LEAGUE_TEAM_PREVIEW, payload: {} })
    try {
        dispatch({ type: LOADER, payload: true })

        // payload = qs.stringify(payload);

        const res = await helper.api(apiPath.points_team_preview, 'post', payload)
        if (res?.success) {
            dispatch({
                type: LEAGUE_TEAM_PREVIEW,
                payload: res?.results[0]
            })
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.success(res?.msg);
            return res
        } else {
            dispatch({ type: LEAGUE_TEAM_PREVIEW, payload: {} })
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.error(res?.msg);
            return res
        }

    } catch (error) {
        //console.log('error in upcoming matches api::', error)
    }

}
export const get_league_player_states = (payload) => async (dispatch) => {

    try {
        dispatch({ type: LOADER, payload: true })
        dispatch({ type: LEAGUE_SERIES_PLAYER_LIST, payload: [] })

        payload = qs.stringify(payload);
        const res = await helper.api(apiPath.get_league_series_player_list, 'post', payload)
        if (res?.success) {
            dispatch({
                type: LEAGUE_SERIES_PLAYER_LIST,
                payload: res
            })
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.success(res?.msg);
            return res
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            dispatch({
                type: LEAGUE_SERIES_PLAYER_LIST,
                payload: []
            })
            // toast.error(res?.msg);
            return res
        }

    } catch (error) {
        //console.log('error in upcoming matches api::', error)
    }

}
export const get_league_leader_board_data = (payload, page) => async (dispatch) => {

    try {
        dispatch({ type: LOADER, payload: true })

        // payload = qs.stringify(payload);
        var path = `${apiPath.get_league_leaderboard_data}?page=${page}&itemsPerPage=10`
        const res = await helper.api(path, 'post', payload)
        if (res?.success) {


            if (page > 1) {
                dispatch({
                    type: LEADER_BOARD_SCROLL_DATA,
                    payload: res
                })
            } else {
                dispatch({
                    type: LEAGUE_LEADER_BOARD_DATA,
                    payload: res
                })
            }


            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.success(res?.msg);
            return res
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.error(res?.msg);
            return res
        }

    } catch (error) {
        //console.log('error in upcoming matches api::', error)
    }

}
export const get_league_top_players = (payload) => async (dispatch) => {

    try {
        dispatch({ type: LOADER, payload: true })

        payload = qs.stringify(payload);

        const res = await helper.api(apiPath.get_league_top_players, 'post', payload)
        if (res?.success) {
            dispatch({
                type: LEAGUE_TOP_PLAYERS,
                payload: res
            })
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.success(res?.msg);
            return res
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.error(res?.msg);
            return res
        }

    } catch (error) {
        //console.log('error in upcoming matches api::', error)
    }

}
export const get_league_players_points = (payload) => async (dispatch) => {

    try {
        dispatch({ type: LOADER, payload: true })

        payload = qs.stringify(payload);

        const res = await helper.api(apiPath.get_league_players_points, 'post', payload)
        if (res?.success) {
            dispatch({
                type: LEAGUE_PLAYERS_POINT_AND_TRANSFER,
                payload: res
            })
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.success(res?.msg);
            return res
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.error(res?.msg);
            return res
        }

    } catch (error) {
        //console.log('error in upcoming matches api::', error)
    }

}
export const get_league_transfer_preview = (payload) => async (dispatch) => {

    try {
        dispatch({ type: LOADER, payload: true })

        // payload = qs.stringify(payload);

        const res = await helper.api(apiPath.get_league_transfer_preview, 'post', payload)
        if (res?.success) {
            dispatch({
                type: LEAGUE_TRANSFER_PREVIEW,
                payload: res
            })
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.success(res?.msg);
            return res
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.error(res?.msg);
            return res
        }

    } catch (error) {
        //console.log('error in upcoming matches api::', error)
    }

}

export const league_player_details = (navigate, item) => async (dispatch, getState) => {
    const { match_details, } = getState()?.league;
    var res

    var matchDetails = match_details;
    matchDetails['player_id'] = item?.player_id

    if (matchDetails?.match_status === 'Completed' || matchDetails?.match_status === 'Live' || matchDetails?.match_status === 'In Progress') {

        res = await dispatch(get_player_state_for_single_match(matchDetails, item))
        if (res?.results?.length > 0) {
            // item?.isButton && res.results.isButton = item?.isButton
            res.match_status = matchDetails?.match_status
            navigate('/league-player-info', { state:  {...res, boosterDetails:item?.boosterDetails}, replace: false })
        } else {
            toast.warn(res?.msg)
        }
    } else {
        res = await dispatch(get_player_details(matchDetails, item))
        if (!isEmpty(res?.results)) {
            if (item?.isButton) {
                res.results.isButton = item?.isButton
            }
            navigate('/league-player-info', { state: {...res, boosterDetails:item?.boosterDetails}, replace: false })
        } else {
            toast.warn(res?.msg)
        }
    }

}


export const league_preview_team = (navigate, team) => async (dispatch, getState) => {


    try {
        dispatch({ type: LEAGUE_TEAM_PREVIEW, payload: {} })
        const { match_details, leagueStateData } = getState()?.league;
        const { playerList } = getState()?.leagueTeam


        var previewData = {}

        previewData.seriesPlayer = playerList?.filter(player => player?.isSelected)
        previewData.visitorCount = playerList?.filter((item) => item.isSelected === true && match_details?.visitorteam_id === item?.team_id).length
        previewData.localCount = playerList?.filter((item) => item.isSelected === true && match_details?.localteam_id === item?.team_id).length
        previewData.captain_player_id = playerList?.filter((item) => item.isSelected === true && item?.isCaptain === true)?.[0]?.player_id || ''
        previewData.vice_captain_player_id = playerList?.filter((item) => item.isSelected === true && item?.isViceCaptain === true)?.[0]?.player_id || ''

        previewData.localteam_short_name = match_details?.localteam_short_name
        previewData.visitorteam_short_name = match_details?.visitorteam_short_name
        previewData.actualPoints = match_details?.actualPoints || ''

        var stateData = { ...leagueStateData, teamPreview: true, team_number: leagueStateData?.teamName, team_type: 'my', }
        dispatch({ type: LEAGUE_STATE_DATA, payload: stateData })


        dispatch({ type: LEAGUE_TEAM_PREVIEW, payload: previewData })
        navigate('/league-team-preview')


    } catch (error) {
        console.log('err in handel create team function action==>>>>', error);
    }

}

export const points_preview_team = (navigate, team) => async (dispatch, getState) => {
    const { match_details } = getState()?.league;
    try {
        var payload = { "series_id": match_details?.series_id, "match_id": team?.match_id, "teamName": team?.team_number, "user_id": team?.user_id }
      
        const res = await dispatch(points_team_preview(payload))
        if (res?.success) {
            navigate('/league-team-preview')
        } else {
            toast.warn(res.msg)
        }
    } catch (error) {
        console.log('err in handel create team function action==>>>>', error);
    }

}


// *****************************************join contest flow*************************************

export const join_contest_check_wallet_soccer = (data) => async (dispatch, getState) => {
    const { match_details, selectedContest } = getState()?.league;
    try {
        // dispatch({ type: LOADER, payload: true })

        let payload = {
            match_id: match_details?.match_id,
            series_id: match_details?.series_id,
            contest_id: selectedContest?.id,
            entry_fees: Number(selectedContest?.entry_fee),
            league_type: Session.getSession('homeActiveKey') || 'cricket'
        }

        const res = await helper.api(apiPath.contest_check_wallet, 'post', payload)
        if (res) {
            dispatch({
                type: CHECK_WALLET_BALANCE_LEAGUE,
                payload: res?.results
            })
            dispatch({
                type: LOADER,
                payload: false
            })
            return res
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.error(res?.msg);
            return res
        }

    } catch (error) {
        //console.log('error in upcoming matches api::', error)
    }

}



export const check_wallet = (selectedTeam, navigate, state) => async (dispatch, getState) => {
    const { match_details, selectedContest } = getState()?.league;
    const data = match_details;


    const res = await dispatch(join_contest_check_wallet_soccer())
    // console.log('winnwer ocunt==>>>>>>>>>>>>>', state?.winners_count);

    const isEligible = (Number(res?.results?.winning_balance) + Number(res?.results?.cash_balance)) >= Number(res?.results?.entry_fee)
    if (res?.success && isEligible) {
        dispatch({
            type: CONTEST_CONFIRMATION_LEAGUE,
            payload: true
        })
        dispatch({
            type: SELECTED_TEAM_LEAGUE,
            payload: selectedTeam
        })
        // navigate(-2, { state, replace: true })
    } else {
        dispatch({
            type: LOW_BALANCE_LEAGUE,
            payload: true
        })
        navigate(-1, { state, replace: true })
    }
}

export const join_contest_confirm_league = (navigate, location) => async (dispatch, getState) => {
    const { match_details, selectedContest, selectedTeam, leagueStateData } = getState()?.league;
    try {
        // dispatch({ type: LOADER, payload: true })
        const payload = {
            "contest_id": selectedContest?._id,
            "team_id": selectedTeam?._id,
            "series_id": match_details?.series_id,
            "teamName": selectedTeam?.team_number,
            "match_id": match_details?.match_id,
            "timestamp_start": match_details?.timestamp_start
        }

        const res = await helper.api(apiPath.join_contest_league, 'post', payload)

        if (res?.success) {
            toast.success(res?.msg)
            dispatch({
                type: LOADER,
                payload: false
            })
            dispatch({
                type: CONTEST_CONFIRMATION_LEAGUE,
                payload: false
            })
            dispatch({ type: LEAGUE_MY_CONTEST_TAB, payload: 'myContest' })
            navigate(-1, { state: 'item', replace: true })
            return res
        } else {
            toast.error(res?.msg)
            dispatch({
                type: CONTEST_CONFIRMATION_LEAGUE,
                payload: false
            })
            navigate(-1, { state: 'item', replace: true })
            dispatch({
                type: LOADER,
                payload: false
            })
            return res
        }

    } catch (error) {
        console.log('error in join contest api==>>>>>::', error)
    }

}
export const get_user_teams = () => async (dispatch, getState) => {
    const { match_details, selectedContest, selectedTeam, leagueStateData } = getState()?.league;
    try {
        // dispatch({ type: LOADER, payload: true })
        const payload = { "series_id": match_details?.series_id, }

        const res = await helper.api(apiPath.get_user_teams, 'post', payload)
        dispatch({ type: LOADER, payload: false })
        return res
    } catch (error) {
        console.log('error in get_user_teams api==>>>>>::', error)
    }

}

export const checkUnJoinedTeams = () => async (dispatch, getState) => {
    const { match_details, selectedContest, selectedTeam, leagueStateData } = getState()?.league;
    try {
        dispatch({ type: LOADER, payload: true })
        const payload = { "series_id": match_details?.series_id, }

        const res = await helper.api(apiPath.check_unjoined_team, 'post', payload)
        if (res)
            dispatch({
                type: LOADER,
                payload: false
            })
        return res

    } catch (error) {
        console.log('error in get_user_teams api==>>>>>::', error)
    }

}



// ***************************************create Team Actions*************************************
//get all player list
export const get_all_player_list_league = (data, type) => async (dispatch, getState) => {
    const { homeActiveKey } = getState()?.loader;
    try {
        dispatch({ type: LOADER, payload: true })

        const res = await helper.api(apiPath.get_league_player_list, 'post', data)
        if (res?.success) {
            res.homeActiveKey = homeActiveKey
            dispatch({ type: TEAM_TYPE_LEAGUE, payload: type })
            dispatch({
                type: ALL_PLAYER_LIST_LEAGUE,
                payload: res
            })
            dispatch({
                type: LOADER,
                payload: false
            })
            return res
        } else {
            dispatch({
                type: ALL_PLAYER_LIST_LEAGUE,
                payload: []
            })
            dispatch({
                type: LOADER,
                payload: false
            })
            return res
        }

    } catch (error) {
        //console.log('error in upcoming matches api::', error)
    }

}

export const create_team_data = (navigate, type, state, route) => async (dispatch, getState) => {
    try {

        const { match_details, contestDetails, leagueStateData, leagueDetails } = getState()?.league;
        const { playerList } = getState()?.leagueTeam;

        var playerData = {
            contest_id: contestDetails?._id || '',
            team_id: '',
            match_id: match_details?.match_id,
            series_id: match_details?.series_id,
            boosterCode: leagueStateData?.selectedBooster?.boosterCode 
            
        }

        if (leagueStateData?.team_id) {
            playerData.team_id = leagueStateData?.team_id
        }
        if (leagueStateData?.teamName) {
            playerData.teamName = leagueStateData?.teamName
        }

        playerData.player_id = playerList.filter(player => player.isSelected === true)?.map(player => player.player_id)
        playerList.map(player => {
            if (player.isCaptain) {
                playerData.captain = player.player_id
            }
            if (player.isViceCaptain) {
                playerData.vice_captain = player.player_id
            }
            if (leagueStateData?.selectedBooster?.boosterCode === 1 && player?.isBoosted3x) {
                playerData.boostedPlayerId = player.player_id
            }
        })


        if (type === 'preview') {
            let result = await dispatch(get_league_transfer_preview(playerData))
     
            if(!result?.success){
                toast.error(result?.msg)
                return
            }
            navigate('/league-review-team')
            return
        }

        var result = await dispatch(create_Update_Team(playerData))
        if (result) {
            if (leagueStateData?.isDirectJoin) {
                var selectedTeam = { team_id: result?.team_id }
                dispatch(check_wallet(selectedTeam, navigate, state))
                navigate(-2, { state: state, replace: true })
            } else {
                dispatch({ type: LEAGUE_STATE_DATA, payload: {} })
                const payload = { series_id: leagueDetails?.id_api }
                dispatch(get_league_states(payload))
                if (type === 'saveTeam') {
                    navigate(-3, { state: state, replace: true })
                    return
                }
                navigate(-2, { state: state, replace: true })

            }
        }

    } catch (error) {
        console.log('err in handel create team function action==>>>>', error);
    }

}
export const update_team_data = (navigate, item, replace) => async (dispatch, getState) => {

    try {
        const { match_details, leagueStateData } = getState()?.league;
        const { homeActiveKey } = getState()?.loader;


        if (!item?._id) {
            navigate(-1)
            return
        }

        item.seriesPlayer = await item?.seriesPlayer?.map(player => player?.player_id)

        const currentTimeStamp = helper.start_time;
        if (match_details.timestamp_start > currentTimeStamp) {
            var data = { match_id: match_details?.match_id, visitor_team_id: match_details?.visitorteam_id, local_team_id: match_details?.localteam_id, series_id: match_details?.series_id }
            var stateEditData = {
                isDirectJoin: false, type: item?._id ? 'edit' : 'create', team_id: item?.team_id ? item?.team_id : leagueStateData?.team_id,
                boosterCode: item?.boosterCode,
                selectedBooster: item?.boosterDetails,
                teamName: item?.team_number
            }
            dispatch({ type: LEAGUE_STATE_DATA, payload: stateEditData })
            const result = await dispatch(get_all_player_list_league(data, 'Edit Team'))
            if (result?.success) {
                item.homeActiveKey = homeActiveKey
                dispatch({ type: EDIT_TEAM_LEAGUE, payload: item })
                dispatch({ type: PREVIOUS_APPLIED_BOOSTER, payload: item })

                if (item?.isDirectBooster) {
                    navigate('/league-save-team', { state: {}, replace: false })
                    return
                }
                navigate('/league-create-team', { state: {}, replace: replace })
            } else {
                return 'handleCloseWarningPopup'
            }

        } else {
            return 'handleCloseWarningPopup'
        }

    } catch (error) {
        console.log('err in update team action==>>>>', error);
    }
}


export const create_Update_Team = (data) => async (dispatch, getState) => {
    const { leagueStateData } = getState()?.league;
    try {

        dispatch({ type: LOADER, payload: true })
        const res = await helper.api(apiPath.create_update_league_team, 'post', data)
        //console.log('result of api==>>>>>>>', res)

        if (res?.success) {
            dispatch({ type: LEAGUE_STATE_DATA, payload: {} })
            // if (leagueStateData?.type !== 'edit') {
            //     dispatch({ type: LEAGUE_MY_TEAMS_ACCORDION, payload: 0 })
            // }
            dispatch({
                type: LOADER,
                payload: false
            })
            toast.success(res?.msg);
            return res?.results
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            toast.error(res?.msg);
            return false
        }

    } catch (error) {
        //console.log('error in update bank api::', error)
    }

}



export const validate_boosters = (data) => async (dispatch) => {

    try {
        dispatch({ type: LOADER, payload: true })

        const res = await helper.api(apiPath.validate_booster, 'post', data)
        if (res?.success) {
            dispatch({ type: LOADER, payload: false })
            // toast.success(res?.msg);
            return res
        } else {
            dispatch({ type: LOADER, payload: false })
            toast.error(res?.msg);
            return res
        }

    } catch (error) {
        console.log('error in validate boosters::', error)
    }

}


// export const navigateRoutes = (navigate, state, url, replace) => async (dispatch, getState) => {
//     const { leagueStateData } = getState()?.league;
//     const createUrl = '/'
//     const replace = replace ? replace : false
//     const route = url ? url : createUrl
//     navigate(route, { state, replace })
// }