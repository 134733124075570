import React from "react";
import Slider from "react-slick";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Session from "../utils/session";
import { useSelector } from "react-redux";
import BannerSlide from "./BannerSlide";
import LeftSlide from "./LeftSlide";

function LeftPanel() {
  const { banners_list, banner_path } = useSelector(state => state.match)

  const panelleft_slider = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };


  const navigate = useNavigate()

  const handleHomePage = () => {
    Session.setSession('activeMenu', '/Fantasy')
    navigate('/')
  }

  return (
<div className="InnerSidebar">
    <LeftSlide backgroundColor={'none'} />
</div>
  );
}

export default LeftPanel;
