import React, { useEffect } from "react";
import { useLocation } from "react-router";
import BottomMenu from "../components/BottomMenu";

const CommonBottom = () => {
  const location = useLocation();

  var noBottomMenu = [
    "/AllContest",
    "/addcash",
    "/contest-details",
    "/mycontest",
    "/player-info",
    "/transaction",
    "/withdrawamount",
    "/rewards",
    "/howtoplay",
    "/terms-condition",
    "/privacypolicy",
    "/legality",
    "/faqs",
    "/createcontest",
    "/prize-breakup",
    "/create-team",
    "/save-team",
    "/my-profile",
    "/verifykyc",
    "/fantasypoint",
    "/team-preview",
    "/select-team",
    "/update-team",
    "/notifications",
    "/profile",
    "/share-contest",
    //soccer routes=>>>>>>>>>>>
    "/transaction",
    "/notifications",
    "/deposit-payment",
    "/add-boosters",
    "/daily-shop",
    "/user-boosters",
    "/pool-detail",
    "/about-us",
    "/contact-us",
    "/refund-policy",
    "/verify-pan",
    "/verify-bank",
    "/verify-aadhaar",
    "/influencer"
  ];

  var noBottomAndButton = [
    "/contest-details",
    "/mycontest",
    "/privacypolicy",
    "/fantasypoint",
    "/verifykyc",
    "/transaction",
    "/notifications",
    "/share-contest",
    "/deposit-payment",
    // "/influencer"
  ];

  var checkBottom = noBottomMenu.includes(location?.pathname);
  var noButton = noBottomAndButton.includes(location?.pathname);

  useEffect(() => {
    if (checkBottom) {
      if (noButton) {
        document.getElementById("outlet-container")?.classList?.add("bottom-button-false");
        document.getElementById("outlet-container")?.classList?.remove("bottom-button-true");
      } else {
        document.getElementById("outlet-container")?.classList?.add("bottom-button-true");
        document.getElementById("outlet-container")?.classList?.remove("bottom-button-false");
      }
    } else {
      document.getElementById("outlet-container")?.classList?.remove("bottom-button-true");
      document.getElementById("outlet-container")?.classList?.remove("bottom-button-false");
    }

    document.getElementById("outlet-container").classList.remove("full_height");
   
  }, [location?.pathname]);


  return <>{noBottomMenu.includes(location?.pathname) ? <></> : <BottomMenu />}</>;
};

export default CommonBottom;
