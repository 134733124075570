import React, { useState } from 'react'
import Iframe from '../games/Iframe'
import { Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Session from '../../utils/session'
import { toast } from 'react-toastify'
import io from "socket.io-client";
import { get_profile } from '../../redux/actions/AuthActions'


const WebPayment = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { state, search   } = useLocation()
    const [loading, setLoading] = useState(true)
    const { isPaymentDeposit, paymentData } = useSelector(state => state.payment)

    const user = Session.getSession("user") || false;

    useEffect(() => {
        // const searchParams = new URLSearchParams(search);
        // const isCallBackUrl = searchParams.get("merchant_reference")
        // if (!isPaymentDeposit && !isCallBackUrl) {
        //     navigate(-1)
        // }
    }, [])

    // useEffect(() => {
    //     window.addEventListener("message", function (e) {
    //         console.log('main target===========================>>>>>>>', e)
    //         // if (e?.data === 'landscape') {
    //         //     //console.log('device in landscape mode')
    //         //     var target = document.getElementById("iframe-rummy");
    //         //     target.className += "fullScreen";
    //         // }
    //         // if (e?.data === 'portrait') {
    //         //     //console.log('device in portrait mode')
    //         //     var target = document.getElementById("iframe-rummy");
    //         //     target.classList.remove('fullScreen')
    //         // }
    //         // if (e?.data?.event === 'LeaveRummy') {

    //         // }

    //     });
    // }, []);


    // useEffect(() => {
    //     window.open(paymentData?.url, '_blank', 'width=500,height=500');
    // }, [])
    // const socket = io(process.env.REACT_APP_SOCKET_URL);

    // socket.on(`payment_success_${user?._id}`, (data) => {console.log('socket calledd==>>>>>>>>>')})

    const socket = io(process.env.REACT_APP_SOCKET_URL);


    useEffect(() => {
        socket.once(`payment_success_${user?._id}`, (data) => {
            toast.success('payment success!')
            dispatch(get_profile())
            if (state?.navigate === '/select-team') {
                navigate('/select-team', { state: state })
                return
            } else if (state?.navigate === '/soccer-select-team') {
                navigate('/soccer-select-team', { state: state })
                return
            }
            navigate('/wallet', { state: state, replace: true })


        })
        socket.once(`payment_failed_${user?._id}`, (data) => {
            toast.error('payment Failed!')
            navigate('/addcash')

        })
    }, [])


    // useEffect(() => {
    //     socket.off(`payment_success_${user?._id}`).on(`payment_success_${user?._id}`, (data) => {
    //         console.log('payment_success==>>>>>>', user?._id, data?.user_id);
    //         if (user?._id === data?.user_id) {
    //             dispatch(get_profile())
    //             navigate('/wallet')
    //             toast.success('payment success!')
    //         }
    //     })
    //     socket.off(`payment_failed_${user?._id}`).on(`payment_failed_${user?._id}`, (data) => {
    //         if (user?._id === data?.user_id) {
    //             toast.error('payment Faild!')
    //             navigate('/wallet')
    //         }
    //         console.log('payment_failed ==>>>>>>>', user?._id, data?.user_id);
    //     })
    // }, [])

    // useEffect(() => {
    //     setTimeout(() => {
    //         setLoading(false)
    //     }, 5000);
    // }, [])

    // if (loading) {
    //     return (
    //         <Spinner animation="border" role="status">
    //             <span className="visually-hidden">Loading...</span>
    //         </Spinner>
    //     )
    // }

    const OpenURL = () => { 

        // Create a new window with the specified dimensions and position
        var popup = window.open(paymentData?.url, "_self");

        // Check if the popup was blocked by the browser's popup blocker
        if (!popup || popup.closed || typeof popup.closed == "undefined") {
            alert("The popup window was blocked by the browser. Please allow popups for this website and try again.");
        }

    }



    return (
        <>
            {
                isPaymentDeposit ? (

                    // <iframe src={paymentData?.url} style={{ height: '100vh', width: '100%' }} id="iframe-rummy" />
                    OpenURL()
                ) : (
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                )
            }
        </>
    )
}

export default WebPayment

