import React from "react";
import { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import LeftPanel from "../../components/LeftPanel";
import RightPanel from "../../components/RightPanel";
import { getStaticPage } from "../../redux/actions/OtherActions";
import parser from 'html-react-parser';

function FoundingMember() {

    return (

        <div className="d-flex flex-column justify-content-between vh-100">
            <img src="../images/founding_member.png" />
        </div>
    );
}

export default FoundingMember;


