// import { LOGIN_SUCCESS, LOGOUT_SUCCESS, FORGOT_PASSWORD, VERIFY_OTP, PACKAGE_ID } from '../constant/Auth'
import Session from "../../utils/session";
import { LOGIN_SUCCESS, LOGOUT_SUCCESS, PROFILE_UPDATE, GET_PROFILE, EMAIL_VERIFICATION_LINK, GET_STATE_LIST, PAN_UPDATE, ACCOUNT_STATEMENT, OPEN_SIDE_NAV, WITHDRAW_LIST, ADMIN_SETTING, LOAD_ACCOUNT_STATEMENT } from "../constants/Auth";

var getSession = Session?.getSession("token")
var user = Session?.getSession("user")


let initialState = {
    user: user || {},
    token: getSession || null,
    auth: getSession ? true : false,
    user_profile: {},
    unread_msg: 0,
    profile_image_url: '',
    state_list: [],
    account_statement: [],
    transaction_totalDocs: 0,
    isOpen: false,
    withdrawList: {},
    adminSetting: {},
};



const LoginReducer = function (state = initialState, action) {
    try {
        switch (action.type) {
            case LOGIN_SUCCESS:
              //console.log('payload==>>>>>>>',action?.payload)
                Session.setSession("token", action?.payload?.token)
                Session.setSession("user", action?.payload?.result)
                return {
                    ...state,
                    auth: action?.payload?.success,
                    token: action?.payload?.token,
                    user: action?.payload?.result,
                };


            case LOGOUT_SUCCESS:
                Session.clearAllSession()
                return {
                    ...state,
                    auth: false,
                    token: null,
                    user: null,
                };


            case PROFILE_UPDATE:
                Session.setSession("user", action?.payload?.results)
                return {
                    ...state,
                    auth: action?.payload?.success,
                    token: action?.payload?.token,
                    user: action?.payload?.results,
                    user_profile: action?.payload?.results,
                };
            case GET_PROFILE:
                Session.setSession("user", action?.payload?.results)
                return {
                    ...state,
                    user: action?.payload?.results,
                    user_profile: action?.payload?.results,
                    unread_msg: action?.payload?.unread_msg,
                };
            case GET_STATE_LIST:
                return {
                    ...state,
                    state_list: action?.payload?.results,
                };
            case PAN_UPDATE:
                return {
                    ...state
                };
            case ACCOUNT_STATEMENT:
                return {
                    ...state,
                    transaction_totalDocs: action?.payload?.res?.results?.[0]?.totalDocs,
                    account_statement: action?.payload?.page === 1 ? action?.payload?.res?.results : [...state.account_statement, ...action?.payload?.res?.results]

                    // account_statement: action?.payload?.results
                    // account_statement: action?.payload?.results,
                };
            case LOAD_ACCOUNT_STATEMENT:
                return {
                    ...state,
                    transaction_totalDocs: action?.payload?.results?.[0]?.totalDocs,
                    account_statement: action?.payload?.results
                    // account_statement: [...state.account_statement, ...action?.payload?.results]
                    // account_statement: action?.payload?.results,
                };

            case OPEN_SIDE_NAV:
                return {
                    ...state,
                    isOpen: action.payload
                };

            case WITHDRAW_LIST:
                return {
                    ...state,
                    withdrawList: action.payload
                };

            case ADMIN_SETTING:
                return {
                    ...state,
                    adminSetting: action.payload
                };


            default:
                return state;
        }
    } catch (error) {
      //console.log('err in reducers==>>>>', error)
    }
};

export default LoginReducer;



