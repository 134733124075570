import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { OPEN_SIDE_NAV } from '../redux/constants/Auth'
// import LOGO from '../../public/images/menu-left.png'
import LOGO from '../assets/images/menu-left.png'
import { useLocation } from 'react-router-dom'
import { Dropdown } from 'react-bootstrap'
import { HOME_ACTIVE_KEY } from '../redux/constants/Common'
import helper from '../utils/helper'




const Profile = () => {
    const user_profile = useSelector(state => state?.auth?.user_profile)
    const dispatch = useDispatch()
    const location = useLocation()
    const { homeActiveKey } = useSelector(state => state.loader)

    const urls = {
        // '/wallet': helper?.textConverter( user_profile?.full_name, 15),
        '/refer-friends': 'Refer & Earn',
        '/Fantasy': 'Cricket',
        '/help': 'Help',
        "/league-fantasy": 'Cricket',
        "/wallet": `${user_profile?.username}`,
        "/claim": 'Claim',
        "/shop": 'Shop',
        "/chat-group": 'Community',
        "/money-pool": 'Money Pool',
    }

    // console.log('user_profile',user_profile?.full_name);

    const infoShow = () => {
        dispatch({
            type: OPEN_SIDE_NAV,
            payload: true
        })
    }



    var isUrl = ["/league-fantasy"]


    const handleUserTeam = (e, type) => {
        if (homeActiveKey === type) {
            return
        }
        dispatch({ type: HOME_ACTIVE_KEY, payload: type })
    }

    return (
        <>
            <div className="back_arrow">
                <div
                    className="d-flex align-items-center position-relative cursor-pointer"
                >
                    <div className="profile_pic">
                        {/* <img src={user_profile.image} className="me-3 " alt="" /> */}
                    </div>
                    <div className="sidmenu_icon" onClick={() => infoShow()}>
                        <img src={LOGO} className="me-3 " alt="" style={{ height: '20px', width: '22px' }} />
                    </div>
                    {
                        !isUrl?.includes(location?.pathname) &&
                        <span style={{fontSize:"16px"}}>{helper?.textConverter(urls[location?.pathname], 12)}</span>
                    }

                    {
                        isUrl?.includes(location?.pathname) &&
                        (
                            <div className="score-over-sec flex-fill mw-100 me-2 justify-content-end " >
                                    <span className='fw_700' style={{fontSize:'18px'}}>{helper?.textCapitalize(helper?.textConverter(urls[location?.pathname], 12))}</span>
                                        {"   "} &nbsp;
                            </div>
                        )
                    }
                </div>
            </div>
        </>
    )
}

export default Profile