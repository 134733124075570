
import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

// import reducers from './reducers/RootReducer'
import appReducer from './reducers/RootReducer'


// var rootReducer = combineReducers({ ...reducers });


let initialState = {}
const middleware = [thunk]



const store = createStore(
  appReducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
  );
  


export default store;



