import { React, useEffect, useState } from "react";
import {
	Col,
	Container,
	Row
} from 'react-bootstrap'
import Slider from 'react-slick'
import helper from "../../utils/helper";
import apiPath from "../../utils/apiPath";
// import { get_active_matches_list, get_live_and_upcoming_matches, get_home_banners, save_reminders } from "../../redux/actions/FantasyActions";
// const getData = async () => {
//     await dispatch(get_home_banners())
//   }


const Banner = () => {

	const [list, setList] = useState([])
	const [banner_path, setBannerPath] = useState([])

	const getHomeBannersList = async () => {
		const res = await helper.api(apiPath.get_home_banners, 'get', {})
		if (res?.success) {
			setList(res?.results?.docs)
			setBannerPath(res?.banner_path)
		}
	}


	useEffect(() => {
		getHomeBannersList()
	}, [])

	const settings = {
		dots: true,
		infinite: true,
		arrows: false,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
	}

	return (
		<div className='banner landingBanner'>
			<Container>
				<Slider {...settings}>
					{list?.length && list?.map((item => {
							return (
								<div>
									<Row className='align-items-center bannerRow'>
										<Col md={6}>
											<img src={`${banner_path}${item.image}`} alt='' className='mw-100' />
										</Col>
										<Col md={6}>
											<div className='caption'>
												<h1>
													{`${item.title}`}
												</h1>
												<p>
													{' '}
													“India's Fantasy - Where every cricket fan becomes a millionaire. India’s fantasy Sports Leagues”
												</p>
											</div>
										</Col>
									</Row>
								</div>
							)
						}))}
				</Slider>
			</Container>
		</div>
	)
}

export default Banner
