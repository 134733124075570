import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { HOME_ACTIVE_KEY } from '../redux/constants/Common'
import { LEAGUE_DETAILS } from '../redux/constants/league'
import Session from '../utils/session'
import { get_league_match_details, get_league_states } from '../redux/actions/LeagueAction'
import { get_match_detail } from '../redux/actions/FantasyActions'
import helper from '../utils/helper'

const GiftSocketPopup = ({ state, setState }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const closeWarning = () => {
        setState(false)
    }

    const getLeagueMatchDetails = async () => {
        const payload = { series_id: state?.series_id }
        dispatch(get_league_states(payload))
        dispatch(get_league_match_details(payload))
    }


    const getDailyMatchDetails = async () => {
        if (state?.match_id) {

            if (state?.match_type === 'soccer' || state?.match_type === 'Soccer') {
                dispatch({ type: HOME_ACTIVE_KEY, payload: 'cricket' })
            } else {
                dispatch({ type: HOME_ACTIVE_KEY, payload: 'cricket' })
            }



            const data = { match_id: state?.match_id }
            const res = await dispatch(get_match_detail(data))
            const currentTimeStamp = helper.start_time;
            //console.log('start==>>>>>>>>>>>',res?.results?.timestamp_start)

            if (state?.match_type === 'soccer' || state?.match_type === 'Soccer') {
                if (res?.success) {
                    Session.setSession('match_details', res?.results)
                    if (res?.results?.timestamp_start > currentTimeStamp) {
                        navigate('/all-contest-soccer', { state: res?.results })
                    } else {
                        res.results["status"] = res?.results?.match_status === "In Progress" ? "Live" : res?.results?.match_status
                        navigate('/mycontest-soccer', { state: res?.results })
                    }
                }

                return
            }

            if (res?.success) {
                if (res?.results?.timestamp_start > currentTimeStamp) {
                    navigate('/AllContest', { state: res?.results })
                } else {
                    res.results["status"] = res?.results?.match_status === "In Progress" ? "Live" : res?.results?.match_status
                    navigate('/mycontest', { state: res?.results })
                }
            }
        }
    }

    const goToLeaguePage = () => {
        var payload = {
            "name": state?.leagueName,
            "id_api": state?.series_id
        }
        Session.setSession('league_type', state?.leagueType)
        dispatch({ type: HOME_ACTIVE_KEY, payload: state?.match_type })
        dispatch({ type: LEAGUE_DETAILS, payload: payload })
        getLeagueMatchDetails()
        navigate('/league-home')
        setState(false)
    }



    const handleNavigate = () => {
        // if (state?.contestType === "daily") {
        //     getDailyMatchDetails()
        //     setState(false)
        // } else {
        //     goToLeaguePage()
        // }
    }



    return (

        <Modal show={state} onHide={closeWarning} className="contest_confirmation contest_confirmation_popup team_exits_dialog  debuff_popup for-border-none">
            <Modal.Header closeButton>

            </Modal.Header>

            <Modal.Body className=''>

                <div >

                    <figure className='gift-banner-div'>
                        <img src={state?.bannerImage} alt='' height={'auto'} className='cursor' onClick={handleNavigate} />
                    </figure>

                    <div className='gift-banner-span-div'>
                        <span>{state?.bannerText}</span>
                    </div>

                </div>
            </Modal.Body>

        </Modal >
    )
}

export default GiftSocketPopup

