import React from "react";
import { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import LeftPanel from "../../components/LeftPanel";
import RightPanel from "../../components/RightPanel";
import { getStaticPage } from "../../redux/actions/OtherActions";
import parser from 'html-react-parser';

function TermsConditions() {

  const dispatch = useDispatch();

  const staticPage = useSelector(state => state?.static?.staticPage)
  // console.log('staticc==>>>>>>', staticPage)

  useEffect(() => {
    dispatch(getStaticPage('terms-and-condition'))
  }, [])

  return (
    <>
      <div className="onlyHeader_scroll2 bg-dark p-4 after-login-static-pages text_content_page h-100">
        <h3>{staticPage?.title}</h3>
        <p> {staticPage?.content ? parser(staticPage?.content) : ''}</p>
      </div>

    </>
  );
}

export default TermsConditions;

