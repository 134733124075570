import React from 'react'
import helper from '../../utils/helper'
import { Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useNavigation } from 'react-router-dom'
import { useEffect } from 'react'
import { depositPayment } from '../../redux/actions/paymentActions'

const WalletHeader = () => {
    const location = useLocation()
    const navigate = useNavigate()

    const dispatch = useDispatch()
    const { user } = useSelector(state => state.auth)


    const payment = () => {
        navigate('/addcash')
    }



    if (location?.pathname !== '/wallet') {
        document.getElementById("outlet-container")?.classList?.remove('wallet_scroll_page')
        return ''
    }

    if (location?.pathname === '/wallet') {
        document.getElementById("outlet-container")?.classList?.add('wallet_scroll_page')
        // console.log('target==>>>', target);
    }



    return (
        <div className="wallet_header mt-4">
            <div className="text-center">
                <div className="d-flex text-white justify-content-center align-items-center">
                    <img src="./images/wallet_icon.png" alt="" />
                    <h4 className="ms-2 mt-2">Total Available Balance</h4>
                </div>

                <h3 className="m-2">
                    <strong style={{color:'#f7b32b'}}>{helper.localizeNumber('$', user?.total_balance)}</strong>
                    <img src="./images/gold_icon.png" alt="" className='ms-2' />
                </h3>

                <Button type="submit" className="btn-blue btn btn-primary w-100 mx-0 fw_700 mb-3" onClick={payment}>
                    Deposit
                </Button>
            </div>
        </div>
    )
}

export default WalletHeader