import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import apiPath from '../../../utils/apiPath'
import helper from '../../../utils/helper'
import { toast } from 'react-toastify'
import { Tab, Tabs } from 'react-bootstrap'
import { LOADER } from '../../../redux/constants/Auth'
import InfiniteScroll from 'react-infinite-scroll-component'


const LeagueHistory = () => {

  const { match_details, } = useSelector(state => state?.league)
  const { loader } = useSelector((state) => state?.loader);

  const [history, setHistory] = useState([])
  const [page, setPage] = useState(1)
  const [parPage, setParPage] = useState(10)
  const [type, setType] = useState('cricket')

  const dispatch = useDispatch()

  const getSeasonHistory = async () => {
    dispatch({ type: LOADER, payload: true })
    try {
      const path = `${apiPath.get_league_history}?page=${page}&itemsPerPage=${parPage}&type=${type}`

      const res = await helper.api(path, 'get', {})
      if (res?.success) {

        if (page == 1) {
          setHistory(res?.results?.docs)
        } else {
          setHistory((prev) => ([...prev, ...res?.results?.docs]))
        }
      } else {
        toast.warn(res?.msg)
      }

    } catch (error) {
      toast.warn(error?.message)
    }
    dispatch({ type: LOADER, payload: false })
  }


  useEffect(() => {
    getSeasonHistory()
  }, [type, page])


  const HistoryCard = () => {
    return (

      <div className='session_history_list_page'>
        <div className='session_history_list_main p-4'>

          {
            history?.length > 0 ? history?.map((item, i) =>
              <div className='section_history_list'>
                <span>{item?.leagueName} </span>
                <div className='section_history_feature'>
                  <div className='cols'>
                    <span className='text-yellow'>Ranking</span>
                    <strong className='text-white'>{item?.rank}</strong>
                  </div>

                  <div className='cols'>
                    <span className='text-yellow'>Total points</span>
                    <strong className='text-white'>{item?.totalSeriesPoint}</strong>
                  </div>

                  <div className='cols'>
                    <span className='text-yellow'>Prize won</span>
                    <strong className='text-white'>{item?.win_amount}</strong>
                  </div>
                </div>
              </div>
            )
              : !loader && <div className="not_found"><p className="text_white">{type === 'cricket' ? 'Cricket Season' : 'Soccer Season'} History Not Found!</p></div>
          }
        </div>
      </div>
    )

  }

  const historyType = (eventKey) => {
    setPage(1)
    setType(eventKey)
    setHistory([])
  }

  const loadMore = () => {
    setPage((page) => page + 1);
  };

  return (

    <div className="booster_page  bg-dark">
      <div className="booster_tab">
        <div className="inner_tabs booster_lists_tabs">

          <div className='season-history-advance bg-blue-dark' id="scrollableDiv">

            <InfiniteScroll
              dataLength={history.length}
              next={loadMore}
              hasMore={true}
              // loader={<h4>Loading...</h4>}
              scrollableTarget="scrollableDiv"
            >
              <HistoryCard />
            </InfiniteScroll>
          </div>

        </div>
      </div>
    </div>


  )
}

export default LeagueHistory