import React from 'react'
import {
    Row,
    Container,
    Col,
  } from "react-bootstrap";

const Offers = () => {
  return (
<section className="offers" id="offers">
          <Container>
            <div className="heading">
              <h2>Offers</h2>
              {/* <p>Get 200% Bonus on upto Rs. 1000</p> */}
            </div>

            <Row className="justify-content-center">
              <Col md={4}>
                <div className="offers_main text-center">
                  <img src="../images/refer_earn.png" />
                  <h3> Refer and earn</h3>
                </div>
              </Col>
              <Col md={4}>
                <div className="offers_main text-center">
                  <img src="../images/welcome_bonus.png" />
                  <h3>Huge Rewards</h3>
                </div>
              </Col>
              <Col md={4}>
                <div className="offers_main text-center">
                  <img src="../images/tournament.png" />
                  <h3> Unlimited Entertainment</h3>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
  )
}

export default Offers