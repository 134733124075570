import { useLocation, useNavigate, useRoutes } from "react-router-dom";

// routes
import Session from "../utils/session";
import CommonRoutes from "./CommonRoutes";
import LeagueRoutes from "./LeagueRoutes";
import MainRoutes from "./MainRoutes";
import PrivateRoutes from "./PrivateRoutes";





// ==============================|| ROUTING RENDER ||============================== //
export default function CombineRoutes() {
  const navigate = useNavigate();
  const location = useLocation();
  const Auth = Session.getSession("token") || false;
  const user = Session.getSession("user") || false;


  // if (!Auth) {
  //   location?.pathname === "/" && navigate("/signin");
  // }


  if (location?.pathname === '/signin' && location?.search && user?.username && user?.country) {
    const searchKey = location?.search?.split('?')?.[1]
    const decodeInviteUrl = searchKey ? atob(searchKey) : ''
    if (decodeInviteUrl) {
      setTimeout(() => {
        navigate(decodeInviteUrl)
      }, 500);
    }
  }

  // if(Auth)

  // const rugbyMatch = ["/all-contest-rugby", "/rugby-contest-details", "/rugby-team-preview", "/rugby-create-team", "/rugby-create-contest"]
  // const isRugbyPath = rugbyMatch?.includes(location?.pathname)

  // const isValidateMatch = Auth && 


  const authLayoutRoutes = Auth ? [PrivateRoutes, LeagueRoutes, CommonRoutes] : [MainRoutes, CommonRoutes]

  return useRoutes(
    authLayoutRoutes,
    process.env.BASENAME
  );
}
