
import Session from "../../utils/session";
import { BOOSTER_GIFT_LIST_DAILY, BOOSTER_GIFT_OTHER_DAILY, BOOSTER_GIFT_POPUP_DAILY, BOOSTER_LIST_TABS_DAILY, DEBUFF_BOOSTER_SEND_DAILY, GET_DAILY_BOOSTER_LIST, SEND_GIFT_POPUP_DAILY, SEND_GIFT_TYPE_DAILY, STATE_DATA__DAILY } from "../constants/Common";
import { GET_STATIC_PAGE, GET_NOTIFICATION_PAGE, SET_REMINDER, TEAM_PREVIEW, GET_NOTIFICATION_SCROLL_DATA, MONEY_POOL_TAB, MONEY_POOL_TAB_TYPE, SELECT_POOL_CATEGORY, SHOW_MEDIA } from "../constants/Other";
import { CREATE_TRANS_ID } from "../constants/Payment";


let initialState = {
    staticPage: {},
    notificationList: [],
    totalDocs: '',
    isOpen: false,
    teamPreview: false,


    updatedBoostersList: [],
    totalBoosters: 0,
    usedBoosters: 0,
    boosterListTab: 'booster',
    boosterGiftPopUp: false,
    boosterGiftList: [],
    sendPopup: false,
    shareBooster: {},
    shareBoosterType: '',
    debuffBooster: {},
    aapiledDebuffBooster: {},
    // dailyStateData: Session.getSession('STATE_DATA__DAILY') || {},
    dailyStateData: {},
    money_pool_tab: 'live',
    money_pool_tab_type: 'liveEvent',
    selectedCategory: null,
    showMedia: false


};



const OtherReducer = function (state = initialState, action) {
    try {
        switch (action.type) {


            case GET_STATIC_PAGE:
                return {
                    ...state,
                    staticPage: action.payload
                };

            case GET_NOTIFICATION_PAGE:
                return {
                    ...state,
                    // notificationList: action.payload
                    totalDocs: action.payload?.results?.totalDocs,
                    notificationList: action?.payload?.results?.docs
                };
            case GET_NOTIFICATION_SCROLL_DATA:

                return {
                    ...state,
                    totalDocs: action.payload?.results?.totalDocs,
                    notificationList: [...state.notificationList, ...action?.payload?.results?.docs]
                };
            case SET_REMINDER:
                return {
                    ...state,
                    isOpen: action.payload
                };
            case TEAM_PREVIEW:
                return {
                    ...state,
                    teamPreview: action.payload
                };

            ///daily contest ==>>>>>>>>>>>>>>>>>>>>>>>>
            case GET_DAILY_BOOSTER_LIST:
                return {
                    ...state,
                    updatedBoostersList: action?.payload?.results?.updatedBoostersList,
                    debuffBooster: action?.payload?.results?.debuffBooster,
                    totalBoosters: action?.payload?.totalBoosters,
                    usedBoosters: action?.payload?.usedBoosters
                };

            case BOOSTER_LIST_TABS_DAILY:
                return {
                    ...state,
                    boosterListTab: action?.payload
                };
            case STATE_DATA__DAILY:
                // Session.setSession('STATE_DATA__DAILY', action?.payload)
                return {
                    ...state,
                    dailyStateData: action?.payload
                };

            case BOOSTER_GIFT_LIST_DAILY:
                return {
                    ...state,
                    shareBooster: { myBooster: action?.payload?.booster, other: '' },
                    boosterGiftList: action?.payload?.giftBooster,
                };

            case BOOSTER_GIFT_POPUP_DAILY:
                return {
                    ...state,
                    boosterGiftPopUp: action?.payload
                };

            case SEND_GIFT_TYPE_DAILY:
                return {
                    ...state,
                    shareBoosterType: action?.payload,
                };

            case BOOSTER_GIFT_OTHER_DAILY:
                return {
                    ...state,
                    shareBooster: { ...state.shareBooster, other: action?.payload },
                };
            case SEND_GIFT_POPUP_DAILY:
                return {
                    ...state,
                    sendPopup: action?.payload
                };

            case DEBUFF_BOOSTER_SEND_DAILY:
                return {
                    ...state,
                    aapiledDebuffBooster: action?.payload,
                };
            // case GET_USER_BOOSTER_LIST:
            //     return {
            //         ...state,
            //         totalBoosters: action?.payload?.totalCount,
            //         updatedBoostersList: action?.payload?.results
            //     };

            case MONEY_POOL_TAB:
                return {
                    ...state,
                    money_pool_tab: action?.payload
                };
            case MONEY_POOL_TAB_TYPE:
                return {
                    ...state,
                    money_pool_tab_type: action?.payload
                };

            case SELECT_POOL_CATEGORY:
                return {
                    ...state,
                    selectedCategory: action?.payload
                };

            case SHOW_MEDIA:
                return {
                    ...state,
                    showMedia: action?.payload
                };

            default:
                return state;
        }
    } catch (error) {
        //console.log('err in payment reducers==>>>>', error)
    }
};

export default OtherReducer;