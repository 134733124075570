import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import apiPath from "../utils/apiPath";
import helper from "../utils/helper";

function RightPanel() {

  const [androidLink, setAndroidLink] = useState("")
  const [iosLink, setIosLink] = useState("")

	const getDownloadLinks = async () => {
		const res = await helper.api(apiPath.getSetting, 'get', {})
		if (res?.success) {
			setAndroidLink(res?.results?.android_app_link)
			setIosLink(res?.results?.ios_app_link)
		}
	}


	useEffect(() => {
		getDownloadLinks()
	}, [])


  return (
    <div className="right_panel d-flex flex-column justify-content-between align-items-center">
 

      <div className="download_app_icon">
      <Link to="/"><div className="login_logo d-block cursor"><img src="../images/Indias_fantasy.png" alt="" style={{maxHeight:"164px"}} /></div></Link>
        <h6 className="text-white pb-3">Fantasy App Available On Mobile!</h6>
        <Link to={iosLink} target="_blank" className="me-2" >
          <img src="./images/app_store.png" alt="" />
        </Link>
        <Link to={androidLink}>
          <img src="./images/play_store.png" alt="" />
        </Link>
      </div>

     
    </div>
  );
}

export default RightPanel;
