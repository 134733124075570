import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { LOADER } from '../../redux/constants/Auth'
import apiPath from '../../utils/apiPath'
import helper from '../../utils/helper'
import { toast } from 'react-toastify'

const UserBoosters = () => {

    const dispatch = useDispatch()
    const [boostersList, setBoostersList] = useState([])


    const getBoosterList = async () => {
        dispatch({ type: LOADER, payload: true })
        try {

            const path = apiPath.getUserBoostersList
            const res = await helper.api(path, 'get', {})
            if (res?.success) {
                setBoostersList(res?.results)
                dispatch({ type: LOADER, payload: false })
                return true
            } else {
                dispatch({ type: LOADER, payload: false })
                toast.warn(res?.msg)
                return false
            }

        } catch (error) {
            dispatch({ type: LOADER, payload: false })
            //console.log('error in upcoming matches api::', error)
            return false
        }


    }

    useEffect(() => {
        getBoosterList()
    }, [])


    const colorMatch = {
        "Standard": "#344452",
        "Elite": "#413452",
        "Legendary": "#7C754E",
    }


    const BoosterIcons = ({ item }) => {
        return (
            <span className="card_img_overlay flex_column" style={{ display: 'flex' }}>
                {item?.gameType?.includes('cricket') && <img src="../images/cricket_ic.png" alt='' />}
                {item?.gameType?.includes('soccer') && <img src="../images/football.png" alt='' className='my-1' />}
                {item?.gameType?.includes('rugby') && <img src="../images/rugby_ic.png" alt='' />}
            </span>
        )
    }

    return (
        <div className="standard_booster_list_outer px-4" >
            {
                boostersList && boostersList?.length > 0 ? boostersList?.map((item, i) => (
                    <div className="standard_booster_list cursor"
                        style={{ background: colorMatch[item?.subCategoryName] }}
                    >

                        <figure>
                            <img src={item?.booster_image} height={'148.48px'} width={'178px'} alt='' />
                            <BoosterIcons item={item} />
                        </figure>
                        <figcaption>
                            <h3>
                                <a href="#">{item?.title}</a>
                            </h3>
                            <p>{helper.textConverter(item?.description, 40)}</p>

                            <div className="card_footer">
                                <span>{item?.subCategoryName}</span>
                                <div className="standard_value">
                                    <span>{item?.price}</span>
                                    <img src="../images/gold_icon.png" alt="" />
                                </div>
                            </div>
                        </figcaption>
                    </div>
                )) :
                    (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',  textAlign: 'center',    height: '60vh' }} className="px-4 text-white">
                            <span>You do not have any boosters/debuff in your inventory. Visit the shop to purchase boosters/debuffs.</span>
                        </div>
                    )
            }



        </div>


    )
}

export default UserBoosters