import { useEffect, lazy } from 'react'

import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import Loadable from '../components/loader/Loadable'
import BaseLayout from '../layout/BaseLayout'
import Session from '../utils/session'
import Home from '../pages/home/Home'


const ResponsiblePlay = Loadable(lazy(() => import('../pages/other/WebResponsiblePlay')))

// import component
const Signin = Loadable(lazy(() => import('../pages/login/Signin')))
const Otp = Loadable(lazy(() => import('../pages/login/Otp')))
const Welcome = Loadable(lazy(() => import('../pages/userWallet/Welcome')))

const Signup = Loadable(lazy(() => import('../pages/login/Signup')))
const ForgotPassword = Loadable(lazy(() => import('../pages/login/ForgotPassword')))
const GoogleSignIn = Loadable(lazy(() => import('../pages/login/GoogleSignIn')))
const SignUpOptions = Loadable(lazy(() => import('../pages/login/SignUpOptions')))


const Auth = Session.getSession('token') || false
//= ====>>>>>>>not found page
const NotFound = ({ to }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams();



  useEffect(() => {

    const inviteCode = searchParams.get('code')
    if (location.pathname === '/contestinvite' && inviteCode) {
      const url = location?.pathname + location?.search
      const enCode = btoa(url)
      const navigateUrl = `${to}?${enCode}`
      navigate(navigateUrl)
    } else {
      navigate(to)
    }
  }, [])
}

const HomeRedirect = ({ to }) => {
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    navigate(to)
  }, [])

  if (!Auth) {
    location?.pathname === '/' && navigate('/home')
  }
}

const MainRoutes = {

  // All common routes
  path: '/',
  element: <BaseLayout />,
  children: [
    {
      path: '/',
      element: <Home />
    },
    // {
    //   path: '/',
    //   element: <HomeRedirect to='/home' />
    // },
    // {
    //   path: '/signin',
    //   element: <Signin />
    // },
    {
      path: '/signin',
      element: <GoogleSignIn/>
    },
    {
      path: '/sign-up-options',
      element: <SignUpOptions/>
    },
    {
      path: '/sign-up',
      element: <Signup />
    },
    {
      path: '/forgot-password',
      element: <ForgotPassword />
    },
    {
      path: '/Otp',
      element: <Otp />
    },
    {
      path: '/welcome',
      element: <Welcome />
    },
    {
      path: '/responsiblePlay',
      element: <ResponsiblePlay />
    },
    {
      path: '*',
      element: <NotFound to='/signin' />
    }
  ]
}

export default MainRoutes
