import React from "react";
import { Tabs, Tab, Accordion, Container, Row, Col, Form, InputGroup, Button } from "react-bootstrap";
import Slider from "react-slick";

const Our_fantasy = () => {

  var fantasyapp = {
    // dots: false, sonar bug
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    centerMode: true,
    centerPadding: '150px',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          centerPadding: '0',
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centerPadding: '0'          
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '0'
        }
      }
    ]
  };

    
    // const fantasyapp = {
    //     dots: false,
    //     arrows: false,
    //     infinite: true,
    //     slidesToShow: 5,
    //     slidesToScroll: 1,
    //     autoplay: true,
    //     autoplaySpeed: 2000,
    //     centerMode: true,
    //     centerPadding: '150px',
    //   };
      
  return (
    <section className="our_fantasy position-relative" id="our_fantasy">
         
        <div className="heading text-center mb-md-5 mb-3">
                <h2>Our Fantasy App</h2>
        </div>

       
        <Slider {...fantasyapp}>
             <div><figure className="fantasy_screen"><img src="images/screen1.png"/></figure></div>
             <div><figure className="fantasy_screen"><img src="images/screen2.png"/></figure></div>
             <div><figure className="fantasy_screen"><img src="images/screen3.png"/></figure></div>
             <div><figure className="fantasy_screen"><img src="images/screen4.png"/></figure></div>
             <div><figure className="fantasy_screen"><img src="images/screen5.png"/></figure></div>
             <div><figure className="fantasy_screen"><img src="images/screen6.png"/></figure></div>
             <div><figure className="fantasy_screen"><img src="images/screen7.png"/></figure></div> 
        </Slider>      
      
           

        
  </section>
  )
}

export default Our_fantasy