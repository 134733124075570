import Session from "../../utils/session";
import { BOOSTER_GIFT_LIST, BOOSTER_GIFT_OTHER, BOOSTER_GIFT_POPUP, BOOSTER_LIST_TABS, CHECK_WALLET_BALANCE, CHECK_WALLET_BALANCE_LEAGUE, CLEAR_LEAGUE_STATES, CONTEST_CONFIRMATION_LEAGUE, DEBUFF_BOOSTER_SEND, EXITS_CREATE_TEAM, GET_SERIES_LIST, GET_USER_BOOSTER_LIST, INFO_POP_UP, LEADER_BOARD_SCROLL_DATA, LEAGUE_CONTEST_DETAILS_TAB, LEAGUE_DETAILS, LEAGUE_DETAILS_TAB, LEAGUE_LEADER_BOARD_DATA, LEAGUE_LIST_POP_UP, LEAGUE_MY_CONTEST_TEAMS_CLEAR, LEAGUE_MY_TEAMS_ACCORDIAN, LEAGUE_MY_TEAMS_ACCORDION, LEAGUE_PLAYERS_POINT_AND_TRANSFER, LEAGUE_SELECTED_CONTEST, LEAGUE_STATE_DATA, LEAGUE_STATS_TEAM_COUNT, LEAGUE_TEAM_PREVIEW, LEAGUE_TOP_PLAYERS, LEAGUE_TRANSFER_PREVIEW, LOW_BALANCE_LEAGUE, POINTS_HISTORY_TEAM_SELECT, SELECTED_TEAM_LEAGUE, SEND_GIFT_POPUP, SEND_GIFT_TYPE, TEAM_EXITS } from "../constants/league";
import { GET_LEAGUE_BOOSTER_LIST, GET_LEAGUE_MATCH_DETAILS, GET_LEAGUE_STATES, LEAGUE_ALL_CONTEST, LEAGUE_MY_CONTEST, LEAGUE_MY_CONTEST_TAB, LEAGUE_MY_CONTEST_TEAMS, LEAGUE_SERIES_PLAYER_LIST } from "../constants/league";


const league_stats = Session.getSession('GET_LEAGUE_STATES')


let initialState = {
    leagueDetails: Session.getSession('LEAGUE_DETAILS') || {},
    leagueList: {},
    match_details: Session.getSession('MATCH_DETAIL_LEAGUE') || {},
    league_states: league_stats || {},
    PlayersTeamsData: [],
    updatedBoostersList: [],
    totalBoosters: 0,
    usedBoosters: 0,
    allContest: [],
    myContest: [],
    myTeams: [],
    seriesPlayerList: [],
    seriesMyTeam: {},
    leaderBoardData: {},
    contestCounts: { full_contest: 0, my_contests: 0, my_teams: 0 },
    myContestTab: 'allContest',

    contestDetailsTab: 'general',
    leagueContestDetailsTab: 'leader_board',
    leagueStateData: Session.getSession('LEAGUE_STATE_DATA') || {},
    teamsAccordion: league_stats?.userTeams[0] || { team_count: 1 },
    topPlayers: [],
    teamDetails: {},
    leagueListPopUp: false,
    infoPopUp: false,
    transferPreview: Session.getSession('LEAGUE_TRANSFER_PREVIEW') || {},
    teamPreview: Session.getSession('LEAGUE_TEAM_PREVIEW') || {},
    selectedContest: Session.getSession('LEAGUE_SELECTED_CONTEST') || {},
    walletBalance: Session.getSession('CHECK_WALLET_BALANCE_LEAGUE') || {},
    isContestConfirm: false,
    lowBalance: false,
    selectedTeam: {},
    teamExits: false,
    isExitsCreateTeam: false,
    selectedTeamPoints: 1,
    statsTeamsCount: 1,
    boosterListTab: 'booster',
    boosterGiftPopUp: false,
    boosterGiftList: [],
    sendPopup: false,
    shareBooster: {},
    shareBoosterType: '',
    debuffBooster: {},
    aapiledDebuffBooster: {},

};



const LeagueReducer = function (state = initialState, action) {
    try {
        switch (action.type) {
            case LEAGUE_DETAILS:
                Session.setSession('LEAGUE_DETAILS', action?.payload)
                return {
                    ...state,
                    leagueDetails: action?.payload
                };
            case GET_SERIES_LIST:
                Session.setSession('GET_LEAGUE_LIST', action?.payload?.results)
                return {
                    ...state,
                    leagueList: action?.payload?.results
                };
            case GET_LEAGUE_MATCH_DETAILS:
                if (action?.payload?.results) {
                    Session.setSession('MATCH_DETAIL_LEAGUE', action?.payload?.results)
                }
                return {
                    ...state,
                    match_details: action?.payload?.results
                };

            case GET_LEAGUE_STATES:
                if (action?.payload?.results) {
                    Session.setSession('GET_LEAGUE_STATES', action?.payload?.results)
                }
                return {
                    ...state,
                    league_states: action?.payload?.results,
                };

            case GET_LEAGUE_BOOSTER_LIST:
                return {
                    ...state,
                    updatedBoostersList: action?.payload?.results?.updatedBoostersList,
                    PlayersTeamsData: action?.payload?.results?.PlayersTeamsData,
                    debuffBooster: action?.payload?.results?.debuffBooster,
                    totalBoosters: action?.payload?.totalBoosters,
                    usedBoosters: action?.payload?.usedBoosters
                };
            case BOOSTER_GIFT_POPUP:
                return {
                    ...state,
                    boosterGiftPopUp: action?.payload
                };
            case BOOSTER_GIFT_LIST:
                const allBoosters = [...state.updatedBoostersList, ...state.debuffBooster]
                const giftBooster = allBoosters?.filter(booster => !booster.isApplied && !booster.isExpired && booster.boosterCode != 9 && !booster?.isCurrentlyUsed)
                return {
                    ...state,
                    shareBooster: { myBooster: action?.payload, other: '' },
                    boosterGiftList: giftBooster,
                };
            case BOOSTER_GIFT_OTHER:
                return {
                    ...state,
                    shareBooster: { ...state.shareBooster, other: action?.payload },
                };
            case SEND_GIFT_TYPE:
                return {
                    ...state,
                    shareBoosterType: action?.payload,
                };
            case DEBUFF_BOOSTER_SEND:
                return {
                    ...state,
                    aapiledDebuffBooster: action?.payload,
                };
            case GET_USER_BOOSTER_LIST:
                return {
                    ...state,
                    totalBoosters: action?.payload?.totalCount,
                    updatedBoostersList: action?.payload?.results
                };

            case LEAGUE_ALL_CONTEST:
                return {
                    ...state,
                    allContest: action?.payload?.results || 0,
                    contestCounts: { full_contest: action?.payload?.full_contest, my_contests: action?.payload?.my_contests, my_teams: action?.payload?.my_teams }
                };
            case LEAGUE_MY_CONTEST:
                return {
                    ...state,
                    myContest: action?.payload?.results,
                    contestCounts: { full_contest: action?.payload?.full_contest, my_contests: action?.payload?.my_contests, my_teams: action?.payload?.my_teams }
                };
            case LEAGUE_MY_CONTEST_TEAMS:
                return {
                    ...state,
                    myTeams: action?.payload?.results,
                    contestCounts: { full_contest: action?.payload?.full_contest, my_contests: action?.payload?.my_contests, my_teams: action?.payload?.my_teams }
                };

            case LEAGUE_MY_CONTEST_TAB:
                return {
                    ...state,
                    myContestTab: action?.payload,
                };
            case LEAGUE_DETAILS_TAB:
                return {
                    ...state,
                    contestDetailsTab: action?.payload,
                };
            case LEAGUE_CONTEST_DETAILS_TAB:
                return {
                    ...state,
                    leagueContestDetailsTab: action?.payload,
                };
            case LEAGUE_SERIES_PLAYER_LIST:
                return {
                    ...state,
                    seriesPlayerList: action?.payload?.results?.seriesPlayerList,
                    seriesMyTeam: action?.payload?.results?.my_teams
                };
            case LEAGUE_LEADER_BOARD_DATA:
                return {
                    ...state,
                    leaderBoardData: action?.payload?.results
                };

            case LEADER_BOARD_SCROLL_DATA:
                return {
                    ...state,
                    leaderBoardData: { ...state.leaderBoardData, ['otherJoinedTeams']: [...state.leaderBoardData?.otherJoinedTeams, ...action?.payload?.results?.otherJoinedTeams] }
                };

            case LEAGUE_STATE_DATA:
                Session.setSession('LEAGUE_STATE_DATA', action?.payload)
                return {
                    ...state,
                    leagueStateData: action?.payload
                };
            case LEAGUE_MY_TEAMS_ACCORDION:
                return {
                    ...state,
                    teamsAccordion: action?.payload
                };
            case LEAGUE_TOP_PLAYERS:
                return {
                    ...state,
                    topPlayers: action?.payload?.results
                };
            case LEAGUE_PLAYERS_POINT_AND_TRANSFER:
                return {
                    ...state,
                    teamDetails: action?.payload?.results
                };
            case LEAGUE_LIST_POP_UP:
                return {
                    ...state,
                    leagueListPopUp: action?.payload
                };
            case INFO_POP_UP:
                return {
                    ...state,
                    infoPopUp: action?.payload
                };
            case LEAGUE_TRANSFER_PREVIEW:
                Session.setSession('LEAGUE_TRANSFER_PREVIEW', action?.payload?.results)
                return {
                    ...state,
                    transferPreview: action?.payload?.results
                };

            case LEAGUE_TEAM_PREVIEW:
                Session.setSession('LEAGUE_TEAM_PREVIEW', action?.payload)
                return {
                    ...state,
                    teamPreview: action?.payload
                };
            case LEAGUE_SELECTED_CONTEST:
                Session.setSession('LEAGUE_SELECTED_CONTEST', action?.payload)
                return {
                    ...state,
                    selectedContest: action?.payload
                };
            case CHECK_WALLET_BALANCE_LEAGUE:
                Session.setSession('CHECK_WALLET_BALANCE_LEAGUE', action?.payload)
                return {
                    ...state,
                    walletBalance: action?.payload
                };

            case CONTEST_CONFIRMATION_LEAGUE:
                return {
                    ...state,
                    isContestConfirm: action?.payload
                };
            case LOW_BALANCE_LEAGUE:
                return {
                    ...state,
                    lowBalance: action?.payload
                };

            case SELECTED_TEAM_LEAGUE:
                return {
                    ...state,
                    selectedTeam: action?.payload
                };
            case TEAM_EXITS:
                return {
                    ...state,
                    teamExits: action?.payload
                };
            case EXITS_CREATE_TEAM:
                return {
                    ...state,
                    isExitsCreateTeam: action?.payload
                };

            case POINTS_HISTORY_TEAM_SELECT:
                return {
                    ...state,
                    selectedTeamPoints: action?.payload
                };

            case LEAGUE_STATS_TEAM_COUNT:
                return {
                    ...state,
                    statsTeamsCount: action?.payload
                };
            case BOOSTER_LIST_TABS:
                return {
                    ...state,
                    boosterListTab: action?.payload
                };


            case SEND_GIFT_POPUP:
                return {
                    ...state,
                    sendPopup: action?.payload
                };



            // *********************************clear state****************************************
            case LEAGUE_MY_CONTEST_TEAMS_CLEAR:
                return {
                    ...state,
                    myTeams: [],
                };



            default:
                return state;
        }
    } catch (error) {
        //console.log('err in reducers==>>>>', error)
    }
};

export default LeagueReducer;