import React, { useEffect, useState } from "react";
import { Tabs, Tab, Accordion, Container, Row, Col, Form, InputGroup, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { LOADER } from "../../redux/constants/Auth";
import apiPath from "../../utils/apiPath";
import helper from "../../utils/helper";
import {QRCodeSVG} from 'qrcode.react';


const Games = ({androidLink, iosLink}) => {

  const dispatch = useDispatch()
  const [mobile, setMobile] = useState('')
  const [err, setErr] = useState(false)

  const sendSms = async () => {
    if (!mobile) {
      setErr('mobile number is required')
      return
    }
    if (mobile?.length < 8 || mobile?.length > 12) {
      setErr('invalid mobile')
      return
    }
    try {

      dispatch({ type: LOADER, payload: true })

      var path = apiPath.sendApkLink;
      const payload = {
        mobile: mobile,
        country_code: "+91"
      }

      const res = await helper.api(path, 'post', payload)
      //console.log('send link===>>>>>',res)

      if (res?.success) {
        dispatch({
          type: LOADER,
          payload: false
        })
        toast.success(res?.msg);
        setMobile('')
        setErr(false)
        return res
      } else {
        dispatch({
          type: LOADER,
          payload: false
        })
        toast.error(res?.msg);
        return false
      }

    } catch (error) {
      //console.log('error in send link api::', error)
    }

  }
  return (
    <div className="why_game">
      <Container>
        <div className="download_app">
          <Row>
            <Col md={6}>
              <div className="box box-bg-gradient p-3 p-sm-4 text-center">
                <h3 className="text-black">
                  Download APP  
                </h3>
                {/* <p className="coming_soon_text" style={{color:'black', fontWeight:600}}>Comming Soon...</p> */}
                <a href={iosLink} target="_blank" rel="noreferrer" className="mb-3 d-block app_icon">
                  <img src="../images/app_store.png" alt="" />
                </a>
                <a href={androidLink} target="_blank" rel="noreferrer" className="app_icon">
                  <img src="../images/play_store.png" alt="" />
                </a>
              </div>
            </Col>
            {/* <Col md={4}>
              <div className="box box-bg-gradient p-3 p-sm-4 text-center">
                <h3 className="text-black">Download via SMS</h3>
                
                <InputGroup className="mb-3">
                  <Form.Control
                    className="border-end-0"
                    placeholder="Enter Mobile Number"
                    aria-label="Amount (to the nearest dollar)"
                    type="number"
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                    style={{background:"#D9DCDF"}}
                  />
                  <InputGroup.Text className="" style={{background:"#D9DCDF"}}>
                    <img src="../images/telephone.png" alt="" />
                  </InputGroup.Text>
                </InputGroup>
                
                {
                  err &&
                  <span style={{ color: 'red' }}>{err}</span>
                }
                <Button className="btn d-block border-0 w-100" style={{background:"#03881A"}} type="" onClick={sendSms}>
                  Send Link
                </Button>
                
              </div>
            </Col> */}
            <Col md={6}>
              <div className="box box-bg-gradient p-3 p-sm-4 text-center">
                <h3 className="text-black">Scan QR Code</h3>
                <QRCodeSVG value={androidLink} />
              </div>
            </Col>
          </Row>
        </div>

        <div className="hungama_game">
          <div className="heading">
            <h2 className="mb-3 mb-md-5">Let the games begin…</h2>
          </div>

          <div className="hungama_game_divider">
            <Row>
              <Col md={4}>
                <div className="box">
                  <div className="icon">
                    <img src="../images/deposit.svg" alt="" />
                  </div>
                  <div className="text">
                    <h4 className="text-green">Deposit</h4>
                    <p>Deposit FIAT into your India Fantasy Custody Wallet</p>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className="box">
                  <div className="icon">
                    <img src="../images/grade.svg" alt="" />
                  </div>
                  <div className="text">
                    <h4 className="text-green">Institutional Grade Custody</h4>
                    <p>Safely storing your digital assets to provide ease of use & top tier security</p>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className="box">
                  <div className="icon">
                    <img src="../images/play.svg" alt="" />
                  </div>
                  <div className="text">
                    <h4 className="text-green">Play & Earn</h4>
                    <p>Play daily & seasonal contests and win pooled prizes & bonuses based on your ranking</p>

                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Games