import React, { useEffect, useState } from "react";
import { Tabs, Tab, Accordion, Container, Row, Col, Form, InputGroup, Button } from "react-bootstrap";
import helper from "../../utils/helper";
import apiPath from "../../utils/apiPath";

const Download = ({androidLink, iosLink}) => {
  return (
    <section className="download-sec position-relative" id="Download">
    <Container>
      <Row className="align-items-center">
      <Col md={6}>
          <figure className="text-md-end">
            <img src="./images/download_app.png" alt="" />
          </figure>
        </Col>
        <Col md={6}>
          <div className="download-inner">
            <div className="heading text-start mb-md-5 mb-3">
              <h2>How to Download</h2>
            </div>
            <ul className="download-step ps-0">
              <li>
                <h4>Step 1. Download the App and Sign Up:</h4>                
                <p>Download the India’s Fantasy app from the App Store or Google Play Store.</p>          
                <p>Sign up for an account by providing your details such as name, email, and password.get your KYC done.</p>          
                <p>Verify your account through the email or mobile verification process.</p>          
              </li>

              <li>
                <h4>Step 2. Select a Match:</h4> 
                <p>Browse through the list of upcoming matches available on the India’s Fantasy platform. </p> 
                <p>Choose a match that you want to create a fantasy team for and participate in. </p> 
              </li>

              <li>
                <h4>Step 3. Create Your Team:</h4> 
                <p>Use your knowledge and skills to select players for your fantasy team within the designated budget.</p>
                <p>Choose players from the match you selected, including batsmen, bowlers, all-rounders, and wicket-keepers.</p>
                <p> Assign a captain and vice-captain to your team for bonus points. </p>
              </li>
              <li>
                <h4>Step 4. Join Free or Cash Contests:</h4> 
                <p>Explore the contests available for the match, including free contests and cash contests with entry fees.</p>
                <p>Join a free contest to practice and improve your skills or join a cash contest to compete for real prizes.</p>
                <p>Pay the entry fee for cash contests if required and confirm your participation.</p>
              </li>
              <li>
                <h4>Step 5. Track Your Team and Earn Points:</h4> 
                <p>Follow the real match to track the performance of your selected players in your fantasy team.</p>
                <p>Earn points based on the actual performance of your players during the match.</p>
                <p>Check your ranking in the contest at the end of the match and claim any prizes you may have won based on your team’s performance.</p>
              </li>   
            </ul>

        <div className="store d-flex">
              <a href={iosLink} target="_blank" className="me-3">
                {" "}
                <img src="./images/app_store.png" alt="" />
              </a>
              <a href={androidLink} target="_blank">
                {" "}
                <img src="./images/play_store.png" alt="" />
              </a>
            </div> 

          </div>
        </Col>

      </Row>
    </Container>
  </section>
  )
}

export default Download