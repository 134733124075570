import { Row, Container, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <footer>
        <div className="footer">
          <Container>
            <Row>
              <Col md={3}>
                <div className="footer_logo">
                  <a href="#">
                    <img src="../images/logo2.svg" />
                  </a>
                </div>
              </Col>
              <Col md={9}>
                <div className="text-md-end copyright">
                  <span>Copyright by digikhiladi multimedia pvt ltd</span>
                <div className="certificate">
                    <a href="/founding-member" target="_blank">
                      <strong>MEMBER</strong>
                      <img src="../images/fifa.png" alt="" />
                    </a>
 
                    </div>

                </div>



              </Col>
            </Row>
          </Container>
        </div>

        <div className="social-link-footer desclaimer text-center d-flex align-items-center justify-content-center py-3">
          <Link to="/privacy-policy" target="_blank" className="text-white me-4">
            Privacy Policy
          </Link>
          <Link to="/terms-conditions" target="_blank" className="text-white me-4">
            Terms & Conditions
          </Link>
          <Link to="/about-us" target="_blank" className="text-white me-4">
            About Us
          </Link>
          <Link to="/refund-and-cancellation" target="_blank" className="text-white me-4">
            Refund and Cancellation
          </Link>
          <Link to="/contact-us" target="_blank" className="text-white me-4">
            Contact Us
          </Link>
        </div>
      </footer>
    </>
  );
}

export default Footer;
