import apiPath from '../../utils/apiPath'
import helper from '../../utils/helper'
import axios from 'axios'
import _ from 'lodash'
import Session from '../../utils/session'
import {
  GET_SOCCER_MATCHES_LIST,
  GET_SOCCER_BANNERS,
  GET_LIVE_AND_UPCOMING_MATCHES_SOCCER,
  MATCH_DETAIL_SOCCER,
  GET_CONTEST_LIST_BYMATCH_JSON_SOCCER,
  JOINED_CONTEST_LIST_SOCCER,
  PLAYER_TEAM_LIST_SOCCER,
  CONTEST_DETAILS_SOCCER,
  TEAM_SCORE_SOCCER,
  CHECK_WALLET_BALANCE_SOCCER,
  CONTEST_CONFIRMATION_SOCCER,
  SELECTED_TEAM_SOCCER,
  ALL_PLAYER_LIST_SOCCER,
  STATE_DATA,
  PLAYER_ROLE,
  TEAM_PREVIEW_SOCCER,
  CONTEST_ENTRY_FEE,
  CONTEST_ENTRY_FEE_SOCCER,
  CONTEST_PRIZE_BREAKUP_SOCCER,
  CONTEST_SHARE_CODE_SOCCER,
  EDIT_SOCCER_TEAM,
  JOINED_MATCHES_LIST_SOCCER,
  JOINED_CONTEST_LIST,
  LIVE_SCORE_SOCCER,
  SERIES_PLAYER_LIST_SOCCER,
  TEAM_TYPE,
  TABS_ALL_CONTEST_SOCCER
} from '../constants/cricket'
import {
  CONTEST_CONFIRMATION,
  LOADER,
  LOW_BALANCE,
  MATCH_LIST_LOADER,
  STATE_DATA__DAILY
} from '../constants/Common'
import qs from 'qs'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  get_player_details,
  get_player_state_for_single_match
} from './FantasyActions'
import { ALL_CONTEST_TABS } from '../constants/Fantasy'
// const qs = require('qs');

export const get_soccer_matches_list = params => async dispatch => {
  try {
    dispatch({ type: LOADER, payload: true })
    // console.log('params', params);

    var path = params
      ? `${apiPath.get_active_matches_list}?series_id=${params} `
      : apiPath.get_active_matches_list

    // console.log('path==>>>>>', path);

    const res = await helper.api(path, 'get', {})
    if (res?.success) {
      dispatch({
        type: GET_SOCCER_MATCHES_LIST,
        payload: res
      })
      dispatch({
        type: LOADER,
        payload: false
      })
      dispatch({ type: MATCH_LIST_LOADER, payload: false })
      // toast.success(res?.msg);
      return res
    } else {
      dispatch({
        type: LOADER,
        payload: false
      })
      dispatch({ type: MATCH_LIST_LOADER, payload: false })
      // toast.error(res?.msg);
      return res
    }

  } catch (error) {
    console.log('error in soccer matches list api::', error)
  }
}

export const get_banners = data => async dispatch => {
  try {
    dispatch({ type: LOADER, payload: true })
    let payload = {}
    // payload = JSON.stringify(payload)

    payload = qs.stringify(payload)

    const res = await helper.api(
      apiPath.get_banners + '?page=1&itemsPerPage=10',
      'get',
      payload
    )
    if (res?.success) {
      dispatch({
        type: GET_SOCCER_BANNERS,
        payload: res
      })
      dispatch({
        type: LOADER,
        payload: false
      })
      return res
    } else {
      dispatch({
        type: LOADER,
        payload: false
      })
      return res
    }
  } catch (error) {
    //console.log('error in Get banners api::', error)
  }
}

export const get_live_and_upcoming_matches_soccer = data => async dispatch => {
  try {
    dispatch({ type: LOADER, payload: true })
    var payload = qs.stringify(data)

    const res = await helper.api(
      apiPath.get_live_and_upcoming_matches,
      'get',
      payload
    )
    if (res?.success) {
      dispatch({
        type: GET_LIVE_AND_UPCOMING_MATCHES_SOCCER,
        payload: res
      })
      dispatch({
        type: LOADER,
        payload: false
      })
      return res
    } else {
      dispatch({
        type: LOADER,
        payload: false
      })
      return res
    }
  } catch (error) {
    //console.log('error in live and upcoming matches api::', error)
  }
}

export const save_reminders_soccer = data => async dispatch => {
  try {
    dispatch({ type: LOADER, payload: true })

    let payload = qs.stringify(data)
    const res = await helper.api(apiPath.matchSaveRemindrs, 'post', payload)
    //console.log(res, "save reminder what get====>>>>>>>>>>", res)

    if (res?.success) {
      dispatch({
        type: LOADER,
        payload: false
      })

      return res
    } else {
      dispatch({
        type: LOADER,
        payload: false
      })
      // toast.error(res?.msg);
      return false
    }
  } catch (error) {
    //console.log('error in upcoming matches api::', error)
  }
}

export const get_match_detail_soccer = data => async (dispatch, getState) => {
  try {
    dispatch({ type: LOADER, payload: true })

    //console.log("data", data)
    let dict = { match_id: Number(data?.match_id) }

    const res = await helper.api(apiPath.getMatchDetail, 'post', dict)
    if (res?.success) {
      dispatch({
        type: MATCH_DETAIL_SOCCER,
        payload: res?.results
      })
      dispatch({
        type: LOADER,
        payload: false
      })
      return res
    } else {
      dispatch({
        type: LOADER,
        payload: false
      })
      // toast.error(res?.msg);
      return res
    }
  } catch (error) {
    //console.log('error in match detail api::', error)
  }
}

export const get_contest_list_byMatch_json__soccer =
  data => async (dispatch, getState) => {
    try {
      dispatch({ type: LOADER, payload: true })
      const user = Session.getSession('user')

      let dict = {
        match_id: Number(data?.match_id),
        series_id: Number(data?.series_id),
        user_id: user?.id,
        filter_data: data?.filter_data
      }

      const res = await helper.api(
        apiPath.getContestListByMatchJson,
        'post',
        dict
      )

      if (res?.success) {
        var temp = []
        res?.results?.length > 0 &&
          res?.results?.map((item, i) => {
            item?.contestData?.map((data, index) => {
              temp.push(data)
            })
          })
        res.results = temp

        dispatch({
          type: GET_CONTEST_LIST_BYMATCH_JSON_SOCCER,
          payload: res
        })
        dispatch({
          type: LOADER,
          payload: false
        })
        return res
      } else {
        dispatch({
          type: LOADER,
          payload: false
        })
        // toast.error(res?.msg);
        return res
      }
    } catch (error) {
      //console.log('error in match detail api::', error)
    }
  }

export const get_joined_contest_list_soccer = data => async dispatch => {
  const user = Session.getSession('user')

  try {
    dispatch({ type: LOADER, payload: true })

    let dict = {
      match_id: data?.match_id,
      series_id: data?.series_id,
      user_id: user?.id
    }
    let payload = qs.stringify(dict)

    const res = await helper.api(apiPath.joinedContestList, 'post', payload)

    if (res?.success) {
      dispatch({
        type: JOINED_CONTEST_LIST_SOCCER,
        payload: res
      })
      dispatch({
        type: LOADER,
        payload: false
      })
      return res
    } else {
      dispatch({
        type: LOADER,
        payload: false
      })
      // toast.error(res?.msg);
      return res
    }
  } catch (error) {
    //console.log('error in upcoming matches api::', error)
  }
}

export const get_player_team_list_soccer =
  (data, my_team_ids) => async dispatch => {
    // console.log('payload data', data)

    try {
      dispatch({ type: LOADER, payload: true })
      // console.log(data);

      let dict = {
        match_id: data?.match_id,
        series_id: data?.series_id,
        team_id: my_team_ids || ''
      }
      let payload = qs.stringify(dict)

      const res = await helper.api(apiPath.playerTeamList, 'post', payload)
      if (res?.success) {
        dispatch({
          type: PLAYER_TEAM_LIST_SOCCER,
          payload: res?.results
        })
        dispatch({
          type: LOADER,
          payload: false
        })
        return res
      } else {
        dispatch({
          type: LOADER,
          payload: false
        })
        // toast.error(res?.msg);
        return res
      }
    } catch (error) {
      //console.log('error in upcoming matches api::', error)
    }
  }

export const get_contest_details_soccer = data => async dispatch => {
  const user = Session.getSession('user')

  try {
    dispatch({ type: LOADER, payload: true })
    let dict = {
      match_id: Number(data?.match_id),
      series_id: Number(data?.series_id),
      contest_id: data?.contest_id,
      user_id: user?.id
    }

    const res = await helper.api(apiPath.getContestDetails, 'post', dict)
    if (res?.success) {
      dispatch({
        type: CONTEST_DETAILS_SOCCER,
        payload: res?.results
      })
      dispatch({
        type: LOADER,
        payload: false
      })
      return res
    } else {
      dispatch({
        type: LOADER,
        payload: false
      })
      // toast.error(res?.msg);
      return res
    }
  } catch (error) {
    //console.log('error in upcoming matches api::', error)
  }
}

export const get_team_score_soccer = data => async dispatch => {
  try {
    dispatch({ type: LOADER, payload: true })

    // var axios = require('axios');
    // var data = {
    //     "match_id": 57072,
    //     "series_id": 125912
    // }

    let dict = {
      match_id: Number(data?.match_id),
      series_id: Number(data?.series_id)
    }
    // let payload = qs.stringify(data);

    const res = await helper.api(apiPath.teamScore, 'post', dict)
    if (res?.success) {
      dispatch({
        type: TEAM_SCORE_SOCCER,
        payload: res?.results
      })
      dispatch({
        type: LOADER,
        payload: false
      })
      return res
    } else {
      dispatch({
        type: LOADER,
        payload: false
      })
      // toast.error(res?.msg);
      return res
    }
  } catch (error) {
    //console.log('error in upcoming matches api::', error)
  }
}

export const join_contest_check_wallet_soccer = data => async dispatch => {
  try {
    dispatch({ type: LOADER, payload: true })

    let dict = {
      match_id: data?.match_id,
      series_id: data?.series_id,
      contest_id: data?.contest_id,
      entry_fees: data?.entryFee
    }

    let payload = qs.stringify(dict)

    const res = await helper.api(
      apiPath.joinContestCheckWallet,
      'post',
      payload
    )
    if (res?.success) {
      dispatch({
        type: CHECK_WALLET_BALANCE_SOCCER,
        payload: res?.results
      })
      dispatch({
        type: LOADER,
        payload: false
      })
      return res
    } else {
      dispatch({
        type: LOADER,
        payload: false
      })
      // toast.error(res?.msg);
      return res
    }
  } catch (error) {
    //console.log('error in upcoming matches api::', error)
  }
}

//get all player list
export const get_all_player_list_soccer = (data, type) => async dispatch => {
  // console.log('payload data', data)

  try {
    dispatch({ type: LOADER, payload: true })
    dispatch({ type: ALL_PLAYER_LIST_SOCCER, payload: [] })
    if (type === 'Create Team') {
      dispatch({ type: STATE_DATA__DAILY, payload: {} })
    }

    let dict = {
      match_id: data?.match_id,
      visitor_team_id: data?.visitor_team_id,
      local_team_id: data?.local_team_id,
      series_id: data?.series_id
    }
    let payload = qs.stringify(dict)

    const res = await helper.api(apiPath.allPlayerList, 'post', payload)
    if (res?.success) {
      dispatch({ type: TEAM_TYPE, payload: type })
      dispatch({
        type: ALL_PLAYER_LIST_SOCCER,
        payload: res
      })
      dispatch({
        type: LOADER,
        payload: false
      })
      return res
    } else {
      dispatch({
        type: ALL_PLAYER_LIST_SOCCER,
        payload: []
      })
      dispatch({
        type: LOADER,
        payload: false
      })
      return res
    }
  } catch (error) {
    //console.log('error in upcoming matches api::', error)
  }
}

export const create_Update_Team = data => async dispatch => {
  try {
    //console.log("update team action calledd==>>>>", data)
    dispatch({ type: LOADER, payload: true })
    const res = await helper.api(apiPath.createTeam, 'post', data)
    //console.log('result of api==>>>>>>>', res)

    if (res?.success) {
      dispatch({
        type: LOADER,
        payload: false
      })
      toast.success(res?.msg)
      return res?.results
    } else {
      dispatch({
        type: LOADER,
        payload: false
      })
      toast.error(res?.msg)
      return false
    }
  } catch (error) {
    //console.log('error in update bank api::', error)
  }
}

export const get_contest_entry_fee_soccer = data => async dispatch => {
  try {
    dispatch({ type: LOADER, payload: true })

    let dict = {
      contest_size: data?.contest_size,
      winning_amount: data?.winning_amount
    }
    let payload = qs.stringify(dict)

    const res = await helper.api(apiPath.getEntryFee, 'post', payload)
    //console.log('result====>>>>>??>>>>>>>>>>', res)
    if (res?.success) {
      dispatch({
        type: CONTEST_ENTRY_FEE_SOCCER,
        payload: res
      })
      dispatch({
        type: LOADER,
        payload: false
      })
      return res
    } else {
      dispatch({
        type: LOADER,
        payload: false
      })
      // toast.error(res?.msg);
      return res
    }
  } catch (error) {
    //console.log('error in upcoming matches api::', error)
  }
}

export const contest_prize_breakup_soccer = contest_size => async dispatch => {
  try {
    dispatch({ type: LOADER, payload: true })

    let dict = { contest_size: parseInt(contest_size) }
    // let payload = qs.stringify({ contest_size: 100 });
    // let payload = { contest_size: 100 }

    const res = await helper.api(apiPath.contestPrizeBreakup, 'post', dict)
    //console.log('result====>>>>>??>>>>>>>>>>', res)
    if (res?.success) {
      dispatch({
        type: CONTEST_PRIZE_BREAKUP_SOCCER,
        payload: res?.results
      })
      dispatch({
        type: LOADER,
        payload: false
      })
      return res
    } else {
      dispatch({
        type: LOADER,
        payload: false
      })
      // toast.error(res?.msg);
      return res
    }
  } catch (error) {
    //console.log('error in upcoming matches api::', error)
  }
}

export const user_create_contest_soccer = data => async dispatch => {
  try {
    dispatch({ type: LOADER, payload: true })

    // let dict = { contest_size: parseInt(contest_size) }
    let payload = qs.stringify(data)
    // let payload = { contest_size: 100 }

    const res = await helper.api(
      apiPath.userCreatePrivateContest,
      'post',
      payload
    )

    if (res?.success) {
      dispatch({
        type: CONTEST_SHARE_CODE_SOCCER,
        payload: res
      })
      Session.setSession('contestInviteCode', res?.results?.invite_code)
      dispatch({
        type: LOADER,
        payload: false
      })

      return res
    } else {
      dispatch({
        type: LOADER,
        payload: false
      })
      toast.error(res?.msg)
      return res
    }
  } catch (error) {
    //console.log('error in upcoming matches api::', error)
  }
}

export const get_joined_matches_list = data => async dispatch => {
  try {
    dispatch({ type: LOADER, payload: true })
    let payload = qs.stringify({ status: data })
    const res = await helper.api(apiPath.joinedMatchesList, 'post', payload)
    if (res?.success) {
      dispatch({
        type: JOINED_MATCHES_LIST_SOCCER,
        payload: res?.results
      })
      dispatch({
        type: LOADER,
        payload: false
      })
      return res
    } else {
      dispatch({
        type: LOADER,
        payload: false
      })
      // toast.error(res?.msg);
      return res
    }
  } catch (error) {
    //console.log('error in upcoming matches api::', error)
  }
}

export const get_live_score_soccer = data => async dispatch => {
  try {
    dispatch({ type: LOADER, payload: true })

    let dict = {
      match_id: parseInt(data?.match_id),
      series_id: parseInt(data?.series_id)
    }
    // let payload = qs.stringify(dict);

    const res = await helper.api(apiPath.liveScore, 'post', dict)
    if (res?.success) {
      dispatch({
        type: LIVE_SCORE_SOCCER,
        payload: res
      })
      dispatch({
        type: LOADER,
        payload: false
      })
      return res
    } else {
      dispatch({
        type: LOADER,
        payload: false
      })
      // toast.error(res?.msg);
      return res
    }
  } catch (error) {
    //console.log('error in upcoming matches api::', error)
  }
}

export const get_series_player_list_soccer =
  (data, is_player_state) => async dispatch => {
    try {
      dispatch({ type: LOADER, payload: true })

      let dict = { match_id: data?.match_id, series_id: data?.series_id }
      if (is_player_state) {
        dict.is_player_state = 1
      }
      // let payload = qs.stringify(dict);

      const res = await helper.api(apiPath.seriesPlayerList, 'post', dict)
      if (res?.success) {
        dispatch({
          type: SERIES_PLAYER_LIST_SOCCER,
          payload: res
        })
        dispatch({
          type: LOADER,
          payload: false
        })
        return res
      } else {
        dispatch({
          type: LOADER,
          payload: false
        })
        // toast.error(res?.msg);
        return res
      }
    } catch (error) {
      //console.log('error in upcoming matches api::', error)
    }
  }

// **************************************************common function that use in all pages **********************************************//

export const soccer_contest_details = (item, navigate) => async dispatch => {
  navigate('/soccer-contest-details', { state: item, replace: false })
}


const setTeamState = (dispatch, payload) => {
  dispatch({
    type: STATE_DATA,
    payload: payload
  })
}
export const join_contest_soccer =
  (innerItem, navigate) => async (dispatch, getState) => {
    var stateData = { isDirectJoin: true, type: 'join' }

    const { matchDetails } = getState()?.soccer
    matchDetails.contest_id = innerItem?._id
    await dispatch(get_contest_details_soccer(matchDetails))

    const contestDetails = innerItem

    const currentTimeStamp = helper.start_time
    if (matchDetails?.timestamp_start > currentTimeStamp) {
      const res = await dispatch(get_player_team_list_soccer(matchDetails))
      const isValidToJoin =
        (res?.results?.length > 0 &&
          contestDetails?.my_teams_ids?.length <
            contestDetails?.max_team_join_count) ||
        contestDetails?.my_team_ids?.length <
          contestDetails?.max_team_join_count
      if (isValidToJoin) {
        var payload = await res?.results?.map(data => {
          if (contestDetails?.my_teams_ids?.includes(data?.team_id)) {
            data.isJoined = true
          }
          return data
        })
        dispatch({
          type: PLAYER_TEAM_LIST_SOCCER,
          payload: payload
        })

        const isCheckTeamCreateOrSelect =
          res?.results?.length === contestDetails?.my_teams_ids?.length ||
          res?.results?.length === contestDetails?.my_team_ids?.length

        if (isCheckTeamCreateOrSelect) {
          var data = {
            match_id: matchDetails?.match_id,
            visitor_team_id: matchDetails?.visitorteam_id,
            local_team_id: matchDetails?.localteam_id,
            series_id: matchDetails?.series_id
          }
          dispatch(get_all_player_list_soccer(data, 'Create Team'))
          setTeamState(dispatch, stateData)
          navigate('/soccer-create-team')
        } else {
          navigate('/soccer-select-team')
        }
      } else {
        dispatch(get_all_player_list_soccer(data, 'Create Team'))
        setTeamState(dispatch, stateData)
        navigate('/soccer-create-team')
      }
    } else {
      // handleCloseWarningPopup()
    }
  }

export const check_soccer_wallet =
  (selectedTeam, navigate, state) => async (dispatch, getState) => {
    const { matchDetails, contestDetails, stateData } = getState()?.soccer
    if (stateData?.type !== 'contest_create') {
      matchDetails.contest_id = contestDetails?._id
      matchDetails.entryFee = contestDetails?.entryFee
    }

    const res = await dispatch(join_contest_check_wallet_soccer(matchDetails))

    const isEligible =
      res?.results?.winning_balance + res?.results?.cash_balance >=
      res?.results?.entry_fee

    if (res?.success && isEligible) {
      if (stateData?.type === 'contest_create') {
        const data = {
          ...stateData,
          ...state?.contest_data,
          match_id: state?.match_id,
          series_id: state?.series_id,
          ...selectedTeam
        }
        dispatch({ type: STATE_DATA, payload: data })
      }
      dispatch({ type: CONTEST_CONFIRMATION_SOCCER, payload: true })
      dispatch({ type: SELECTED_TEAM_SOCCER, payload: selectedTeam })
      // navigate(-2, { state, replace: true })
    } else {
      contestDetails.entryFee = res?.results?.entry_fee
      contestDetails.cash_balance = res?.results?.cash_balance
      contestDetails.winning_balance = res?.results?.winning_balance

      dispatch({
        type: CONTEST_DETAILS_SOCCER,
        payload: contestDetails
      })
      dispatch({
        type: LOW_BALANCE,
        payload: true
      })
      // navigate(-2, { state, replace: true })
    }
  }

export const join_contest_confirm =
  (navigate, location) => async (dispatch, getState) => {
    const { matchDetails, contestDetails, selectedTeam, stateData } =
      getState()?.soccer

    try {
      // dispatch({ type: LOADER, payload: true })
      var state = location?.state

      if (stateData?.isDirectJoin && stateData?.type === 'contest_create') {
        let data = {
          series_id: stateData?.series_id,
          match_id: stateData?.match_id,
          team_id: stateData?.team_id,
          contest_size: stateData?.contest_size,
          contest_name: stateData?.contest_name,
          max_team_join_count: stateData?.max_team,
          looserPunishment: stateData?.loser
        }

        const res = await dispatch(user_create_contest_soccer(data))

        if (res?.success) {
          dispatch({ type: ALL_CONTEST_TABS, payload: 'my_contest' })
          dispatch({ type: LOADER, payload: false })
          dispatch({ type: CONTEST_CONFIRMATION_SOCCER, payload: false })
          toast.success(res?.msg)
          dispatch({ type: STATE_DATA, payload: {} })
          dispatch({ type: SELECTED_TEAM_SOCCER, payload: {} })
          navigate('/share-contest', { state: 'item', replace: true })
        } else if (
          res?.success === false &&
          res?.msg ===
            'You do not have sufficient balance to join this contest.'
        ) {
          toast?.error(res?.msg)
        }
        return
      }

      let apiData = {
        contest_id: contestDetails?._id,
        team_id: selectedTeam?.team_id,
        series_id: matchDetails?.series_id,
        match_id: matchDetails?.match_id
      }

      let payload = qs.stringify(apiData)
      const res = await helper.api(apiPath.joinContest, 'post', payload)

      if (res?.success) {
        Session.setSession('match_details', matchDetails)
        dispatch({ type: TABS_ALL_CONTEST_SOCCER, payload: 'my_contest' })
        toast.success(res?.msg)
        dispatch({ type: LOADER, payload: false })
        dispatch({ type: CONTEST_CONFIRMATION_SOCCER, payload: false })
        dispatch({ type: SELECTED_TEAM_SOCCER, payload: {} })
        navigate('/all-contest-soccer', { state: 'item', replace: true })
        return res
      } else {
        toast.error(res?.msg)
        dispatch({
          type: CONTEST_CONFIRMATION_SOCCER,
          payload: false
        })
        navigate('/all-contest-soccer', { state: 'item', replace: true })
        dispatch({
          type: LOADER,
          payload: false
        })
        // toast.error(res?.msg);
        return res
      }
    } catch (error) {
      //console.log('error in upcoming matches api::', error)
    }
  }

const team_preview_new_api =
  (matchDetails, teamId, stateData) => async (dispatch, getState) => {
    try {
      let apiData = {
        series_id: matchDetails?.series_id,
        match_id: matchDetails?.match_id,
        team_id: teamId,
        user_id: stateData?.user_id || ''
      }

      let payload = qs.stringify(apiData)
      const res = await helper.api(apiPath.teamPreviewSoccer, 'post', payload)
      
      if (res?.success) {
        var data = res?.results

        data.visitorCount = data?.seriesPlayer?.filter(
          item => matchDetails?.visitorteam_id === item?.team_id
        ).length
        data.localCount = data?.seriesPlayer?.filter(
          item => matchDetails?.localteam_id === item?.team_id
        ).length
        return res
      } else {
        toast.error(res?.msg)
        return res
      }
    } catch (error) {}
  }

export const handel_preview_team =
  (navigate, teamId) => async (dispatch, getState) => {
    try {
      dispatch({ type: LOADER, payload: true })
      const { matchDetails, stateData } = getState()?.soccer
      const { playerList } = getState()?.soccerTeam

      if (teamId) {
        const res = await dispatch(
          team_preview_new_api(matchDetails, teamId, stateData)
        )
        if (res?.success) {
          dispatch({ type: TEAM_PREVIEW_SOCCER, payload: res?.results })
          navigate('/soccer-team-preview')
          dispatch({ type: LOADER, payload: false })
          return
        } else {
          dispatch({ type: LOADER, payload: false })
          return
        }
      }

      var previewData = {}

      previewData.seriesPlayer = playerList?.filter(
        player => player?.isSelected
      )
      previewData.visitorCount = playerList?.filter(
        item =>
          item.isSelected === true &&
          matchDetails?.visitorteam_id === item?.team_id
      ).length
      previewData.localCount = playerList?.filter(
        item =>
          item.isSelected === true &&
          matchDetails?.localteam_id === item?.team_id
      ).length
      previewData.captain_player_id =
        playerList?.filter(
          item => item.isSelected === true && item?.isCaptain === true
        )?.[0]?.player_id || ''
      previewData.vice_captain_player_id =
        playerList?.filter(
          item => item.isSelected === true && item?.isViceCaptain === true
        )?.[0]?.player_id || ''

      dispatch({ type: TEAM_PREVIEW_SOCCER, payload: previewData })
      navigate('/soccer-team-preview')

      dispatch({ type: LOADER, payload: false })
    } catch (error) {
      dispatch({ type: LOADER, payload: false })
      console.log('err in handel create team function action==>>>>', error)
    }
  }

export const handel_update_team =
  (navigate, item, replace, direct_booster) => async (dispatch, getState) => {
    try {
      const { matchDetails, stateData } = getState()?.soccer

      if (!item?._id) {
        navigate(-1)
        return
      }

      item.seriesPlayer = await item?.seriesPlayer?.map(
        player => player?.player_id
      )

      const currentTimeStamp = Session.getSession('server_time')

      if (matchDetails.timestamp_start > currentTimeStamp) {
        var data = {
          match_id: matchDetails?.match_id,
          visitor_team_id: matchDetails?.visitorteam_id,
          local_team_id: matchDetails?.localteam_id,
          series_id: matchDetails?.series_id
        }
        var stateEditData = {
          isDirectJoin: false,
          type: item?._id ? 'edit' : 'create',
          team_number: item?.team_number,
          team_id:
            (!item?.isClone &&
              (item?.team_id ? item?.team_id : stateData?.team_id)) ||
            ''
        }
        dispatch({ type: STATE_DATA, payload: stateEditData })
        if (item?.isClone) {
          dispatch({ type: STATE_DATA__DAILY, payload: {} })
        } else {
          const boosterData = { selectedBooster: item?.boosterDetails }
          dispatch({ type: STATE_DATA__DAILY, payload: boosterData })
        }

        const result = await dispatch(
          get_all_player_list_soccer(
            data,
            item?.isClone ? 'Create Team' : 'Edit Team'
          )
        )
        if (result?.success) {
          dispatch({ type: EDIT_SOCCER_TEAM, payload: item })
          if (direct_booster === 'direct_booster') {
            return true
          }
          setTimeout(() => {
            navigate('/soccer-create-team', { state: {}, replace: replace })
          }, 1000)
        } else {
          return 'handleCloseWarningPopup'
        }
      } else {
        return 'handleCloseWarningPopup'
      }
    } catch (error) {
      console.log('err in update team action==>>>>', error)
    }
  }

export const handel_createTeam =
  (navigate, state) => async (dispatch, getState) => {
    try {
      const { matchDetails, contestDetails, stateData } = getState()?.soccer
      const { playerList } = getState()?.soccerTeam
      const { dailyStateData } = getState()?.static

      var playerData = {
        contest_id: contestDetails?._id || '',
        team_id: '',
        match_id: matchDetails?.match_id,
        series_id: matchDetails?.series_id,
        boosterCode:
          dailyStateData?.selectedBooster?.length > 0
            ? dailyStateData?.selectedBooster.map(item => {
                return { id: item?.boosterUniqueId, code: item?.boosterCode }
              })
            : []
      }

      if (stateData?.team_id) {
        playerData.team_id = stateData?.team_id
      }

      playerData.player_id = playerList
        .filter(player => player.isSelected === true)
        ?.map(player => player.player_id)
      playerList.map(player => {
        if (player.isCaptain) {
          playerData.captain = player.player_id
        }
        if (player.isViceCaptain) {
          playerData.vice_captain = player.player_id
        }
        if (
          dailyStateData?.selectedBooster?.some(ele => ele.boosterCode === 1) &&
          player?.isBoosted3x
        ) {
          playerData.boostedPlayerId = player.player_id
        }
      })

      var result = await dispatch(create_Update_Team(playerData))
      if (result) {
        dispatch({ type: STATE_DATA__DAILY, payload: {} })
        dispatch({ type: ALL_PLAYER_LIST_SOCCER, payload: [] })
        if (stateData?.isDirectJoin) {
          var selectedTeam = { team_id: result?.team_id }
          dispatch(check_soccer_wallet(selectedTeam, navigate, state))
          navigate('/soccer-select-team', {
            state: { navigate: true },
            replace: true
          })
        } else {
          navigate('/all-contest-soccer', {
            state: { navigate: true },
            replace: true
          })
        }
      }
    } catch (error) {
      console.log('err in handel create team function action==>>>>', error)
    }
  }

export const handle_player_details =
  (navigate, item) => async (dispatch, getState) => {
    const { matchDetails, contestDetails, stateData } = getState()?.soccer
    var res
    matchDetails['player_id'] = item?.player_id

    if (
      matchDetails?.match_status === 'Completed' ||
      matchDetails?.match_status === 'Live' ||
      matchDetails?.match_status === 'In Progress'
    ) {
      res = await dispatch(get_player_state_for_single_match(matchDetails))
      if (res?.results?.length > 0) {
        // item?.isButton && res.results.isButton = item?.isButton
        res.match_status = matchDetails?.match_status
        res['boostersDetails'] = item?.boosterDetails
        res['debuffboosterDetails'] = item?.debuffboosterDetails
        navigate('/player-info-soccer', { state: res, replace: false })
      } else {
        toast.warn(res?.msg)
      }
    } else {
      res = await dispatch(get_player_details(matchDetails))
      if (!_.isEmpty(res?.results)) {
        if (item?.isButton) {
          res.results.isButton = item?.isButton
        }
        res['boostersDetails'] = item?.boosterDetails
        res['debuffboosterDetails'] = item?.debuffboosterDetails
        navigate('/player-info-soccer', { state: res, replace: false })
      } else {
        toast.warn(res?.msg)
      }
    }
  }
