import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import Session from '../utils/session'
import BackArrow from './BackArrow'
import Profile from './Profile'
import { useState } from 'react'
import { useEffect } from 'react'

const CommonHeader = () => {
    const location = useLocation()
    const user = Session?.getSession('user')


    const { allContestTabs } = useSelector(state => state?.match)
    const { teamType, playerTeamCount, } = useSelector(state => state?.soccerTeam)
    const { stateData } = useSelector(state => state?.soccer)
    const playerTeamCountCricket = useSelector(state => state?.match?.all_player_list?.playerTeamCount)

    const [typeTemOpration, setTypeTemOpration] = useState(Session?.getSession('teamType') || '')

    // console.log('socer==>>>>>>>>>',teamType, playerTeamCount, teamNumber );
    // console.log('team number==>>>>>>>>>>>>>', location?.state?.item?.team_number);

    useEffect(() => {

        if (location?.pathname === '/update-team') {
            if (location?.state?.isClone) {
                var teamNameType = `Create Team ${location?.state?.playerTeamList?.length + 1}`
                Session.setSession('teamType', teamNameType)
                setTypeTemOpration(teamNameType)
            } else {
                var teamNameType = `Edit Team ${location?.state?.item?.team_number}`
                Session.setSession('teamType', teamNameType)
                setTypeTemOpration(teamNameType)
            }
        }
        if (location?.pathname === '/create-team' && playerTeamCountCricket || playerTeamCountCricket == '0') {
            var teamNameType = `Create Team ${playerTeamCountCricket + 1}`
            Session.setSession('teamType', teamNameType)
            setTypeTemOpration(teamNameType)

        }

        if (location?.pathname === '/save-team') {
            if (location?.state?.item?.isDirectBooster) {
                var teamNameType = `Edit Team ${location?.state?.item?.team_number ? location?.state?.item?.team_number : location?.state?.mathData?.team_number}`
                Session.setSession('teamType', teamNameType)
                setTypeTemOpration(teamNameType)
            }
        }
    }, [location?.pathname, playerTeamCountCricket])




    var dict = {
        '/MyOffer': 'My Offers',
        // '/upcomingmatch': 'Cricket', sonar bug
        '/FantasyMatch': 'Fantasy',
        '/AllContest': `${allContestTabs === 'allContest' ? 'Contests' : allContestTabs === 'my_contest' ? "My Contest" : "My Teams"}`,
        // '/AllContest': inner[allContestTabs],
        '/contest-details': 'Cricket',
        '/update-team': `${typeTemOpration}`,
        '/save-team': `${typeTemOpration}`,
        // '/upcomingmatch': user?.username, sonar bug
        '/mycontest': 'Cricket',
        '/player-info': 'Player Info',
        '/addcash': 'Add Cash',
        '/transaction': 'My Transactions',
        '/rewards': 'Rewards',
        '/howtoplay': 'How To Play',
        '/terms-condition': 'Terms & Conditions',
        '/about-us': 'About Us',
        '/contact-us': 'Contact Us',
        '/refund-policy': 'Refund Policy',
        '/privacypolicy': 'Privacy Policy',
        '/legality': 'Legality',
        '/help': 'Help',
        '/fantasypoint': 'Fantasy Points System',
        '/faqs': 'FAQs',
        '/my-profile': 'My Profile',
        '/profile': 'Welcome!',
        '/notifications': 'Notifications',
        '/verifykyc': 'Verify KYC',
        '/prize-breakup': 'Create Contest',
        '/createcontest': 'Create Contest',
        '/create-team': `${typeTemOpration}`,
        '/withdrawamount': 'Withdraw Amount',
        '/select-team': 'Select Team',
        '/upcomingmatch': 'Cricket',
        '/all-contest-soccer': 'Contests',
        '/soccer-contest-details': 'Leaderboard',
        '/soccer-select-team': 'Select Team',
        '/soccer-create-team': `${teamType}  ${teamType === 'Create Team' ? playerTeamCount + 1 : stateData?.team_number}`,
        '/soccer-save-team': `${teamType}  ${teamType === 'Create Team' ? playerTeamCount + 1 : stateData?.team_number}`,
        "/soccer-create-contest": "Create Contest",
        "/soccer-prize-breakup": "Create Contest",
        "/share-contest": " Invite Your Friend",
        "/player-info-soccer": 'Player Info',
        "/upcomingmatch-soccer": 'Football',
        "/mycontest-soccer": 'Football',
        "/deposit-payment": 'Add Money',
        "/add-boosters": 'Add Booster',
        "/season-history": 'Season History',
        "/daily-shop": 'Shop',
        "/user-boosters": 'Boosters',
        "/shop": 'Shop',
        // "/money-pool":"Money Pool",

        "/pool-detail": 'Money Pool',
        "/verify-pan": 'Verify PAN Card',
        "/verify-bank": 'Verify Bank Account',
        "/verify-aadhaar": 'Verify Aadhaar Card',
        "/influencer": 'Top Influencers',


    }

    return (
        <>
            {dict.hasOwnProperty(location?.pathname) ?
                <BackArrow name={dict[location?.pathname]} />
                :
                <Profile />
            }
        </>
    )
}

export default CommonHeader