
import Session from "../../utils/session";
import { DEPOSIT_PAYMENT_FAIL, DEPOSIT_PAYMENT_REQUEST, DEPOSIT_PAYMENT_SUCCESS } from "../constants/Other";
import { CREATE_TRANS_ID } from "../constants/Payment";


let initialState = {
    isPaymentDeposit: false,
    paymentData: {},
    paymentObj: {},
};



const PaymentReducer = function (state = initialState, action) {
    try {
        switch (action.type) {
            case DEPOSIT_PAYMENT_REQUEST:
                return {
                    ...state,
                    paymentData: {},
                    isPaymentDeposit: false
                };
            case DEPOSIT_PAYMENT_SUCCESS:
                return {
                    ...state,
                    paymentData: action?.payload,
                    isPaymentDeposit: action?.payload?.url ? true : false
                };
            case DEPOSIT_PAYMENT_FAIL:
                return {
                    ...state,
                    paymentData: {},
                    isPaymentDeposit: false
                };

            case CREATE_TRANS_ID:
                return {
                    ...state,
                    paymentObj: action?.payload?.results
                };

            default:
                return state;
        }
    } catch (error) {
        //console.log('err in payment reducers==>>>>', error)
    }
};

export default PaymentReducer;