import React from 'react'
import { useSelector } from 'react-redux';
import Slider from "react-slick";


const banner_slider = {
    dots: true,
    infinite: true,
    speed: 1000,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    cssEase: "linear"
};

const BannerSlide = ({ backgroundColor }) => {

    const { banners_list, banner_path } = useSelector(state => state.match)

    // console.log('banneerrrr==>>>>>>>>>>>>>>>',banners_list);

    const handelClickBanner = (data) => {
        //console.log('data', data);
        // if(data?.banner_type==='offer'){
        //   navigate('/MyOffer')
        // } else {  
        window.open(data?.link);
        // }
    }


    return (
        <div className={`${backgroundColor !== 'none' && 'bg-blue-dark'} inner_slider py-2 px-4 w-100`}>
            <Slider {...banner_slider}>
                {banners_list.map((item, index) => {
                    return (
                        // <div key={Math.floor(Math.random() * 100) + 1} className="position-relative cursor" onClick={() => handelClickBanner(item)}> sonar bug
                        <div key={index} className="position-relative cursor" onClick={() => handelClickBanner(item)}>
                            <div className="inner-sec">
                                <img src={banner_path + item.image} alt="" width={'100%'} height={'100%'} />
                            </div>
                        </div>
                    )
                })}
            </Slider>
        </div>
    )
}

export default BannerSlide