


export const DEPOSIT_PAYMENT_REQUEST = 'DEPOSIT_PAYMENT_REQUEST'
export const DEPOSIT_PAYMENT_SUCCESS = 'DEPOSIT_PAYMENT_SUCCESS'
export const DEPOSIT_PAYMENT_FAIL = 'DEPOSIT_PAYMENT_FAIL'

export const GET_STATIC_PAGE = 'GET_STATIC_PAGE'


export const GET_NOTIFICATION_PAGE = 'GET_NOTIFICATION_PAGE'
export const GET_NOTIFICATION_SCROLL_DATA = 'GET_NOTIFICATION_SCROLL_DATA'
export const CHECK_REFERRAL_CODE = 'CHECK_REFERRAL_CODE'
export const CHECK_SOCIAL_DATA = 'CHECK_SOCIAL_DATA'


export const SET_REMINDER= 'SET_REMINDER'
export const TEAM_PREVIEW= 'TEAM_PREVIEW'


export const MONEY_POOL_TAB = 'MONEY_POOL_TAB'

export const MONEY_POOL_TAB_TYPE = 'MONEY_POOL_TAB_TYPE'
export const SELECT_POOL_CATEGORY = 'SELECT_POOL_CATEGORY'

export const SHOW_MEDIA = 'SHOW_MEDIA'







