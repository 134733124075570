import { lazy } from 'react'
import Loadable from '../components/loader/Loadable'
import BaseLayout from '../layout/BaseLayout'
import WebAboutus from '../pages/other/WebAboutus'
import RefundCancellation from '../pages/other/RefundCancellation'
import ContactUs from '../pages/other/ContactUs'
import FoundingMember from '../pages/other/FoundingMember'

// import component
const Home = Loadable(lazy(() => import('../pages/home/Home')))

// static pages
const WebResponsiblePlay = Loadable(lazy(() => import('../pages/other/WebResponsiblePlay')))
const WebPrivacyPolicy = Loadable(lazy(() => import('../pages/other/WebPrivacyPolicy')))
const WebTernCondition = Loadable(lazy(() => import('../pages/other/WebTernCondition')))



// All common routes
const CommonRoutes = {
  path: '/',
  element: <BaseLayout />,
  children: [
    {
      path: '/',
      element: <Home />
    },
    {
      path: '/web-responsible-play',
      element: <WebResponsiblePlay />
    },
    {
      path: '/privacy-policy',
      element: <WebPrivacyPolicy />
    },
    {
      path: '/about-us',
      element: <WebAboutus />
    },
    {
      path: '/terms-conditions',
      element: <WebTernCondition />
    },
    {
      path: '/refund-and-cancellation',
      element: <RefundCancellation />
    },
    {
      path: '/contact-us',
      element: <ContactUs />
    },
    {
      path: '/founding-member',
      element: <FoundingMember />
    },
  ]
}

export default CommonRoutes
