import { lazy, useEffect } from "react";

// project imports
import Loadable from "../components/loader/Loadable";
import AuthLayout from "../layout/AuthLayout";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import Session from "../utils/session";
import TeamPreview from "../components/TeamPreview";
import TermsConditions from "../pages/other/TermsConditions";

import Claim from "../pages/home/claim/Claim";
import WebPayment from "../pages/userWallet/WebPayment";
import LeagueHistory from "../pages/session_leguage/history/LeagueHistory";
import UserBoosters from "../pages/userWallet/UserBoosters";
import { useDispatch } from "react-redux";
import { APPLY_REFERRAL_CODE_POPUP } from "../redux/constants/Common";

// main home==>>>>>>>
const MoneyPool = Loadable(lazy(() => import("../pages/moneyPool/MoneyPool")));
const PooDetail = Loadable(lazy(() => import("../pages/moneyPool/PooDetail")));

const Rummy = Loadable(lazy(() => import("../pages/games/Rummy")));

const Shop = Loadable(lazy(() => import("../pages/home/shop/Shop")));

// fantasy pages==>>>>>>
const Fantasy = Loadable(lazy(() => import("../pages/fantasy/Fantasy")));
const FantasyMatch = Loadable(lazy(() => import("../pages/fantasy/FantasyMatch")));
const AllContest = Loadable(lazy(() => import("../pages/fantasy/AllContest")));
const Winnings = Loadable(lazy(() => import("../pages/fantasy/Winnings")));
const PreviewTeam = Loadable(lazy(() => import("../pages/fantasy/PreviewTeam")));
const MyContest = Loadable(lazy(() => import("../pages/fantasy/MyContest")));

// myGames routes==>>>>
const UpcomingMatch = Loadable(lazy(() => import("../pages/myGames/UpcomingMatch")));
const Myteams = Loadable(lazy(() => import("../pages/myGames/updateTeam/Myteams")));
const Saveteam = Loadable(lazy(() => import("../pages/myGames/updateTeam/Saveteam")));
const CreateTeam = Loadable(lazy(() => import("../pages/myGames/createTeam/CreateTeam")));
const MyGames = Loadable(lazy(() => import("../pages/myGames/MyGames")));
const Createcontest = Loadable(lazy(() => import("../pages/myGames/Createcontest")));
const PlayerInfo2 = Loadable(lazy(() => import("../pages/myGames/PlayerInfo2")));

// my offer routes====>>>>>>
const MyOffer = Loadable(lazy(() => import("../pages/myOffers/MyOffer")));
const Rewards = Loadable(lazy(() => import("../pages/myOffers/Rewards")));

const ReferFriends = Loadable(lazy(() => import("../pages/referFriend/ReferFriends")));

// user wallet==>>>>
const Wallet = Loadable(lazy(() => import("../pages/userWallet/Wallet")));
const TopInfluencer = Loadable(lazy(() => import("../pages/userWallet/TopInfluencer")));
const AddCash = Loadable(lazy(() => import("../pages/userWallet/AddCash")));
const MyProfile = Loadable(lazy(() => import("../pages/userWallet/MyProfile")));

const Payment = Loadable(lazy(() => import("../pages/userWallet/Payment")));
const PlayerInfo = Loadable(lazy(() => import("../pages/userWallet/PlayerInfo")));
const Transaction = Loadable(lazy(() => import("../pages/userWallet/Transaction")));
const WithdrawAmount = Loadable(lazy(() => import("../pages/userWallet/WithdrawAmount")));
const PaymentSuccess = Loadable(lazy(() => import("../pages/userWallet/PaymentSuccess")));
const PaymentSuccessWeb = Loadable(lazy(() => import("../pages/userWallet/PaymentSuccessWeb")));
// kyc====>>>>>>
const Verifykyc = Loadable(lazy(() => import("../pages/kyc/Verifykyc")));
const Verifiybank = Loadable(lazy(() => import("../pages/kyc/Verifiybank")));
const Verifypan = Loadable(lazy(() => import("../pages/kyc/Verifypan")));
const VerifyAadhaar = Loadable(lazy(() => import("../pages/kyc/VerifyAadhaar")));

// other routess==>>>>>
const Congrats = Loadable(lazy(() => import("../pages/other/Congrats")));
const Help = Loadable(lazy(() => import("../pages/other/Help")));
const HowToPlay = Loadable(lazy(() => import("../pages/other/HowToPlay")));
const Missions = Loadable(lazy(() => import("../pages/other/Missions")));
const ResponsiblePlay = Loadable(lazy(() => import("../pages/other/WebResponsiblePlay")));
const Notifications = Loadable(lazy(() => import("../pages/other/Notifications")));
const PrivacyPolicy = Loadable(lazy(() => import("../pages/other/PrivacyPolicy")));
const Legality = Loadable(lazy(() => import("../pages/other/Legality")));
const ContestInvite = Loadable(lazy(() => import("../pages/other/ContestInvite")));
const FantasyPoint = Loadable(lazy(() => import("../pages/other/FantasyPoint")));
const Faq = Loadable(lazy(() => import("../pages/other/Faq")));
const AboutUs = Loadable(lazy(() => import("../pages/other/AboutUs")));
const ContactUsApp = Loadable(lazy(() => import("../pages/other/ContactUsApp")));
const RefundPolicy = Loadable(lazy(() => import("../pages/other/RefundPolicy")));
const ContestPrizeBreakup = Loadable(lazy(() => import("../pages/fantasy/ContestPrizeBreakup")));
const SelectTeam = Loadable(lazy(() => import("../pages/fantasy/SelectTeam")));
const ShareContest = Loadable(lazy(() => import("../pages/fantasy/ShareContest")));
const ContestDetails = Loadable(lazy(() => import("../pages/myGames/ContestDetails")));
const Chat = Loadable(lazy(() => import("../pages/chat/Chat")));

const Myfantasy = Loadable(lazy(() => import('../pages/session_leguage/Myfantasy')))
const BoosterListDailyContest = Loadable(lazy(() => import('../components/boosterShop/BoosterList')))


const Auth = Session.getSession("token") || false;

//= ====>>>>>>>not found page
const NotFound = ({ to }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const checkNavigation = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const referralCode = urlParams.get("referral");
    if (referralCode) {
      to = `/Fantasy?referral=${referralCode}`;
      setTimeout(() => {
        dispatch({ type: APPLY_REFERRAL_CODE_POPUP, payload: true });
      }, 1000);
    }

    navigate(to);
  };

  useEffect(() => {
    checkNavigation();
  }, []);
};

const HomeRedirect = ({ to }) => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(to);
  }, []);

  if (!Auth) {
    // location?.pathname === "/" && navigate("/signin");
  }
};

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const PrivateRoutes = {
  path: "/",
  element: <AuthLayout />,
  children: [
    {
      path: "/",
      element: <HomeRedirect to="/signin" />,
    },

    // fantasy app home routesss==>>>>>
    {
      path: "/refer",
      element: <ReferFriends />,
    },
    {
      path: "/Fantasy",
      element: <Fantasy />,
    },
    {
      path: "/FantasyMatch",
      element: <FantasyMatch />,
    },
    {
      path: "/AllContest",
      element: <AllContest />,
    },
    {
      path: "/Winnings",
      element: <Winnings />,
    },
    {
      path: "/PreviewTeam",
      element: <PreviewTeam />,
    },

    // my offer routes========>>>>>>
    {
      path: "/claim",
      element: <Claim />,
    },
    {
      path: "/upcomingmatch",
      element: <UpcomingMatch />,
    },
    {
      path: "/shop",
      element: <Shop />,
    },
    {
      path: "/daily-shop",
      element: <Shop />,
    },
    {
      path: "/wallet",
      element: <Wallet />,
    },
    {
      path: "/influencer",
      element: <TopInfluencer />,
    },
    {
      path: "/money-pool",
      element: <MoneyPool />,
    },
    {
      path: "/pool-detail",
      element: <PooDetail />,
    },
    {
      path: "/user-boosters",
      element: <UserBoosters />,
    },
    {
      path: "/play-rummy",
      element: <Rummy />,
    },
    {
      path: "/update-team",
      element: <Myteams />,
    },
    {
      path: "/save-team",
      element: <Saveteam />,
    },
    {
      path: "/create-team",
      element: <CreateTeam />,
    },
    {
      path: "/mycontest",
      element: <MyContest />,
    },
    {
      path: "/contest-details",
      element: <ContestDetails />,
    },
    {
      path: "/verifykyc",
      element: <Verifykyc />,
    },
    {
      path: "/congrats",
      element: <Congrats />,
    },

    {
      path: "/share-contest",
      element: <ShareContest />,
    },
    {
      path: "/mygames",
      element: <MyGames />,
    },
    {
      path: "/rewards",
      element: <Rewards />,
    },
    {
      path: "/missions",
      element: <Missions />,
    },
    {
      path: "/responsibleplay",
      element: <ResponsiblePlay />,
    },
    {
      path: "/notifications",
      element: <Notifications />,
    },
    {
      path: "/privacypolicy",
      element: <PrivacyPolicy />,
    },
    {
      path: "/terms-condition",
      element: <TermsConditions />,
    },

    {
      path: "/legality",
      element: <Legality />,
    },
    {
      path: "/contestinvite",
      element: <ContestInvite />,
    },
    {
      path: "/fantasypoint",
      element: <FantasyPoint />,
    },
    {
      path: "/faqs",
      element: <Faq />,
    },
    {
      path: "/about-us",
      element: <AboutUs />,
    },
    {
      path: "/contact-us",
      element: <ContactUsApp />,
    },
    {
      path: "/refund-policy",
      element: <RefundPolicy />,
    },
    {
      path: "/howtoplay",
      element: <HowToPlay />,
    },
    {
      path: "/withdrawamount",
      element: <WithdrawAmount />,
    },
    {
      path: "/addcash",
      element: <AddCash />,
    },
    {
      path: "/payment",
      element: <Payment />,
    },
    {
      path: "/payment_success",
      element: <PaymentSuccess />,
    },
    {
      path: "/payment_success_web",
      element: <PaymentSuccessWeb />,
    },
    {
      path: "/transaction",
      element: <Transaction />,
    },
    {
      path: "/myoffer",
      element: <MyOffer />,
    },
    {
      path: "/my-profile",
      element: <MyProfile />,
    },

    {
      path: "/help",
      element: <Help />,
    },
    {
      path: "/player-info",
      element: <PlayerInfo />,
    },
    {
      path: "/PlayerInfo2",
      element: <PlayerInfo2 />,
    },
    {
      path: "/createcontest",
      element: <Createcontest />,
    },
    {
      path: "/team-preview",
      element: <TeamPreview />,
    },
    {
      path: "/prize-breakup",
      element: <ContestPrizeBreakup />,
    },
    {
      path: "/select-team",
      element: <SelectTeam />,
    },
    {
      path: "/deposit-payment",
      element: <WebPayment />,
    },
    {
      path: "/season-history",
      element: <LeagueHistory />,
    },
    {
      path: "/chat-group",
      element: <Chat />,
    },
    //********************************soccer routes**************************************//
    {
      path: "/league-fantasy",
      element: <Myfantasy />,
    },
    {
      path: "/add-boosters",
      element: <BoosterListDailyContest />,
    },
    {
      path: "/verify-bank",
      element: <Verifiybank />,
    },
    {
      path: "/verify-pan",
      element: <Verifypan />,
    },
    {
      path: "/verify-aadhaar",
      element: <VerifyAadhaar />,
    },

    {
      path: "*",
      element: <NotFound to="/Fantasy" />,
    },
  ],
};

export default PrivateRoutes;
