import React, { lazy } from "react";

import Loadable from "../components/loader/Loadable";


const LeagueLayout = Loadable(lazy(() => import("../layout/LeagueLayout")));
const LeagueHistory = Loadable(lazy(() => import("../pages/session_leguage/history/LeagueHistory")));
const Home = Loadable(lazy(() => import("../pages/session_leguage/Home/Home")));
const Booster = Loadable(lazy(() => import("../pages/session_leguage/booster/Booster")));
const CreateTeam = Loadable(lazy(() => import("../pages/session_leguage/createTeam/LeagueCreateTeam")));
const SaveTeam = Loadable(lazy(() => import("../pages/session_leguage/createTeam/LeagueSaveTeam")));
const LeagueReviewTeam = Loadable(lazy(() => import("../pages/session_leguage/createTeam/LeagueReviewTeam")));
const TeamDetails = Loadable(lazy(() => import("../pages/session_leguage/teamDetails/TeamDetails")));
const MyContestLeague = Loadable(lazy(() => import("../pages/session_leguage/myContest/MyContest")));
const BoosterSelect = Loadable(lazy(() => import("../pages/session_leguage/booster/BoosterSelect")));
const LeagueProfile = Loadable(lazy(() => import("../pages/session_leguage/profile/LeagueProfile")));
const LeagueDetails = Loadable(lazy(() => import("../pages/session_leguage/leagueDetails/LeagueDetails")));
const SelectTeam = Loadable(lazy(() => import("../pages/session_leguage/myContest/LeagueSelectTeam")));
const History = Loadable(lazy(() => import("../pages/session_leguage/others/History")));
const Winning = Loadable(lazy(() => import("../pages/session_leguage/leagueDetails/Winning")));

const PlayerInfo = Loadable(lazy(() => import("../pages/session_leguage/playerInfo/PlayerInfo")));
const TeamPreview = Loadable(lazy(() => import("../pages/session_leguage/others/TeamPreview")));
const ShopHome = Loadable(lazy(() => import("../pages/booster shop/shopHome/ShopHome")));




const LeagueRoutes = {
  path: "/",
  element: <LeagueLayout />,
  children: [
    {
      path: "/league-home",
      element: <Home />,
    },
    {
      path: "/league-booster",
      element: <Booster />,
    },
    {
      path: "/league-booster-select",
      element: <BoosterSelect />,
    },
  
    {
      path: "/league-create-team",
      element: <CreateTeam />,
    },
    {
      path: "/league-save-team",
      element: <SaveTeam />,
    },
    {
      path: "/league-review-team",
      element: <LeagueReviewTeam />,
    },
    {
      path: "/league-team-detail",
      element: <TeamDetails />,
    },
    {
      path: "/league-contest",
      element: <MyContestLeague />,
    },
    {
      path: "/league-profile",
      element: <LeagueProfile />,
    },
    {
      path: "/league-details",
      element: <LeagueDetails />,
    },
    {
      path: "/league-select-team",
      element: <SelectTeam />,
    },
    {
      path: "/league-transfer-history",
      element: <History />,
    },
    {
      path: "/league-player-info",
      element: <PlayerInfo />,
    },
    {
      path: "/league-team-preview",
      element: <TeamPreview />,
    },
    {
      path: "/booster-shop",
      element: <ShopHome />,
    },
    {
      path: "/league-booster-shop",
      element: <ShopHome />,
    },
 
    {
      path: "/league-history",
      element: <LeagueHistory />,
    },
    {
      path: "/league-rank",
      element: <Winning />,
    },
  ],
};

export default LeagueRoutes;
