
import React, { useEffect, useState } from 'react'
import { ToastContainer, } from 'react-toastify'
import CombineRoutes from './routes/CombineRoutes'

import 'bootstrap/dist/css/bootstrap.min.css'
import { useDispatch, useSelector } from 'react-redux'
import 'react-tooltip/dist/react-tooltip.css'
import 'slick-carousel/slick/slick.css'
import io from "socket.io-client"
import './App.css'

import './assets/css/afterlogin/style.css'
import './assets/css/afterlogin/responsive.css'
import './assets/css/style.css' 
import './assets/css/responsive.css'
import DebuffPopup from './components/DebuffPopup'
import GiftSocketPopup from './components/GiftSocketPopup'
import { TimeComponent } from './utils/TimeComponent'
import CommonDialog from './components/CommonDialog'
import { get_profile } from './redux/actions/AuthActions'
import { useNavigate } from 'react-router-dom'
import { playSound } from './utils/playSound'



function App() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [debuffPopup, setDebuffPopup] = useState(false)
  const [giftPopup, setGiftPopup] = useState(false)
  const { user } = useSelector(state => state.auth)


  // const socket = io(process.env.REACT_APP_SOCKET_URL);

  const socket = io(process.env.REACT_APP_SOCKET_URL, {
    transports: ['websocket', 'polling'],
    withCredentials: true,
  });


  socket.on(`team_debuffed_${user?._id}`, (info) => {
    console.log('===>>>>>>>>>>>>>>>>>>>> socket called', info);
    setDebuffPopup(info)
  })

  socket.on(`gift_booster_${user?._id}`, (info) => {
    // playSound()
    console.log(`gift_booster_${user?._id}`, '===>>>>>>>>>>>>>>>>>>>> socket called', info);
    setGiftPopup(info)
  })


  socket.on(`user_ban_${user?._id}`, (info) => {
    console.log(`user_ban_${user?._id}`, '===>>>>>>>>>>>>>>>>>>>> socket called ban user from chat', info);
    dispatch(get_profile());
  })
  socket.on(`user_unban_${user?._id}`, (info) => {
    console.log(`user_ban_${user?._id}`, '===>>>>>>>>>>>>>>>>>>>> socket called Unban user from chat', info);
    dispatch(get_profile());
  })
  socket.on(`sendEmit`, (info) => {
    console.log(`sendEmit`, '===>>>>>>>>>>>>>>>>>>>> get this emit:::::', info);
    socket.emit('receivedEmit', {}, (data) => {
      console.log('success from backend==>>>>>>>>>>>>', data)
      navigate('/Fantasy')
    })
    
  })

// console.log('app.js is working==>>>>>>>>>>');


  return (
    <div className='App'>

      <TimeComponent />
      <CombineRoutes />
      <ToastContainer icon={<img src="../favicon.ico" className="" alt='' />} />

      {
        debuffPopup &&
        <DebuffPopup state={debuffPopup} setState={setDebuffPopup} />
      }
      {
        giftPopup &&
        <GiftSocketPopup state={giftPopup} setState={setGiftPopup} />
      }



      <CommonDialog />

    </div>
  )
}

export default App
