import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Session from "../utils/session";

function BottomMenu() {
  const navigate = useNavigate();
  const url = window.location.pathname;
  const homeActiveKey = Session.getSession("homeActiveKey");
  // const path = Session.getSession('activeMenu')
  // console.log('----------------------',path);

  // var path =  url : Session.getSession('activeMenu')

  const handleNavigate = (nav) => {
    navigate(nav);
    // Session.setSession('activeMenu', nav)
    if (nav === "/upcomingmatch") {
      Session.clearSession("selected_tab");
    }
  };

  const urls = {
    "/Fantasy": ["/Fantasy", "/FantasyMatch"],
    "/money-pool": ["/money-pool"],
    "/upcomingmatch": ["/upcomingmatch", "/league-fantasy"],
    // "/shop": ["/shop"],
    "/myoffer": ["/myoffer"],
    "/wallet": ["/wallet"],
  };

  const activeMenu = (nav) => {
    if (urls[nav]?.includes(url)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div>
      <div className="bottom_menu menu_tabs_fx">
        <ul>
        <li className="cursor text-light-blue">
            <a
              onClick={() => handleNavigate("/money-pool")}
              className={`${activeMenu("/money-pool") ? "active" : ""}`}
            >
              {" "}
              <img
                style={{ height: "27px", width: "27px" }}
                className="active"
                src="./images/home_active.png"
                alt=""
              />{" "}
              Money Pool
            </a>
          </li>
          <li className="cursor text-yellow">
            <a
              onClick={() => handleNavigate("/Fantasy")}
              className={`${activeMenu("/Fantasy") ? "active" : ""}`}
            >
              {" "}
              <img
                className="active"
           
                src="./images/prizepool_active.png"
                alt=""
              />{" "}
              Fantasy
            </a>
          </li>
         
          <li className="home_menu cursor bottom-home">
            <a
              onClick={() =>
                handleNavigate("/league-fantasy")
              }
              className={`${activeMenu("/upcomingmatch") ? "active" : ""}`}
            >
              <img className="d-block" src="./images/home_menu.png" alt=""  style={{height:"33px"}} />
              My Fantasy
            </a>
          </li>
          {/* <li className="cursor text-purple">
            <a
              onClick={() => handleNavigate("/shop")}
              className={`${activeMenu("/shop") ? "active" : ""}`}
            >
              {" "}
              <img
                className="active"
                src="./images/shop_active.png"
                alt=""
                style={{ height: "27px", width: "27px" }}
              />{" "}
              Shop
            </a>
          </li> */}
          <li className="cursor text-purple">
            <a
              onClick={() => handleNavigate("/myoffer")}
              className={`${activeMenu("/myoffer") ? "active" : ""}`}
            >
              {" "}
              <img
                className="active"
                src="./images/offer_menu.png"
                alt=""
                style={{ height: "27px", width: "27px" }}
              />{" "}
              Offers
            </a>
          </li>
          <li className="cursor text-light-red">
            <a
              onClick={() => handleNavigate("/wallet")}
              className={`${activeMenu("/wallet") ? "active" : ""}`}
            >
              {" "}
              <img
                className="active"
                src="./images/activewallet.svg"
                alt=""
              />{" "}
              Wallet
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default BottomMenu;
