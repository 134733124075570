// import { LOGIN_SUCCESS, LOGOUT_SUCCESS, FORGOT_PASSWORD, VERIFY_OTP, PACKAGE_ID } from '../constant/Auth'

import Session from '../../utils/session'
import {
  INVITE_OPEN,
  LOADER,
  JOIN_CONTSEST,
  CONTEST_PDF_MODAL,
  CONTEST_CONFIRMATION,
  TEAM_LOADER,
  LOW_BALANCE,
  HOME_ACTIVE_KEY,
  APPLY_REFERRAL_CODE_POPUP,
  DEFAULT_GUIDES_POPUP,
  MATCH_DEAD_LINE_POPUP,
  MATCH_LIST_LOADER,
  POOL_DEAD_LINE_POPUP
} from '../constants/Common'

let initialState = {
  loader: false,
  isInviteOpne: false,
  isJoinContest: false,
  isOpenTeamPDFDownload: false,
  contestPayload: null,
  isConfirm: false,
  team_loader: false,
  low_balance: false,
  homeActiveKey: Session.getSession('homeActiveKey') || 'cricket',
  applyReferralCode: false,
  defaultGuidesPopup: false,
  matchDeadLinePopup: false,
  poolDeadLinePopup: false,
  matchLoader: false
}

const CommonReducer = function (state = initialState, action) {
  try {
    switch (action.type) {
      case LOADER:
        return {
          ...state,
          loader: action.payload
        }
      case MATCH_LIST_LOADER:
        return {
          ...state,
          matchLoader: action.payload
        }
      case TEAM_LOADER:
        return {
          ...state,
          team_loader: action.payload
        }

      case INVITE_OPEN:
        return {
          ...state,
          isInviteOpne: action.payload
        }

      case JOIN_CONTSEST:
        return {
          ...state,
          isJoinContest: action.payload
        }
      case CONTEST_PDF_MODAL:
        return {
          ...state,
          isOpenTeamPDFDownload: action?.payload?.modal,
          contestPayload: action?.payload?.data
        }

      case CONTEST_CONFIRMATION:
        return {
          ...state,
          isConfirm: action?.payload
        }
      case LOW_BALANCE:
        return {
          ...state,
          low_balance: action?.payload
        }
      case HOME_ACTIVE_KEY:
        Session.setSession('homeActiveKey', action?.payload)
        return {
          ...state,
          homeActiveKey: action?.payload
        }
      case APPLY_REFERRAL_CODE_POPUP:
        return {
          ...state,
          applyReferralCode: action?.payload
        }
      case DEFAULT_GUIDES_POPUP:
        return {
          ...state,
          defaultGuidesPopup: action?.payload
        }
      case MATCH_DEAD_LINE_POPUP:
        return {
          ...state,
          matchDeadLinePopup: action?.payload
        }
      case POOL_DEAD_LINE_POPUP:
        return {
          ...state,
          poolDeadLinePopup: action?.payload
        }

      default:
        return state
    }
  } catch (error) {
    //console.log('err in reducers==>>>>', error)
  }
}

export default CommonReducer
