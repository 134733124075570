import { toast } from "react-toastify";
import apiPath from "../../utils/apiPath";
import helper from "../../utils/helper";
import Session from "../../utils/session";
import {
  ACCOUNT_STATEMENT,
  ADMIN_SETTING,
  BANK_UPDATE,
  GET_PROFILE,
  GET_STATE_LIST,
  LOADER,
  LOAD_ACCOUNT_STATEMENT,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  PAN_UPDATE,
  PROFILE_UPDATE,
  WITHDRAW_LIST,
} from "../constants/Auth";
import { DEFAULT_GUIDES_POPUP, STATE_DATA__DAILY } from "../constants/Common";
import { ALL_PLAYER_LIST } from "../constants/Fantasy";
const qs = require("qs");

export const registerUser = (data) => async (dispatch) => {
  try {
    dispatch({ type: LOADER, payload: true });
    data.phone = data?.phone?.slice(data?.country_code?.length);
    data.device_type = "web";
    data.device_id = "device_id";
    data.country_code = !data?.country_code?.includes("+") && "+" + data?.country_code;

    const res = await helper.api(apiPath.register, "post", data);

    if (res?.success) {
      dispatch({
        type: LOADER,
        payload: false,
      });
      toast.success(res?.msg);
      return res;
    } else {
      dispatch({
        type: LOADER,
        payload: false,
      });
      toast.error(res?.msg);
      return false;
    }
  } catch (error) {
    //console.log('error in login api::', error)
  }
};
export const loginUser = (data) => async (dispatch) => {
  try {
    dispatch({ type: LOADER, payload: true });

    let payload = {
      email: data?.email,
      password: data?.password,
      mobile: data?.phone?.slice(data?.country_code?.length),
      country_code: !data?.country_code?.includes("+") && "+" + data?.country_code,
      user_type: "user",
      device_id: "device_id",
      device_type: "web",
    };

    payload = qs.stringify(payload);

    const res = await helper.api(apiPath.login, "post", payload);

    if (res?.success) {
      dispatch({
        type: LOADER,
        payload: false,
      });
      return res;
    } else {
      dispatch({
        type: LOADER,
        payload: false,
      });
      toast.error(res?.msg);
      return false;
    }
  } catch (error) {
    //console.log('error in login api::', error)
  }
};

export const emailLogin = (data) => async (dispatch) => {
  // email login and mobile login
  try {
    dispatch({ type: LOADER, payload: true });

    let payload = {
      email: data?.email,
      password: data?.password,
      user_type: "user",
      device_id: "device_id",
      device_type: "web",
    };

    if (data?.phone) {
      payload.email = data?.phone?.slice(data?.country_code?.length);
      payload.country_code = !data?.country_code?.includes("+") && "+" + data?.country_code;
    }

    const res = await helper.api(apiPath.emailLogin, "post", payload);
    if (res?.success) {
      if (res?.result?.country && res?.result?.username) {
        dispatch({ type: LOGIN_SUCCESS, payload: res });
      }
      dispatch({
        type: LOADER,
        payload: false,
      });
      return res;
    } else {
      dispatch({
        type: LOADER,
        payload: false,
      });
      toast.error(res?.msg);
      return res;
    }
  } catch (error) {}
};

export const ResendOtp = (data) => async (dispatch) => {
  try {
    dispatch({ type: LOADER, payload: true });
    const payload = {};

    payload.mobile = data?.phone;
    payload.country_code = data?.country_code;

    const res = await helper.api(apiPath.login, "post", payload);
    if (res?.success) {
      dispatch({
        type: LOADER,
        payload: false,
      });
      toast.success(res?.msg);
      return res;
    } else {
      dispatch({
        type: LOADER,
        payload: false,
      });
      toast.error(res?.msg);
      return res;
    }
  } catch (error) {
    //console.log('error in verify otp api::', error)
  }
};
export const forgotPassword = (data) => async (dispatch) => {
  try {
    dispatch({ type: LOADER, payload: true });

    let payload = {
      email: data?.email,
    };

    const res = await helper.api(apiPath.forgotPassword, "post", payload);
    //console.log('resssssssssssssssss', res)
    if (res?.success) {
      dispatch({
        type: LOADER,
        payload: false,
      });
      toast.success(res?.msg);
      return res;
    } else {
      dispatch({
        type: LOADER,
        payload: false,
      });
      toast.error(res?.msg);
      return res;
    }
  } catch (error) {
    //console.log('error in verify otp api::', error)
  }
};

export const verifyEmailOtp = (data) => async (dispatch) => {
  try {
    dispatch({ type: LOADER, payload: true });

    var payload = JSON.parse(JSON.stringify(data));

    const res = await helper.api(apiPath.verifyEmailOtp, "post", payload);
    //console.log('resssssssssssssssss', res)
    if (res?.success) {
      if (res?.result?.dob && res?.result?.state) {
        dispatch({ type: LOGIN_SUCCESS, payload: res });
      }
      dispatch({
        type: LOADER,
        payload: false,
      });
      // toast.success(res?.msg);
      return res;
    } else {
      dispatch({
        type: LOADER,
        payload: false,
      });
      // toast.error(res?.msg);
      return res;
    }
  } catch (error) {
    //console.log('error in verify otp api::', error)
  }
};
export const verifyOtp = (data) => async (dispatch) => {
  try {
    dispatch({ type: LOADER, payload: true });

    var payload = JSON.parse(
      JSON.stringify({
        ...data,
        device_type: "web",
        device_id: "device_id",
      })
    );

    const res = await helper.api(apiPath.verifyOtp, "post", payload);
    //console.log('resssssssssssssssss', res)
    if (res?.success) {
      if (res?.result?.dob && res?.result?.state) {
        dispatch({ type: LOGIN_SUCCESS, payload: res });
      }
      dispatch({
        type: LOADER,
        payload: false,
      });
      // toast.success(res?.msg);
      return res;
    } else {
      dispatch({
        type: LOADER,
        payload: false,
      });
      toast.error(res?.msg);
      return res;
    }
  } catch (error) {
    //console.log('error in verify otp api::', error)
  }
};
export const logout = (data) => async (dispatch) => {
  try {
    dispatch({ type: LOADER, payload: true });
    let payload = {};
    // payload = JSON.stringify(payload)

    payload = qs.stringify(payload);

    const res = await helper.api(apiPath.logout, "get", payload);

    if (true) {
      Session.clearAllSession();
      dispatch({
        type: LOGOUT_SUCCESS,
        payload: res,
      });
      dispatch({
        type: LOADER,
        payload: false,
      });
      window.location.reload();
      // window.history.pushState({urlPath:'/'},"",'/')
      // toast.success(res?.msg);
      return res;
    } else {
    }
  } catch (error) {
    //console.log('error in logout  api::', error)
  }
};
export const updateProfile = (dict) => async (dispatch) => {
  try {
    dispatch({ type: LOADER, payload: true });
    var data = {
      email: dict?.email,
      phone: dict?.mobile,
      username: dict?.username?.trim(),
      full_name: dict?.full_name?.trim(),
      gender: dict?.gender,
      dob: dict?.dob,
      state: dict?.state,
      // 'gender': 'female',
    };

    data = JSON.stringify(dict);
    let formData = new FormData();

    formData.append("data", data);
    formData.append("profile_pic", dict?.image);

    const res = await helper.api(apiPath.updateProfile, "post", formData);

    if (res?.success) {
      dispatch({
        type: PROFILE_UPDATE,
        payload: res,
      });
      dispatch({
        type: LOADER,
        payload: false,
      });
      toast.success(res?.msg);
      return res;
    } else {
      dispatch({
        type: LOADER,
        payload: false,
      });
      toast.error(res?.msg);
      return false;
    }
  } catch (error) {
    //console.log('error in login api::', error)
  }
};
export const updateWelcome = (dict) => async (dispatch) => {
  try {
    dispatch({ type: LOADER, payload: true });
    var data = {
      email: dict?.email,
      phone: dict?.mobile,
      username: dict?.username?.trim(),
      full_name: dict?.full_name?.trim(),
      gender: dict?.gender,
      dob: dict?.dob,
      state: dict?.state,
      // 'gender': 'female',
    };

    data = JSON.stringify(dict);
    let formData = new FormData();

    formData.append("data", data);
    formData.append("profile_pic", dict?.image);

    const res = await helper.api(apiPath.updateProfile, "post", formData);

    if (res?.success) {
      dispatch({
        type: PROFILE_UPDATE,
        payload: res,
      });
      dispatch({
        type: LOADER,
        payload: false,
      });
      toast.success(res?.msg);
      return res;
    } else {
      dispatch({
        type: LOADER,
        payload: false,
      });
      toast.error(res?.msg);
      return false;
    }
  } catch (error) {
    //console.log('error in login api::', error)
  }
};
export const get_profile = (data) => async (dispatch) => {
  try {
    dispatch({ type: LOADER, payload: true });
    let payload = {};
    // payload = JSON.stringify(payload)

    payload = qs.stringify(payload);

    const res = await helper.api(apiPath.get_profile, "get", payload);

    if (res?.success) {
      dispatch({
        type: GET_PROFILE,
        payload: res,
      });
      dispatch({
        type: LOADER,
        payload: false,
      });
      // toast.success(res?.msg);
      return res;
    } else {
      dispatch({
        type: LOADER,
        payload: false,
      });
      // toast.error(res?.msg);
      return res;
    }
  } catch (error) {
    //console.log('error in get_profile  api::', error)
  }
};
export const send_verify_email = (userData) => async (dispatch) => {
  try {
    dispatch({ type: LOADER, payload: true });

    var data = {
      email: userData?.email,
    };

    // data = JSON.stringify(data)

    const res = await helper.api(apiPath.send_verify_email, "post", data);
    if (res?.success) {
      // dispatch({
      //     type: EMAIL_VERIFICATION_LINK,
      //     payload: res
      // })
      dispatch({
        type: LOADER,
        payload: false,
      });
      toast.success(res?.msg);
      return res;
    } else {
      dispatch({
        type: LOADER,
        payload: false,
      });
      toast.error(res?.msg);
      return false;
    }
  } catch (error) {
    //console.log('error in send verify email api::', error)
  }
};
export const send_otp_kyc = (data) => async (dispatch) => {
  try {
    dispatch({ type: LOADER, payload: true });
    // data = JSON.stringify(data)

    const res = await helper.api(apiPath.login, "post", data);
    if (res?.success) {
      // dispatch({
      //     type: EMAIL_VERIFICATION_LINK,
      //     payload: res
      // })
      dispatch({
        type: LOADER,
        payload: false,
      });
      toast.success(res?.msg);
      return res;
    } else {
      dispatch({
        type: LOADER,
        payload: false,
      });
      toast.error(res?.msg);
      return false;
    }
  } catch (error) {
    //console.log('error in send verify email api::', error)
  }
};
export const startKYC = (data) => async (dispatch) => {
  try {
    dispatch({ type: LOADER, payload: true });
    // data = JSON.stringify(data)

    // const res = await helper.api(apiPath.startKYC, 'post', data)
    const res = await helper.api("https://api.indiasfantasy.com/api/users/start-kyc", "post", data);

    if (res?.success) {
      dispatch({
        type: LOADER,
        payload: false,
      });
      return res;
    } else {
      dispatch({
        type: LOADER,
        payload: false,
      });
      toast.error(res?.msg);
      return false;
    }
  } catch (error) {
    //console.log('error in send verify email api::', error)
  }
};
export const get_state_list = (data) => async (dispatch) => {
  try {
    dispatch({ type: LOADER, payload: true });
    let payload = {};

    payload = qs.stringify(payload);

    const res = await helper.api(apiPath.get_state_list, "get", payload);

    if (res?.success) {
      dispatch({
        type: GET_STATE_LIST,
        payload: res,
      });
      dispatch({
        type: LOADER,
        payload: false,
      });
      // toast.success(res?.msg);
      return res;
    } else {
      dispatch({
        type: LOADER,
        payload: false,
      });
      // toast.error(res?.msg);
      return res;
    }
  } catch (error) {
    //console.log('error in get_state_list  api::', error)
  }
};
export const verifyAadhaar = (user) => async (dispatch) => {
  try {
    dispatch({ type: LOADER, payload: true });

    var data = {
      aadhaar_number: user?.aadhaar,
    };
    const res = await helper.api(apiPath.sendAadhaarOtp, "post", data);

    if (res?.success) {
      dispatch({ type: LOADER, payload: false });
      toast.success(res?.msg);
      return res;
    } else {
      dispatch({ type: LOADER, payload: false });
      toast.error(res?.msg);
      return false;
    }
  } catch (error) {
    console.log('error in pan verification api::', error)
  }
};
export const verifyAadhaarOtp = (user) => async (dispatch) => {
  try {
    dispatch({ type: LOADER, payload: true });

    var data = {
      otp: user?.otp,
      ref_id: user?.ref_id,
    };
    const res = await helper.api(apiPath.verifyAadhaarOtp, "post", data);

    if (res?.success) {
      dispatch({ type: LOADER, payload: false });
      toast.success(res?.msg);
      return res;
    } else {
      dispatch({ type: LOADER, payload: false });
      toast.error(res?.msg);
      return false;
    }
  } catch (error) {
    console.log('error in pan verification api::', error)
  }
};
export const verifyPanCard = (user) => async (dispatch) => {
  try {
    dispatch({ type: LOADER, payload: true });

    var data = {
      pan_number: user?.pan,
      name: user?.name,
    };
    const res = await helper.api(apiPath.verifyPanPath, "post", data);

    if (res?.success) {
      dispatch({ type: LOADER, payload: false });
      toast.success(res?.msg);
      return res;
    } else {
      dispatch({ type: LOADER, payload: false });
      toast.error(res?.msg);
      return false;
    }
  } catch (error) {
    console.log('error in pan verification api::', error)
  }
};
export const verify_bank = (user) => async (dispatch) => {
  try {
    dispatch({ type: LOADER, payload: true });

    var data = {
      full_name: user?.account_holder_name,
      bank_account: user?.account_no,
      ifsc: user?.ifsc_code,
    };

    const res = await helper.api(apiPath.verify_bank, "post", data);

    if (res?.success) {
      dispatch({
        type: LOADER,
        payload: false,
      });
      toast.success(res?.msg);
      return res;
    } else {
      dispatch({
        type: LOADER,
        payload: false,
      });
      toast.error(res?.msg);
      return false;
    }
  } catch (error) {
    //console.log('error in update bank api::', error)
  }
};
export const get_account_statement = (qString, page) => async (dispatch) => {
  // console.log('dataaaaaaaaaa',page)
  try {
    // dispatch({ type: LOADER, payload: true })
    let payload = {};
    // payload = JSON.stringify(payload)
    let full_path = apiPath.account_statement + qString;

    payload = qs.stringify(payload);

    const res = await helper.api(full_path, "GET", payload);
    // console.log("res", res);
    if (res?.success) {
      dispatch({
        type: ACCOUNT_STATEMENT,
        payload: { res, page },
      });
      dispatch({
        type: LOADER,
        payload: false,
      });
      // toast.success(res?.msg);
      return res;
    } else {
      dispatch({
        type: LOADER,
        payload: false,
      });
      // toast.error(res?.msg);
      return res;
    }
  } catch (error) {
    //console.log('error in account statement api::', error)
  }
};

export const load_account_statement = (qString) => async (dispatch) => {
  try {
    dispatch({ type: LOADER, payload: true });
    let payload = {};
    // payload = JSON.stringify(payload)
    let full_path = apiPath.account_statement + qString;

    payload = qs.stringify(payload);

    const res = await helper.api(full_path, "GET", payload);
    //console.log("res", res);
    if (res?.success) {
      dispatch({
        type: LOAD_ACCOUNT_STATEMENT,
        payload: res,
      });
      dispatch({
        type: LOADER,
        payload: false,
      });
      // toast.success(res?.msg);
      return res;
    } else {
      dispatch({
        type: LOADER,
        payload: false,
      });
      // toast.error(res?.msg);
      return res;
    }
  } catch (error) {
    //console.log('error in account statement api::', error)
  }
};
export const get_withdraw_list = (data) => async (dispatch) => {
  try {
    dispatch({ type: LOADER, payload: true });
    let payload = {};

    const res = await helper.api(apiPath?.getWithdrawList, "GET");
    //console.log("res", res);
    if (res?.success) {
      dispatch({
        type: WITHDRAW_LIST,
        payload: res,
      });
      dispatch({
        type: LOADER,
        payload: false,
      });
      // toast.success(res?.msg);
      return res;
    } else {
      dispatch({
        type: LOADER,
        payload: false,
      });
      // toast.error(res?.msg);
      return res;
    }
  } catch (error) {
    //console.log('error in account statement api::', error)
  }
};

export const bank_transafer = (request_amount) => async (dispatch) => {
  try {
    dispatch({ type: LOADER, payload: true });
    let payload = { request_amount: request_amount };

    payload = qs.stringify(payload);

    const res = await helper.api(apiPath?.bankTransfer, "post", payload);
    //console.log("res", res);

    dispatch({ type: LOADER, payload: false });
    // toast.success(res?.msg);
    return res;
  } catch (error) {
    //console.log('error in account statement api::', error)
  }
};

export const get_setting = (data) => async (dispatch) => {
  try {
    dispatch({ type: LOADER, payload: true });

    const res = await helper.api(apiPath?.getSetting, "get");
    //console.log("res", res);
    if (res?.success) {
      dispatch({
        type: LOADER,
        payload: false,
      });
      dispatch({
        type: ADMIN_SETTING,
        payload: res,
      });

      return res;
    } else {
      dispatch({
        type: LOADER,
        payload: false,
      });
      // toast.error(res?.msg);
      return false;
    }
  } catch (error) {
    //console.log('error in account statement api::', error)
  }
};

export const updateTeam = (data) => async (dispatch) => {
  try {
    //console.log("update team action calledd==>>>>", data)
    dispatch({ type: LOADER, payload: true });
    const res = await helper.api(apiPath.createTeam, "post", data);
    //console.log('result of api==>>>>>>>', res)

    if (res?.success) {
      dispatch({
        type: LOADER,
        payload: false,
      });
      toast.success(res?.msg);
      setTimeout(() => {
        dispatch({ type: ALL_PLAYER_LIST, payload: [] });
        dispatch({ type: STATE_DATA__DAILY, payload: {} });
      }, 1000);
      return res;
    } else {
      dispatch({
        type: LOADER,
        payload: false,
      });
      toast.error(res?.msg);
      return false;
    }
  } catch (error) {
    //console.log('error in update bank api::', error)
  }
};
