import React, { useState } from 'react'
import { Button, FloatingLabel, Form, Modal, Offcanvas } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { get_profile } from '../redux/actions/AuthActions'
import { LOADER } from '../redux/constants/Auth'
import { APPLY_REFERRAL_CODE_POPUP, DEFAULT_GUIDES_POPUP } from '../redux/constants/Common'
import apiPath from '../utils/apiPath'
import helper from '../utils/helper'

const CommonDialog = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { applyReferralCode } = useSelector(state => state.loader)
    const { defaultGuidesPopup } = useSelector(state => state.loader)
    const urlParams = new URLSearchParams(window.location.search);
    const defaultReferralCode = urlParams.get('referral');


    const [referralCode, setReferralCode] = useState(defaultReferralCode)
    const [error, setError] = useState(null)

    const hide_referral_popup = () => {
        dispatch({ type: APPLY_REFERRAL_CODE_POPUP, payload: false })
        navigate('/Fantasy')
    }


    const handleReferralCode = (e) => {
        setReferralCode(e.target.value)
        if (referralCode !== '') {
            setError(null)
        }
    }

    const handleCheckReferralCode = async () => {
        try {
            if (referralCode === '') {
                setError('Please enter referral code')
                return
            }

            dispatch({ type: LOADER, payload: true })
            var path = apiPath?.apply_referral + referralCode

            const res = await helper.api(path, 'get', {})

            if (res?.success) {
                toast.success(res?.msg)
                hide_referral_popup()
                dispatch(get_profile());
            } else {
                setError(res?.msg)
            }
            dispatch({ type: LOADER, payload: false })
        } catch (error) {
            //console.log('error in get  notification page api::', error)
            dispatch({ type: LOADER, payload: false })
        }
    }

    const closeWarning = () => {
        dispatch({ type: DEFAULT_GUIDES_POPUP, payload: false })
    }

    const cancel = () => {
        dispatch({ type: DEFAULT_GUIDES_POPUP, payload: false })
    }

    const guides = () => {
        const youTubeUrl = process.env.REACT_APP_GUIDES_URL || 'https://www.youtube.com/watch?v=tAP5jTWWEfY&list=PLs8Kvuln33jXLknrBJjVQmaIq9VDFy_0X&index=1'
        window.open(youTubeUrl, '_blank');
        dispatch({ type: DEFAULT_GUIDES_POPUP, payload: false })
    }

    
    return (

        <>
            <Offcanvas
                show={applyReferralCode}
                onHide={() => hide_referral_popup()}
                placement={"bottom"}
                className="offcanvas-common contest-canvas"
            >
                <Offcanvas.Header closeButton className="">
                    <div className="d-flex justify-content-between align-items-center filter-heading2 text-dark">
                        <Offcanvas.Title>Invited by a friend?</Offcanvas.Title>
                    </div>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form>

                        <div className="kyc_card contest-card mt-2 px-sm-3 px-0">
                            <Form.Label className="mb-3 fw-bold">
                                If you have a referral code, enter it and apply.
                            </Form.Label>
                            <FloatingLabel
                                controlId="floatingPassword"
                                label="Enter Referral Code"
                                className="mb-4 fw-bold"
                            >
                                <Form.Control
                                    type="text"
                                    placeholder=""
                                    defaultValue={defaultReferralCode}
                                    onChange={(e) => handleReferralCode(e)}
                                />
                                <span className="text-danger">{error !== null && error}</span>
                            </FloatingLabel>

                            <Button
                                className="btn-blue w-100 fw_700"
                                onClick={() => handleCheckReferralCode()}
                            >
                                Apply
                            </Button>
                        </div>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>


        </>
    )
}

export default CommonDialog 