import React, { useState } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { HOME_ACTIVE_KEY } from "../redux/constants/Common";
import { BOOSTER_SHOP_TABS, STANDARD_BOOSTERS } from "../redux/constants/Shop";
import { ALL_PLAYER_LIST_SOCCER } from "../redux/constants/cricket";
import MatchTimer from "../utils/MatchTimer";
import Session from "../utils/session";

const BackArrow = ({ name }) => {
  const { homeActiveKey } = useSelector((state) => state.loader);
  const { standardBooster } = useSelector((state) => state.shop);

  const navigate = useNavigate();

  const location = useLocation();
  const dispatch = useDispatch();

  const [isExitsCreateTeam, setUIsExitsCreateTeam] = useState(false);

  const navigationPath = {
    cricket: "/AllContest",
  };
  const handleBack = () => {
    // navigate('/Fantasy', { replace: true });

    if (["/verifykyc"]?.includes(location?.pathname)) {
      navigate("/wallet");
      return;
    }
    if (["/add-boosters"]?.includes(location?.pathname)) {
      Session.setSession("from", "/add-boosters");
    }

    if (["/AllContest"]?.includes(location?.pathname)) {
      navigate("/Fantasy");
      return;
    }

    if (standardBooster && ["/shop"]?.includes(location?.pathname)) {
      dispatch({ type: STANDARD_BOOSTERS, payload: false });
      dispatch({ type: BOOSTER_SHOP_TABS, payload: "boosters" });
      return;
    }

    if (["/update-team", "/create-team"]?.includes(location?.pathname)) {
      setUIsExitsCreateTeam(true);
      return;
    }

    if (["/upcomingmatch"]?.includes(location?.pathname)) {
      navigate("/Fantasy", { state: "", replace: true });
      return;
    }

    // return
    if ((["/contest-details"].includes(location?.pathname) && location?.state?.from === "invite") || location?.state?.isCopyCode) {
      navigate("/Fantasy");
      return;
    }

    if (location?.state?.from === "notification") {
      if (["/mycontest"]?.includes(location?.pathname)) {
        navigate("/Fantasy");
        return;
      }
      navigate(-1);
    } else if (location?.pathname === "/share-contest") {
      navigate(navigationPath[homeActiveKey], { replace: true });
    } else if (location?.pathname === "/pool-detail") {
      navigate("/money-pool");
    } else {
      navigate(-1);
    }
  };

  var isUrl = ["/upcomingmatch", "/league-fantasy"];


  const closeTeamExitsPopUp = () => {
    setUIsExitsCreateTeam(!isExitsCreateTeam);
  };
  const exitsTeam = () => {
    navigate(-1);
    setUIsExitsCreateTeam(false);
  };

  return (
    <>
      <div className="back_arrow cursor">
        <div className="d-flex align-items-center">
          <div className="top_header_back">
            <img onClick={() => handleBack()} src="./images/top_arrow.png" className="me-1" alt="" />{" "}
            <div className="top_header_back_timer ms-2" style={{ fontSize: "16px" }}>
              {!isUrl?.includes(location?.pathname) && !name.includes("undefined") && name}
            </div>
          </div>
          {isUrl?.includes(location?.pathname) && (
            <div className="score-over-sec flex-fill mw-100 me-2 justify-content-end ">
              <Dropdown className="highlight_tabs">
                <div variant="success" id="dropdown-basic" className="bg-transparent border-0 p-0">
                  <span style={{ fontSize: "18px", fontWeight: 600 }}>{name}</span>
                </div>
              </Dropdown>
            </div>
          )}
        </div>
      </div>

      {/* exits from team making process */}
      <Modal show={isExitsCreateTeam} onHide={closeTeamExitsPopUp} className="contest_confirmation contest_confirmation_popup team_exits_dialog  ">
        <Modal.Body className="bg-dark">
          <div className="team_exits_body px-4">
            <figure>
              <img src="../images/logo.png" alt="" height={"100px"} />
            </figure>

            <span className="text-white">Are you sure you want to discard your team?</span>

            <div className="w-100 confirm-box">
              <Button variant="danger" onClick={() => closeTeamExitsPopUp()} className="border-0 w-100 bg-orange text-white" style={{ background: "rgb(216 77 37)" }}>
                Cancel
              </Button>
              <Button variant="primary" onClick={() => exitsTeam()} className="border-0 w-100 ms-10">
                Ok
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BackArrow;
