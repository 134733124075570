const BASEURL = process.env.REACT_APP_API_URL;
const USER_BASEURL = BASEURL + "/users/";

const USER_V2_BASEURL = BASEURL + "/v2/users/";

// console.log('USER_BASEURL', BASEURL)
const apiPath = {
  // auth paths
  getCaptcha: USER_BASEURL + "get-captcha",
  emailLogin: USER_BASEURL + "login",
  forgotPassword: USER_BASEURL + "forgot-password",
  // register: USER_BASEURL + 'register',
  login: USER_BASEURL + 'send-otp',
  verifyOtp: USER_BASEURL + 'verify-otp',
  resendOtp: USER_BASEURL + 'resend-otp',
  startKYC: USER_BASEURL + 'start-kyc',
  getMedia: USER_BASEURL + 'get-media-url',

  verifyEmailOtp: USER_BASEURL + "verify-email-otp",
  logout: USER_BASEURL + "logout",
  updateProfile: USER_BASEURL + "profile",
  getGoogleSignUpData: USER_BASEURL + "social-login-for-web",
  socialLogin: USER_BASEURL + "social-login",

  get_banners: USER_BASEURL + "get-banners",
  get_home_banners: USER_BASEURL + "get-home-banners",
  get_profile: USER_BASEURL + "get-profile",
  send_verify_email: USER_BASEURL + "send-verify-email",

  // static page paths
  getFaq: USER_BASEURL + "get-faqs?page=1&itemsPerPage=10",
  getStaticpage: USER_BASEURL + "get-page-by-slug/",

  // Notification
  getNotification: USER_BASEURL + "get-notification",
  readNotification: USER_BASEURL + "read-notification",

  // home page
  sendApkLink: USER_BASEURL + "send-apk-link",
  checkReferralCode: USER_BASEURL + "check-referal-code",
  apply_referral: USER_BASEURL + "apply-referral?referralCode=",

  // home paths
  // get_banners: USER_BASEURL + 'get-home-banners',

  // kyc paths
  get_state_list: USER_BASEURL + "get-state-list",
  update_national_identity: USER_BASEURL + "update-national-identity",
  sendAadhaarOtp: USER_BASEURL + "send-otp-for-aadhar-verification",
  verifyAadhaarOtp: USER_BASEURL + "enter-otp-for-aadhar-verification",
  verifyPanPath: USER_BASEURL + "pan-verification",
  verify_bank: USER_BASEURL + "bank-verification",
  account_statement: USER_V2_BASEURL + "account-statement",
  getWithdrawList: USER_V2_BASEURL + "get-withdrawal-list?page=1&itemsPerPage=10",
  bankTransfer: USER_V2_BASEURL + "request-withdraw",

  // match paths
  get_home_series_list: BASEURL + "/fantasy/series-list",
  get_active_matches_list: BASEURL + "/fantasy/get-active-matches-list",
  get_live_and_upcoming_matches: BASEURL + "/fantasy/get-live-and-upcoming-matches",
  getPlayerStateForSingleMatch: BASEURL + "/fantasy/player-stats-for-single-match",
  getPlayerDetails: BASEURL + "/fantasy/series-player-details",
  getMatchDetail: BASEURL + "/fantasy/match-detail",
  getContestListByMatchJson: BASEURL + "/v1.1/fantasy/get-contest-list-byMatch-json",
  createTeam: BASEURL + "/fantasy/create-team",

  // sidebar Api path
  getRewardList: USER_BASEURL + "rewards",
  getOffersList: USER_BASEURL + "get-coupons",
  applyCoupon: USER_BASEURL + "apply-couppon",
  inviteCode: USER_BASEURL + "join-invite-user",
  getSetting: USER_BASEURL + "get-settings",
  joinedMatchesList: BASEURL + "/fantasy/joined-matches-list",
  joinedContestList: BASEURL + "/fantasy/joined-contest-list",
  teamScore: BASEURL + "/fantasy/team-score",
  liveScore: BASEURL + "/fantasy/live-score",
  playerTeamList: BASEURL + "/fantasy/player-team-list",
  allPlayerList: BASEURL + "/fantasy/get-players-list",
  seriesPlayerList: BASEURL + "/fantasy/get-series-player-list",
  getContestDetails: BASEURL + "/fantasy/get-contest-details",
  contestTeamPDF: BASEURL + "/fantasy/contest-team-pdf",
  applyContestInviteCode: BASEURL + "/fantasy/apply-contest-invite-code",
  joinContest: BASEURL + "/fantasy/join-contest",
  teamPreviewSoccer: BASEURL + "/v1.1/fantasy/team-preview",

  // contest Api path
  getEntryFee: BASEURL + "/fantasy/get-entry-fees",
  contestPrizeBreakup: BASEURL + "/fantasy/contest-prize-breakup",
  userCreateContest: BASEURL + "/fantasy/user-create-contest",
  userCreatePrivateContest: BASEURL + "/v1.1/fantasy/user-create-contest",
  joinContestCheckWallet: USER_BASEURL + "/join-contest-check-wallet",
  addCash: USER_BASEURL + "/add-cash",

  matchSaveRemindrs: BASEURL + "/fantasy/save-reminders",

  // payment getway path cashfree
  depositPayment: USER_BASEURL + "create-callpay-transaction",
  createTransId: USER_BASEURL + "create-transaction-v1",
  updateTransId: USER_BASEURL + "update-transaction-v1",

  //payu
  createHash: USER_BASEURL + "create-payu-hash",
  daynamicHash: USER_BASEURL + "create-payu-dynamic-hash-web",
  updateTransactionPayu: USER_BASEURL + "update-transaction-payu",

  //state list
  // stateList: USER_BASEURL + 'get-state-list',
  stateList: USER_BASEURL + "states",
  countryList: USER_BASEURL + "countries",

  //rummmy create hash
  getRummyLink: BASEURL + "/fantasy/v1/hash",

  // ***********************************league api routes**************************************************************************************
  get_series_list: BASEURL + "/fantasy/league/get-series-list",
  get_league_match_details: BASEURL + "/fantasy/league/match-details",
  get_league_states: BASEURL + "/fantasy/league/states",
  get_league_booster_list: BASEURL + "/fantasy/league/get-boosters-list",
  get_league_booster_list_by_match: BASEURL + "/fantasy/league/booster-list-by-team",
  get_booster_list_by_user: BASEURL + "/booster/get-boosterList",
  get_league_player_list: BASEURL + "/fantasy/league/get-players-list",

  ///contest apies==>>>>>>>>>>>>>>>>>
  get_league_all_contest: BASEURL + "/fantasy/league/contest-list",
  get_league_my_contest: BASEURL + "/fantasy/league/joined-contest-list",
  get_league_my_teams: BASEURL + "/fantasy/league/player-team-list",
  get_league_series_player_list: BASEURL + "/fantasy/league/get-series-player-list",
  get_league_leaderboard_data: BASEURL + "/fantasy/league/get-leaderboard-data",
  create_update_league_team: BASEURL + "/fantasy/league/create-team",
  get_league_top_players: BASEURL + "/fantasy/league/get-top-players",
  get_league_players_points: BASEURL + "/fantasy/league/matches",
  get_league_transfer_preview: BASEURL + "/fantasy/league/transfer-preview",
  join_contest_league: BASEURL + "/fantasy/league/join_contest",
  points_team_preview: BASEURL + "/fantasy/league/team-preview",
  get_user_teams: BASEURL + "/fantasy/league/get-userTeams",
  check_unjoined_team: BASEURL + "/fantasy/league/check-unjoined-team",

  contest_check_wallet: USER_BASEURL + "/join-contest-check-wallet",

  ///////////////////boosters shop api path==>>>>>>////////////////////////
  get_shop_category: BASEURL + "/booster/getCategoryAndSubCategoryList",
  get_shop_boosterListByCategory: BASEURL + "/booster/getBoosterListByCategoryAndSubCategory",
  get_subscription_list: BASEURL + "/booster/getSubscriptionList",
  // purchase_subscription: BASEURL + '/booster/purchaseSubscription',
  purchase_subscription: BASEURL + "/booster/web-purchaseSubscription",
  purchase_booster: BASEURL + "/booster/purchaseBooster",
  booster_contest_list: BASEURL + "/booster/getContestList?series_id=",
  booster_teams_list: BASEURL + "/booster/user-teamList?teamName=",
  booster_transfer: BASEURL + "/booster/boosterTransfer",
  apply_debuff: BASEURL + "/booster/applyDebuffBooster",
  validate_booster: BASEURL + "/booster/validate/booster",
  validate_booster_daily: BASEURL + "/fantasy/validate/booster",

  ///////////////////daily contest boosters api path==>>>>>>////////////////////////

  get_booster_list: BASEURL + "/v1.1/fantasy/booster-list",

  booster_contest_list_daily: BASEURL + "/fantasy/getContestList?team_id=",
  get_user_team_list_daily: BASEURL + "/fantasy/user-teamList?teamName=",
  booster_transfer_daily: BASEURL + "/fantasy/boosterTransfer",
  apply_debuff_daily: BASEURL + "/fantasy/applyDebuffBooster",

  get_booster_history: BASEURL + "/booster/history",
  get_league_history: USER_BASEURL + "/league-history",

  getUserBoostersList: BASEURL + "/fantasy/unUsed-booster",

  server_time: BASEURL + "/fantasy/get-current-timestamp",
  getWinningBreak: BASEURL + "/fantasy/get-winning-break-for-season",

  getPaymentList: BASEURL + "/users/get-available-callpay-services",

  chatList: BASEURL + "/v1.1/fantasy/getChatList",
  reportUser: BASEURL + "/v1.1/fantasy/report-user",
  banUser: BASEURL + "/v1.1/fantasy/update-chatStatus",

  register: BASEURL + "/v1.1/fantasy/register",

  //money pool apis
  get_pool_list: BASEURL + "/fantasy/get-questions-list",
  get_pool_match_list: BASEURL + "/fantasy/get-pool-match-list",
  getPoolCategoryList: BASEURL + "/fantasy/get-category-list/",

  join_pool: BASEURL + "/fantasy/join-pool",
  // payment  aoi
  createTranscation: USER_BASEURL + "create-transaction-v1",
  createTranscationWeb: USER_BASEURL + "create-transaction-v1-web",
  updateTranscationWeb: USER_BASEURL + "update-transaction-v1-web",
  getGstTax: USER_BASEURL + "calculate-gst",
  getInfluencer: USER_BASEURL + "get-influencers",
};
export default apiPath;
