// import { LOGIN_SUCCESS, LOGOUT_SUCCESS, FORGOT_PASSWORD, VERIFY_OTP, PACKAGE_ID } from '../constant/Auth'
import Session from "../../utils/session";
import { GET_ACTIVE_MATCHES_LIST, GET_LIVE_AND_UPCOMING_MATCHES, GET_BANNERS, JOINED_MATCHES_LIST, JOINED_CONTEST_LIST, PLAYER_TEAM_LIST, TEAM_SCORE, SERIES_PLAYER_LIST, LIVE_SCORE, MATCH_TAB, CONTEST_PAYLOAD, PLAYER_DETAILS, MY_CONTEST_TAB, CONTEST_DETAILS, CONTEST_TEAM_PDF, MATCH_DETAIL, GET_CONTEST_LIST_BYMATCH_JSON, ALL_PLAYER_LIST, CONTEST_ENTRY_FEE, CONTEST_PRIZE_BREAKUP, CHECK_WALLET_BALANCE, CONTEST_SHARE_CODE, ALL_CONTEST_TABS, PLAYER_SELECT_DESELECT, PLAYER_ROLE_TAB, CAPTAIN_AND_VICE_CAPTION } from "../constants/Fantasy";




let initialState = {
    live_matches_list: [],
    top_upcoming_matches_list: [],
    completed_matches_list: [],
    upcoming_match_list: [],
    banners_list: [],
    banner_path: '',
    joinedMatchesList: {},
    seriesPlayerList: {},
    liveScoreDetails: {},
    playerTeamList: {},
    joinedContestList: {},
    teamScore: {},
    selectedTab: 'upcoming',
    playerDetails: {},
    myContestTab: 'myContest',
    contestTeamPDF: {},

    my_teams: 0,
    my_contests: 0,
    full_contest: 0,
    contestsList: [],
    all_player_list: [],
    contestEntryFee: {},
    contestPrizeBreakup: {},
    checkWalletBalance: {},
    contestInvitationCode: null,
    allContestTabs: 'allContest',
    playerList: null,
    playerRoles: null,
    selectedPlayerRoleTab: 'wk-tab',
    teamWithCaptionAndViceCaption: null,



};



const FantasyReducer = function (state = initialState, action) {
    try {
        switch (action.type) {
            case GET_ACTIVE_MATCHES_LIST:
                return {
                    ...state,
                    upcoming_match_list: action?.payload?.results?.rows
                };
            case GET_LIVE_AND_UPCOMING_MATCHES:
                return {
                    ...state,
                    live_matches_list: action?.payload?.live,
                    top_upcoming_matches_list: action?.payload?.upcoming,
                    completed_matches_list: action?.payload?.completed,
                };
            case GET_BANNERS:
                // console.log("action",action.payload.banner_path)
                return {
                    ...state,
                    banners_list: action?.payload?.results?.docs,
                    banner_path: action?.payload?.banner_path
                };
            case JOINED_MATCHES_LIST:
                return {
                    ...state,
                    joinedMatchesList: action?.payload
                };

            case JOINED_CONTEST_LIST:
                return {
                    ...state,
                    joinedContestList: action?.payload
                };
            case PLAYER_TEAM_LIST:
                return {
                    ...state,
                    playerTeamList: action?.payload
                };
            case TEAM_SCORE:
                return {
                    ...state,
                    teamScore: action?.payload
                };
            case SERIES_PLAYER_LIST:
                return {
                    ...state,
                    seriesPlayerList: action?.payload
                };
            case LIVE_SCORE:
                return {
                    ...state,
                    liveScoreDetails: action?.payload?.results.sort((a, b) => a._id.inning_number - b._id.inning_number)
                };
            case MATCH_TAB:
                return {
                    ...state,
                    selectedTab: action?.payload
                };

            case PLAYER_DETAILS:
                return {
                    ...state,
                    playerDetails: action?.payload
                };
            case MY_CONTEST_TAB:
                return {
                    ...state,
                    myContestTab: action?.payload
                };
            case CONTEST_DETAILS:
                return {
                    ...state,
                    contestDetails: action?.payload
                };
            case CONTEST_TEAM_PDF:
                return {
                    ...state,
                    contestTeamPDF: action?.payload
                };

            case MATCH_DETAIL:
                return {
                    ...state,
                    matchDetails: action?.payload
                };
            case GET_CONTEST_LIST_BYMATCH_JSON:
                return {
                    ...state,
                    my_teams: action?.payload?.my_teams,
                    my_contests: action?.payload?.my_contests,
                    full_contest: action?.payload?.full_contest,
                    contestsList: action?.payload?.results,

                };
            case ALL_PLAYER_LIST:
                return {
                    ...state,
                    all_player_list: action?.payload
                };

            case CONTEST_ENTRY_FEE:
                return {
                    ...state,
                    contestEntryFee: action?.payload
                };

            case CONTEST_PRIZE_BREAKUP:
                return {
                    ...state,
                    contestPrizeBreakup: action?.payload
                };

            case CHECK_WALLET_BALANCE:
                return {
                    ...state,
                    checkWalletBalance: action?.payload
                };

            case CONTEST_SHARE_CODE:
                return {
                    ...state,
                    contestInvitationCode: action?.payload?.results
                };

            case ALL_CONTEST_TABS:
                return {
                    ...state,
                    allContestTabs: action?.payload
                };
            case PLAYER_SELECT_DESELECT:
                return {
                    ...state,
                    // selectedPlayerList: action?.payload,
                    playerList: action?.payload?.playerList,
                    playerRoles: action?.payload?.playerRoles
                };

            case PLAYER_ROLE_TAB:
                return {
                    ...state,
                    selectedPlayerRoleTab: action?.payload,

                };

            case CAPTAIN_AND_VICE_CAPTION:
                return {
                    ...state,
                    teamWithCaptionAndViceCaption: action?.payload,

                };


            default:
                return state;
        }
    } catch (error) {
      //console.log('err in reducers==>>>>', error)
    }
};

export default FantasyReducer;