import React, { useEffect, useState } from "react";
import { Tabs, Tab, Accordion, Container, Row, Col, Form, InputGroup, Button } from "react-bootstrap";

//import 
import helper from '../../utils/helper';
import apiPath from "../../utils/apiPath";


const Faq = () => {

  const [data, setData] = useState([])

  const getData = async () => {
    try {
      const url = apiPath.getFaq;
      const data = await helper.api(url, "GET", {});
      if (data?.success) {
        const { docs } = data?.results;
        setData(docs)
      }
    } catch (error) {
    //console.log('err in api==>>', error.message)
    }
  }



  // ***************use effect********************
  useEffect(() => {
    getData()
  }, [])

  return (
    <section className="frequent-sec position-relative" id="faq">
      <Container>
        <div className="heading">
          <h2>Frequently asked questions</h2>
        </div>

        <div className="freq-accordian">
          <Accordion>
            {
              data && data?.length > 0 && data?.map((item, i) => (
                <Accordion.Item eventKey={i} key={i}>
                  <Accordion.Header>
                    {item?.question}
                  </Accordion.Header>
                  <Accordion.Body>
                    <div dangerouslySetInnerHTML={{ __html: item?.answer }} />
                  </Accordion.Body>
                </Accordion.Item>
              ))
            }

          </Accordion>
        </div>
      </Container>
    </section>

  )
}

export default Faq