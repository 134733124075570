import React from 'react'
import { useSelector } from 'react-redux';
import Slider from "react-slick";


const banner_slider = {
    dots: true,
    infinite: true,
    speed: 1000,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    cssEase: "linear"
};

const LeftSlide = ({ backgroundColor }) => {

    const { banners_list, banner_path } = useSelector(state => state.match)

    // console.log('banneerrrr==>>>>>>>>>>>>>>>',banners_list);

    const handelClickBanner = (data) => {
        //console.log('data', data);
        // if(data?.banner_type==='offer'){
        //   navigate('/MyOffer')
        // } else {  
        window.open(data?.link);
        // }
    }


    return (
        <div className={`${backgroundColor !== 'none' && 'bg-blue-dark'} inner_slider py-2 px-xl-4 w-100 pb-0`}>
         <div className="innerLeftSide">
            <img src={banner_path + "web_inner.png"} alt="" />
        </div>
             </div>
    )
}

export default LeftSlide