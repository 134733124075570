import { SERVER_TIME } from "../constants/Common";



let initialState = {
    server_time: 0,
};



const TimeReducer = function (state = initialState, action) {

    switch (action.type) {
        case SERVER_TIME:
            return {
                ...state,
                server_time: Number(action.payload),
            };

        default:
            return state;
    }
};

export default TimeReducer;



