import { APPLIED_COUPON, OFFER_LIST, REWARD_LIST } from "../constants/SideBar";




let initialState = {
  rewardList:[],
  offerList:[],
  couponDetails:{}
};



const SideBarReducer = function (state = initialState, action) {
    try {
        switch (action.type) {
            case REWARD_LIST:
                return {
                    ...state,
                    rewardList:action.payload
                };

                case OFFER_LIST:
                return {
                    ...state,
                    offerList:action.payload
                };

                case APPLIED_COUPON:
                return {
                    ...state,
                    couponDetails:action.payload
                };



            default:
                return state;
        }
    } catch (error) {
      //console.log('err in SideBarReducer==>>>>', error)
    }
};

export default SideBarReducer;



