
export const LOADER = 'LOADER'
export const MATCH_LIST_LOADER = 'MATCH_LIST_LOADER'
export const LOADER_PLAYER_LIST = 'LOADER_PLAYER_LIST'
export const TEAM_LOADER = 'TEAM_LOADER'
export const INVITE_OPEN = 'INVITE_OPEN'
export const JOIN_CONTSEST ='JOIN_CONTSEST'
export const CONTEST_PDF_MODAL = 'CONTEST_PDF_MODAL'
export const CONTEST_CONFIRMATION = 'CONTEST_CONFIRMATION'
export const LOW_BALANCE = 'LOW_BALANCE'
export const HOME_ACTIVE_KEY = 'HOME_ACTIVE_KEY'



export const GET_DAILY_BOOSTER_LIST = 'GET_DAILY_BOOSTER_LIST'
export const BOOSTER_GIFT_LIST_DAILY = 'BOOSTER_GIFT_LIST_DAILY'
export const BOOSTER_GIFT_POPUP_DAILY = 'BOOSTER_GIFT_POPUP_DAILY'
export const BOOSTER_LIST_TABS_DAILY = 'BOOSTER_LIST_TABS_DAILY'
export const SEND_GIFT_POPUP_DAILY = 'SEND_GIFT_POPUP_DAILY'
export const BOOSTER_GIFT_OTHER_DAILY = 'BOOSTER_GIFT_OTHER_DAILY'
export const SEND_GIFT_TYPE_DAILY = 'SEND_GIFT_TYPE_DAILY'
export const DEBUFF_BOOSTER_SEND_DAILY = 'DEBUFF_BOOSTER_SEND_DAILY'
export const STATE_DATA__DAILY = 'STATE_DATA__DAILY'
export const APPLY_REFERRAL_CODE_POPUP = 'APPLY_REFERRAL_CODE_POPUP'
export const DEFAULT_GUIDES_POPUP = 'DEFAULT_GUIDES_POPUP'
export const MATCH_DEAD_LINE_POPUP = 'MATCH_DEAD_LINE_POPUP'
export const POOL_DEAD_LINE_POPUP = 'POOL_DEAD_LINE_POPUP'



export const SERVER_TIME = 'SERVER_TIME'








