import axios from 'axios';
import React, { useEffect } from 'react'
import apiPath from './apiPath';
import Session from './session';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { Button, Modal } from 'react-bootstrap';
import { MATCH_DEAD_LINE_POPUP, POOL_DEAD_LINE_POPUP } from '../redux/constants/Common';

export const TimeComponent = () => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { matchDeadLinePopup, poolDeadLinePopup } = useSelector(state => state.loader)

    const browser_minimize_handler = (time) => {
        const minimizeTime = Session.getSession('minimize_time')
        const timeDiff = moment.unix(time).diff(moment.unix(minimizeTime), 'seconds')
        const tenMinutes = (60 * 10)

        if (timeDiff > tenMinutes) {
            Session.clearSession('minimize_time')
            // window.location.reload();
        }
    }

    const fetchServerTime = async () => {
        try {
            const response = await axios.get(apiPath.server_time);
            const serverTime = response.data.results?.currentTimeStamp
            Session.setSession('server_time', serverTime)
            browser_minimize_handler(serverTime)
        } catch (error) {
            console.log('Error fetching server time:', error);
        }
    };



    const handleVisibilityChange = () => {
        if (document.hidden) {
            Session.setSession('minimize_time', Session.getSession('server_time'))
        } else {
            fetchServerTime()
        }

    }

    useEffect(() => {
        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [])



    useEffect(() => {
        fetchServerTime();
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            const serverTime = Session.getSession('server_time')
            if (serverTime > 0) {
                Session.setSession('server_time', serverTime + 1)
            }
        }, 1000);

        return () => clearInterval(intervalId);

    },)

    const deadLIneOver = () => {
        dispatch({ type: MATCH_DEAD_LINE_POPUP, payload: false })
        window.location.href = '/Fantasy';
    }
    const poolDeadLIneOver = () => {
        dispatch({ type: POOL_DEAD_LINE_POPUP, payload: false })
        window.location.href = '/money-pool';
    }

    return (
        <>
            <Modal show={matchDeadLinePopup } onHide={deadLIneOver} className="clear_team_modal responsive_popup">
                <Modal.Header closeButton>
                    <Modal.Title><img src="../images/logo.png" alt="" /></Modal.Title>
                </Modal.Header>
                <Modal.Body>Match deadline is over.</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={deadLIneOver}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={poolDeadLinePopup } onHide={poolDeadLIneOver} className="clear_team_modal responsive_popup">
                <Modal.Header closeButton>
                    <Modal.Title><img src="../images/logo.png" alt="" /></Modal.Title>
                </Modal.Header>
                <Modal.Body>Deadline is over.</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={poolDeadLIneOver}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
