import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import Banner from './Banner'
import Download from './Download'
import Faq from './Faq'
import Games from './Games'
import Offers from './Offers'
import Footer from '../../components/Footer'
import Our_fantasy from './Our_fantasy'
import apiPath from '../../utils/apiPath'
import helper from '../../utils/helper'


function Home () {
  const [androidLink, setAndroidLink] = useState("")
  const [iosLink, setIosLink] = useState("")

	const getDownloadLinks = async () => {
		const res = await helper.api(apiPath.getSetting, 'get', {})
		if (res?.success) {
			setAndroidLink(res?.results?.android_app_link)
			setIosLink(res?.results?.ios_app_link)
		}
	}


	useEffect(() => {
		getDownloadLinks()
	}, [])
  return (
    <>
      <Header />
      <Banner />
      <Games iosLink={iosLink} androidLink={androidLink} />
      <Download iosLink={iosLink} androidLink={androidLink} />
      <Our_fantasy/>      
      <Offers />
      <Faq />
      <Footer />
    </>

  )
}

export default Home
