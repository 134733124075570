export const GET_SOCCER_MATCHES_LIST = 'GET_SOCCER_MATCHES_LIST'
export const GET_SOCCER_BANNERS = 'GET_SOCCER_BANNERS'
export const GET_LIVE_AND_UPCOMING_MATCHES_SOCCER = 'GET_LIVE_AND_UPCOMING_MATCHES_SOCCER'

export const MATCH_DETAIL_SOCCER = 'MATCH_DETAIL_SOCCER'
export const GET_CONTEST_LIST_BYMATCH_JSON_SOCCER = 'GET_CONTEST_LIST_BYMATCH_JSON_SOCCER'
export const FILTER_CONTEST_lIST = 'FILTER_CONTEST_lIST'
export const JOINED_CONTEST_LIST_SOCCER = 'JOINED_CONTEST_LIST_SOCCER'
export const PLAYER_TEAM_LIST_SOCCER = 'PLAYER_TEAM_LIST_SOCCER'
export const CONTEST_DETAILS_SOCCER = 'CONTEST_DETAILS_SOCCER'
export const TEAM_SCORE_SOCCER = 'TEAM_SCORE_SOCCER'
export const CHECK_WALLET_BALANCE_SOCCER = 'CHECK_WALLET_BALANCE_SOCCER'
export const CONTEST_CONFIRMATION_SOCCER = 'CONTEST_CONFIRMATION_SOCCER'
export const SELECTED_TEAM_SOCCER = 'SELECTED_TEAM_SOCCER'
export const ALL_PLAYER_LIST_SOCCER = 'ALL_PLAYER_LIST_SOCCER'
export const EDIT_SOCCER_TEAM = 'EDIT_SOCCER_TEAM'
export const TEAM_TYPE = 'TEAM_TYPE'
export const SAVE_TEAM_IN_SESSION = 'SAVE_TEAM_IN_SESSION'

export const SELECT_3X_BOOSTED_PLAYER_DAILY = 'SELECT_3X_BOOSTED_PLAYER_DAILY'

export const ALL_PLAYER_LIST_ = 'ALL_PLAYER_LIST'
export const PLAYER_INC = 'PLAYER_INC'
export const PLAYER_DEC = 'PLAYER_DEC'
export const PLAYER_SELECT_DESELECT = 'PLAYER_SELECT_DESELECT'
export const RESET_PLAYER = 'RESET_PLAYER'
export const SELECT_CAPTAIN = 'SELECT_CAPTAIN'
export const SELECT_VISE_CAPTAIN = 'SELECT_VISE_CAPTAIN'
export const STATE_DATA = 'STATE_DATA'
export const PLAYER_ROLE = 'PLAYER_ROLE'
export const TEAM_PREVIEW_SOCCER = 'TEAM_PREVIEW_SOCCER'
export const TABS_ALL_CONTEST_SOCCER = 'TABS_ALL_CONTEST_SOCCER'
export const CONTEST_ENTRY_FEE_SOCCER = 'CONTEST_ENTRY_FEE_SOCCER'
export const CONTEST_PRIZE_BREAKUP_SOCCER = 'CONTEST_PRIZE_BREAKUP_SOCCER'
export const CONTEST_SHARE_CODE_SOCCER = 'CONTEST_SHARE_CODE_SOCCER'
export const MATCH_TAB_SOCCER = 'MATCH_TAB_SOCCER'
export const JOINED_MATCHES_LIST_SOCCER = 'JOINED_MATCHES_LIST_SOCCER'
export const LIVE_SCORE_SOCCER = 'LIVE_SCORE_SOCCER'
export const SERIES_PLAYER_LIST_SOCCER = 'SERIES_PLAYER_LIST_SOCCER'
export const TEAM_SELECTED_TAB = 'TEAM_SELECTED_TAB'


export const SET_SOCCER_LIST_FILTER = 'SET_SOCCER_LIST_FILTER'






